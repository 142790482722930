<template>
  <div class="hero is-fullheight justify-center" style="
      background: #0f486d;
      background: linear-gradient(339deg, #0f486d 0%, rgba(66,203,182,1) 100%);
    ">
    <div class="columns is-centered">
      <div class="column is-3">
        <div class="card">
          <div class="card-header">
            <div class="column">
              <div class="is-size-6">
                Restablecer la contraseña
              </div>
            </div>
          </div>
          <div class="card-content">
            <!-- eslint-disable-next-line -->
            <ValidationObserver ref="observer" v-slot="{ passed }">
              <ValidationProvider vid="password" rules="password_complexity" name="Nueva contraseña"
                v-slot="{ errors, valid }">
                <b-field label="Nueva contraseña" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors">
                  <b-input type="password" password-reveal v-model="form.password" />
                </b-field>
              </ValidationProvider>
              <ValidationProvider rules="required|confirmed:password" name="Confirmar nueva contraseña"
                v-slot="{ errors, valid }">
                <b-field label="Confirmar nueva contraseña" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors">
                  <b-input type="password" password-reveal v-model="form.confirmPassword" />
                </b-field>
              </ValidationProvider>
              <div class="columns is-multiline mt-2">
                <div class="column is-12 has-text-right">
                  <b-button type="is-primary" @click="updatePassword">
                    Restablecer contraseña
                  </b-button>
                </div>
                <div class="column is-12">
                  <router-link to="/login">¿Ya tienes usuario y contraseña?</router-link>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null
      }
    }
  },
  computed: {
    token: function () {
      return this.$route.query.invite
    }
  },
  methods: {
    async updatePassword() {
      const valid = await this.$refs.observer.validate()
      if(!valid) return
 
      const payload = {
        token: this.token,
        password: this.form.password,
      }
      
      try {
        const userSession = await this.$store.dispatch('createPassword', payload)
        if (userSession)
          this.$router.push('/').catch(() => {})
      } finally {
        this.form.password = null
        this.form.confirmPassword = null
      }
    }
  }
}
</script>

<style></style>
