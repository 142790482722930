import mxContent from './es-MX'
import esContent from './es-ES'
import usContent from './en-US'

export default {
  getLocaleMessages: function () {
    const langs = {
      mx: mxContent,
      es: esContent,
      en: usContent,
    };
    // Retornar los diferentes contenidos
    return langs;
  }
}
