import httpApi from '@/axios'
import Vue from 'vue'

const createUser = async function (payload) {
  try {
    await httpApi.post(`/users`, payload)
    Vue.$toast.success({
      message: 'El usuario se ha creado con éxito',
      type: 'is-success'
    })
  } catch (error) {
    Vue.$toast.error({
      message: error,
      duration: 5000
    })

    return Promise.reject(error)
  }
}

const getRecruiters = async function () { // Get all recruiters (including recruiter managers)
  try {
    const { data } = await httpApi.get(`/users/all-recruiters`)
    return data

  } catch (error) {
    Vue.$toast.error({
      message: error,
      duration: 5000
    })

    return Promise.reject(error)
  }
}


export {
  createUser,
  getRecruiters,
}
