<template >

  <div>

    <b-modal
      :active.sync="isOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>

      <div 
        class="modal-card">

        <div 
          class="modal-card-head">
        
          <div class="title is-4">

            Duplicar

            <span class="has-text-weight-semibold">

              "{{ vacancyName }}"

            </span>

          </div>

        </div>

        <div 
          class="modal-card-body">

          <div 
            class="subtitle">

            ¿Por cuál motivo estás duplicando la vacante?

          </div>

          <div class="tile is-ancestor">
            
            <div 
              class="tile is-parent pointer">

              <div 
                class="tile is-child notification is-primary"  
                @click="duplicateVacancy('new-vacancy')">

                <div 
                  class="has-text-centered">

                  <p class="subtitle">

                    Nueva vacante

                  </p>

                  <div class="content">

                      <f-icon 
                        size="3x" 
                        icon="fa-regular fa-layer-plus" />

                  </div>

                </div>

              </div>

            </div>

            <div 
            class="tile is-parent pointer">

              <div 
                class="tile is-child notification is-blue">

                <div 
                  class="has-text-centered" 
                  @click="duplicateVacancy('second-round')">

                  <p 
                    class="subtitle">Es segunda terna</p>

                  <div 
                    class="content">

                    <f-icon 
                      size="3x" 
                      icon="fa-regular fa-copy" />

                  </div>

                </div>

              </div>

            </div>

          </div>

          <div class="tile is-ancestor">

            <div 
              class="tile is-parent pointer">

              <div 
                class="tile is-child notification is-blue "  
                @click="duplicateVacancy('scholar-replacement')">

                <div 
                  class="has-text-centered">

                  <p 
                    class="subtitle">Sustitución de becario
                  </p>

                  <div class="content">

                      <f-icon 
                        size="3x" 
                        icon="fa-regular fa-users" />

                  </div>

                </div>

              </div>

            </div>

            <div 
              class="tile is-parent pointer">

              <div 
                class="tile is-child notification is-primary "  
                @click="duplicateVacancy('vacancy-expiration')">

                <div 
                  class="has-text-centered">

                  <p 
                    class="subtitle">Vencimiento de vacante
                  </p>

                  <div class="content">

                      <f-icon 
                        size="3x" 
                        icon="fa-regular fa-calendar-xmark" />

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="modal-card-foot">

        </div>

      </div>

    </b-modal>

  </div>

</template>
<script>
export default {
  data(){
    return{
      isOpen: false,
      vacancy: '',
      vacancyName: ''
    }
  },
  methods:{
    open(id, name){
      this.isOpen = true
      this.vacancy = id
      this.vacancyName = name
    },
    close(){
      this.isOpen = false
    },
    async duplicateVacancy (reason) {
      const payload = {
        duplicateReason: reason
      }
      try {
        await this.$api.post(`/vacancies/${this.vacancy}/duplicate`, payload)
        this.$toast.success({
          message: 'La vacante se duplicó',
        })
        this.$emit('update')
      } catch (error) {
        this.$toast.error({
          message: error,
        })
      }
      this.close()
    },
  }
}
</script>
<style lang="scss" scoped>
  
</style>