import axios from '@/axios'
import Vue from 'vue'

const companiesStore = {
  state: {
    userCompanies: [], // Saves users' companies id's
    userVacancies: [], // Saves users' vacancies id's
    currentCompany: {}, // Saves company which user is in
    currentVacancy: {}, // Saves vacancy which user is in
    currentApplication: {}, // Saves application which user is in
  },
  // Actions are functions that causes side effects and can involve asynchronous operations and only called from other components
  actions: {
    // Get user available companies (get user companies from api)
    async getUserCompanies({ commit }) {
      try {
        const { data } = await axios.get(`/companies?user=${this.state.session.user.id}&limit=10000`)
        const allowedIds = data.allowed

        commit('setUserCompanies', allowedIds)
        return allowedIds
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 3000,
        });
        throw error
      }
    },
    // Get a company by a given id (get company from api)
    async getCurrentCompany({ commit }, id) {
      try {
        const { data } = await axios.get(`/companies/${id}`)

        commit('setCurrentCompany', data)
        return data
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 3000,
        });
        throw error
      }
    },
    // Get a vacancy by a given id (get vacancy from api)
    async getCurrentVacancy({ commit }, id) {
      try {
        const { data } = await axios.get(`/vacancies/${id}`)

        commit('setCurrentVacancy', data)
        return data
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 3000,
        });
        throw error
      }
    },
    // Get an application by a given id (get application from api)
    async getCurrentApplication({ commit }, id) {
      try {
        const { data } = await axios.get(`/application/${id}`)

        commit('setCurrentApplication', data)
        return data
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 3000,
        });
        throw error
      }
    },
    // Get user available vacancies (get user vacancies from api)
    async getUserVacancies({ commit }) {
      try {
        const { data } = await axios.get(`/companies/${this.state.currentCompany.id}/vacancies/get-user-vacancies`);

        commit('setUserVacancies', data)
        return data
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 3000,
        });
        throw error
      }
    }
  },
  // Mutations are functions that effect the state, just called on actions
  mutations: {
    setUserCompanies(state, companies) {
      state.userCompanies = companies
    },
    setUserVacancies(state, vacancies) {
      state.userVacancies = vacancies
    },
    setCurrentCompany(state, company) {
      state.currentCompany = company
    },
    setCurrentVacancy(state, vacancy) {
      state.currentVacancy = vacancy
    },
    setCurrentApplication(state, application) {
      state.currentApplication = application
    }
  },
  // Getters are functions that return a value from the state and can be called from other components
  getters: {
    companyId(state) {
      return state.currentCompany.id
    },
    company(state) {
      return state.currentCompany
    },
    vacancy(state) {
      return state.currentVacancy
    }
  }
}

export default companiesStore;