<template>
  <div class="card" v-if="section.name">
    <div class="card-header">
      <div class="column">
        <div class="columns">
          <div class="column">
            <b-icon icon="arrows-up-down-left-right" />
            Beneficios
          </div>
          <div class="column has-text-right">
            Mostrar sección:
            <b-switch v-model="section.benefits.active" @input="updateSection" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="pb-15">
        <b-field label="Descripción">
          <b-input 
            v-model="section.benefits.description" 
            placeholder="Ingresa descripción de la sección"
            @blur="updateSection" 
          />
        </b-field>
      </div>
      <div v-for="(benefit, index) in section.benefits.benefits" :key="index">
        <div class="columns">
          <div class="column">
            <b-field label="Beneficios de la empresa">
              <b-input 
                v-model="benefit.text" 
                placeholder="¿Cuáles son los beneficios ofrecidos a los becarios?"
                @blur="updateSection"
              />
            </b-field>
            <b-field label="Descripción">
              <b-input 
                v-model="benefit.description" 
                placeholder="Ingresa un texto corto" 
                @blur="updateSection"
              />
            </b-field>
            <b-field class="file">
              <b-upload 
                v-model="benefit.file" 
                accept=".webp, .jpg, .jpeg, .png, .gif, .tiff, .ico"
                @input="uploadLocalImage(benefit, index)"
              >
                <a class="button is-primary">
                  <b-icon icon="upload" />
                  <span>Subir imagen (333px x 384px)</span>
                </a>
              </b-upload>
              <span v-if="benefit.image" class="file-name">
                {{ benefit.image.url }}
                <button class="delete is-small" type="button" @click="deleteLocalImage(benefit)">
                </button>
              </span>
            </b-field>
          </div>
          <div class="column">
            <div class="buttons" style="justify-content: flex-end">
              <b-button type="is-danger" icon-right="trash" @click="removeBenefit(index)" />
            </div>
          </div>
        </div>
        <hr>
      </div>
      <b-button @click="addBenefit">
        Agregar beneficio
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultSection: {
      type: Object,
    },
    uploadImage: {
      type: Function,
    },
    deleteImage: {
      type: Function,
    },
  },
  data() {
    return {
      section: {},
      isLoading: false,
    }
  },
  mounted() {
    this.section = { ...this.defaultSection};
  },
  watch: {
    defaultSection: {
      handler: function (val) {
        this.section = {...val }
      }
    },
  },
  methods: {
    updateSection() {
      this.$emit('updateSection', this.section)
    },
    async uploadLocalImage(slide, index){
      this.isLoading = true;
      const file = slide.file;
      const fileIdentifier = `benefit-${index + 1}`;
      const fileMetadata = slide.image;
      slide.image = await this.uploadImage(file, fileIdentifier, fileMetadata, "archivo para la imagen del beneficio");

      this.updateSection();
      this.isLoading = false;
    },
    async deleteLocalImage(slide){
      this.isLoading = true;
      slide.image = await this.deleteImage(slide.image.key);

      this.updateSection();
      this.isLoading = false;
    },
    addBenefit() {
      this.section.benefits.benefits.push({ text: '', file: null, image: {url: '', tag:'', key: ''}, description: '' })
      this.updateSection()
    },
    removeBenefit(index) {
      if (this.section.benefits.benefits[index].image?.key) 
        this.deleteLocalImage(this.section.benefits.benefits[index])

      this.section.benefits.benefits.splice(index, 1)
      this.updateSection()
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
    position: relative;
    min-height: 300px;
  }
</style>
