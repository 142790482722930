<template>
  <div>
    <b-modal
      :active.sync="isOpen"
      :destroy-on-hide="false"
      @cancel="close"
      aria-modal
      aria-role="dialog"
      has-modal-card
      trap-focus>

      <div class="modal-card">

        <div class="modal-card-head">
          <div class="modal-card-title">
            Editar usuario
          </div>
        </div>

        <div class="modal-card-body">
          <ValidationObserver ref="observer">

            <ValidationProvider rules="required|max:25" name="Nombre(s)" v-slot="{ errors, valid }">
              <b-field
                label="Nombre(s)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  v-model="form.names"
                  icon="signature"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="required|max:25" name="Apellido paterno" v-slot="{ errors, valid }">
              <b-field
                label="Apellido paterno"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  v-model="form.paternalSurname"
                  icon="signature"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="max:25" name="Apellido materno" v-slot="{ errors, valid }">
              <b-field
                label="Apellido materno"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  v-model="form.maternalSurname"
                  icon="signature"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider rules="required" name="Rol" v-slot="{ errors, valid }">
              <b-field
                label="Rol"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-select
                  v-model="form.role"
                  icon="user"
                  expanded>
                  <option
                    v-for="(role, index) in roles"
                    :key="`role-${index}`"
                    :value="role.value">
                    {{ role.text }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              v-if="authRoles.one.includes(form.role)"
              rules="required" name="Calendly" v-slot="{ errors, valid }">
              <b-field
                label="Calendly"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  v-model="form.recruiter.calendlyUrl"
                  icon="link"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              v-if="authRoles.two.includes(form.role)"
              rules="required" name="Monday"
              v-slot="{ errors, valid }">
              <b-field
                label="Monday"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-input
                  v-model="form.account.mondayUrl"
                  icon="link"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              v-if="authRoles.three.includes(form.role) && !this.form.company"
              rules="required"
              name="Empresa"
              v-slot="{ errors, valid }">
              <b-field
                label="Empresa"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors">
                <b-select
                  v-model="form.company"
                  expanded>
                  <option
                    v-for="(company, index) in companies"
                    :key="`company-${index}`"
                    :value="company.id">
                    {{ company.name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>

          </ValidationObserver>
        </div>

        <div class="modal-card-foot is-justify-content-flex-end">
          <b-button rounded @click="close">
            Cancelar
          </b-button>
          <b-button rounded type="is-primary" @click="edit" v-if="canEdit">
            Guardar
          </b-button>
        </div>

      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
export default {
  props: {
    contactUsers: {
      type: Boolean,
      default: false
    },
    companyUsers: {
      type: Boolean,
      default: false
    },
    company: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      authRoles: {
        one: [ 'recruiter-manager', 'recruiter' ],
        two: [ 'account-manager', 'account' ],
        three: [ 'hrmanager','solicitant' ],
        four: [ 'manager', 'designer-manager', 'designer' ]
      },
      isOpen: false,
      user: null,
      form: {
        names: '',
        paternalSurname: '',
        maternalSurname: '',
        role: '',
        recruiter: {
          calendlyUrl: '',
        },
        account: {
          mondayUrl: '',
        }
      },
      companies: [],
      userCurrentRol : ''
    }
  },
  computed: {
    roles () {
      const baseRoles = [
        { value: 'recruiter-manager', text: 'Coordinador de Adquisición de Talento' },
        { value: 'recruiter', text: 'Especialista en Adquisición de Talento' },
        { value: 'designer', text: 'Especialista de Comunicación' },
        { value: 'designer-manager', text: 'Coordinador de Comunicación' },
        { value: 'account', text: 'Analista de Cuentas Clave' },
        { value: 'account-manager', text: 'Coordinador de Cuentas Clave' },
        { value: 'manager', text: 'Manager' }
      ]

      const contactRoles = [
        { value: 'hrmanager', text: 'Manager de RH' },
        { value: 'solicitant', text: 'Solicitante' }
      ]

      if ( this.contactUsers || ['solicitant', 'hrmanager'].includes( this.form.role ) ) {
        return contactRoles
      }

      if ( this.companyUsers ) {
        return baseRoles
      }

      return baseRoles.concat( contactRoles )
    },
    canEdit () {  
      if ( this.userCurrentRol === this.form.role) return true
          
      return this.$hasPermissionByManagmentTree({
          user: this.$store.state.session.user,
          userOnRole: this.form.role,
          userOnAction: 'update-user'
      }) 
    }
  },
  methods: {
    open ( user ) {
      this.getCompanies()
      if ( user ) {
        this.user = user._id || user.id
        this.form = {
          names: user.names,
          paternalSurname: user.paternalSurname,
          maternalSurname: user.maternalSurname,
          role: user.role,
          recruiter: {
            calendlyUrl: ''
          },
          account: {
            mondayUrl: ''
          },
          company: ''
        }
        if( ['recruiter'].includes( user.role ) ) {
          this.form.recruiter.calendlyUrl = user?.recruiter?.calendlyUrl
        }
        if( ['recruiter-manager'].includes( user.role ) ) {
          this.form.recruiter.calendlyUrl = user?.recruiterManager?.recruiter?.calendlyUrl
        }
        if( ['account'].includes( user.role ) ) {
          this.form.account.mondayUrl = user?.account?.mondayUrl
        }
        if( ['account-manager'].includes( user.role ) ) {
          this.form.account.mondayUrl = user?.accountManager?.account?.mondayUrl
        }
        if( ['hrmanager','solicitant'].includes( user.role ) ) {
          this.form.company = user?.company?._id || user?.company
        }
      }

      this.userCurrentRol = user.role

      this.isOpen = true
    },
    close () {
      this.clean()
      this.isOpen = false
    },
    async edit () {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.isLoading = true
        try {
          const payload = { ...this.form }
          delete payload._id
          if ( !this.authRoles.one.includes( this.form.role ) ) {
            delete payload.recruiter
          }
          if ( !this.authRoles.two.includes( this.form.role ) ) {
            delete payload.account
          }
          if ( !this.authRoles.three.includes( this.form.role ) ) {
            delete payload.company
          }
          if ( this.authRoles.four.includes( this.form.role ) ) {
            delete payload.company
          }
          await this.$api.put(`/users/${this.user}`, payload)
          this.$toast.success({
            message: 'El usuario se ha actualizado con éxito',
          })
          this.$emit('updated')
          this.close()
        } catch (error) {
          this.$toast.error({
            message: error,
          })
        }
        this.isLoading = false
      }
    },
    async getCompanies () {
      this.isLoading = true
      try {
        const response = await this.$api.get( `/companies/all` )
        this.companies = response.data
      } catch ( error ) {
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    clean () {
      this.form = {
        names: '',
        paternalSurname: '',
        maternalSurname: '',
        email: '',
        role: '',
        recruiter: {
          calendlyUrl: '',
        },
        account: {
          mondayUrl: '',
        }
      }
      this.$refs.observer.reset()
    }
  }
}
</script>