import httpApi from "@/axios";
import Vue from 'vue'

const getCandidate = async function (id) {
  try {
    const { data } = await httpApi.get(`/candidates/${id}`);
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const sendCustomEvaluation = async function (id, payload) {
  await httpApi.post(`application/${id}/set-custom-evaluation`, payload);
};

const sendCompanyFeedback = async function (id, payload) {
  try {
    await httpApi.post(`application/${id}/company-feedback`, payload);
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};
const updateGeneralProfile = async function (form) {
  await httpApi.put(`application/${form.id}/update-general-profile`, form);
};

const updateAcademicProfile = async function (form) {
  await httpApi.put(`application/${form.id}/update-academic-profile`, form);
};
const updateSkillsProfile = async function (form) {
  await httpApi.put(`application/${form.id}/update-skills-profile`, form);
};

const updateProfessionalProfile = async function (id, form) {
  await httpApi.put(`application/${id}/update-professional-profile`, form);
};

export {
  getCandidate,
  sendCustomEvaluation,
  sendCompanyFeedback,
  updateGeneralProfile,
  updateAcademicProfile,
  updateSkillsProfile,
  updateProfessionalProfile,
};
