<template>
  <div>
    <b-modal :active.sync="showModal" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
      <div class="modal-card">
        <div class="modal-card-head">
          <strong>Rechazar Candidato</strong>
        </div>
        <div class="modal-card-body">
          <strong>Nombre: </strong>{{ rejectCandidateForm.candidateName }}
          <hr />
          <ValidationObserver ref="observer">
            <ValidationProvider rules="required" name="Motivo de rechazo" v-slot="{ errors, valid }">
              <b-field label="Motivo de rechazo" :message="errors"
                :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-select rounded expanded v-model="rejectCandidateForm.reasonRejection.reason">
                  <option value="" disabled>Seleccione un motivo</option>
                  <option v-for="reason in reasons" :key="`${reason}`" :value="reason">
                    {{ reason }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider v-if="rejectCandidateForm.reasonRejection.reason === 'Otro...'" rules="required"
              name="Link de calendly" v-slot="{ errors, valid }">
              <b-field label="Otro..." :message="errors" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-input maxlength="150" type="textarea" v-model="rejectCandidateForm.reasonRejection.message"
                  placeholder="Describa el motivo de rechazo.">
                </b-input>
              </b-field>
            </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button type="is-primary" @click="sendReject">
            Enviar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
export default {
  props: {
    vacancyId: {
      type: String,
      required: true,
      default: '',

    },
  },
  data() {
    return {
      reasons: [
        "Horario incompatible con la posición",
        "Ubicación incompatible con la posición",
        "Nivel de excel insuficiente para la posición",
        "Nivel de inglés insuficiente para la posición",
        "Te encuentras actualmente en otro proceso",
        "Semestre en curso muy bajo para la posición",
        "Semestre en curso muy alto para la posición",
        "La posición ha sido cancelada por la empresa",
        "La posición ha sido completada por la empresa",
        "Nivel de 3° idioma incompatible con la posición",
        "Tu estatus como egresad@ es incompatible con la posición",
        "Otro...",
      ],
      showModal: false,
      isLoading: false,
      rejectCandidateForm: {
        candidateId: "",
        candidateName: "",
        reasonRejection: {
          reason: "",
          message: "",
        },
      },
    };
  },
  watch: {
  },
  computed: {

  },
  methods: {
    open(candidateId, candidateName) {
      this.rejectCandidateForm = {
        candidateId,
        candidateName,
        reasonRejection: {
          reason: "",
          message: "",
        },
      }
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.rejectCandidateForm = {
        candidateId: "",
        candidateName: "",
        reasonRejection: {
          reason: "",
          message: "",
        },
      }
    },
    async sendReject() {
      const valid = await this.$refs.observer.validate();
      const reasonRejection = {
        reason: this.rejectCandidateForm.reasonRejection.reason,
        message: this.rejectCandidateForm.reasonRejection.message || undefined,
      }
      const candidateId = this.rejectCandidateForm.candidateId;

      if (valid) {
        this.isLoading = true;
        try {
          await this.$api.post(`/vacancies/${this.vacancyId}/reject-candidate`, {
            candidate: candidateId,
            reasonRejection: reasonRejection,
          });
          this.$emit("candidateUpdated");
          this.close();
          this.$toast.success({
            message: "Candidato rechazado correctamente",
          });
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      }
    },

  },
}

</script>
<style scoped></style>
