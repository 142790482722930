<template>
  <div
    class="hero is-fullheight justify-center"
    style="
      background: #0f486d;
      background: linear-gradient(339deg, #0f486d 0%, rgba(66,203,182,1) 100%);
    "
  >
    <div class="columns is-centered">
      <div class="column is-3">
        <div class="card">
          <div class="card-header">
            <div class="column">
              <div class="is-size-6">
                Recuperar contraseña
              </div>
            </div>
          </div>
          <div class="card-content">
            <b-field label="Correo electrónico">
              <b-input
                v-model="form.email"
                type="email"
              />
            </b-field>
            <div class="columns is-multiline">
              <div class="column is-12 has-text-right">
                <b-button
                  type="is-primary"
                  @click="recoverPassword"
                >
                  Recuperar contraseña
                </b-button>
              </div>
              <div class="column is-12">
                <router-link to="/login">¿Ya tienes usuario y contraseña?</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        email: null
      }
    }
  },
  watch: {
    'form.email': function (val) {
      if(val) this.form.email = this.form.email.toLowerCase()
    }
  },
  methods: {
    recoverPassword: async function () {
      try {
        await this.$store.dispatch('sendResetPassword', this.form)
        this.$router.push('/login')
      } finally {
        this.form.email = null;
      }
    }
  }
}
</script>
