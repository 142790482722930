const userManagentTree = {
    'superqueen': {
      "assign-all-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "assign-company": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "list-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "create-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "delete-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "get-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "get-recruiter": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "unassign-all-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "update-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "upload-photo": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
    },
    'manager': {
      "assign-all-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "assign-company": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "list-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "create-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "delete-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "get-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "get-recruiter": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "unassign-all-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "update-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "upload-photo": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
    },
    'leader-recruiter-manager': {
      "assign-all-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "assign-company": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "list-companies": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "create-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "delete-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "get-user": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "get-recruiter": [
        "superqueen",
        "manager",
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "unassign-all-companies": [
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "update-user": [
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
      "upload-photo": [
        "recruiter-manager",
        "recruiter",
        "designer-manager",
        "designer",
        "hrmanager",
        "solicitant",
      ],
    },
    'recruiter-manager': {
      "assign-all-companies": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "assign-company": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "list-companies": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "create-user": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "delete-user": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "get-user": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "get-recruiter": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "unassign-all-companies": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "update-user": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "upload-photo": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
    },
    'recruiter': {
      "assign-all-companies": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "assign-company": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "list-companies": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "create-user": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "delete-user": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "get-user": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "get-recruiter": [
        "recruiter-manager",
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "unassign-all-companies": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "update-user": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
      "upload-photo": [
        "recruiter",
        "hrmanager",
        "solicitant",
      ],
    },
    'designer-manager': {
      "assign-all-companies": [
        "designer-manager",
        "designer",
      ],
      "assign-company": [
        "designer-manager",
        "designer",
      ],
      "list-companies": [
        "designer-manager",
        "designer",
      ],
      "create-user": [
        "designer-manager",
        "designer",
      ],
      "delete-user": [
        "designer-manager",
        "designer",
      ],
      "get-user": [
        "designer-manager",
        "designer",
      ],
      "get-recruiter": [
        "designer-manager",
        "designer",
      ],
      "unassign-all-companies": [
        "designer-manager",
        "designer",
      ],
      "update-user": [
        "designer-manager",
        "designer",
      ],
      "upload-photo": [
        "designer-manager",
        "designer",
      ],
    },
    'designer': {
      "assign-all-companies": [
        "designer",
      ],
      "assign-company": [
        "designer",
      ],
      "list-companies": [
        "designer",
      ],
      "create-user": [
        "designer",
      ],
      "delete-user": [
        "designer",
      ],
      "get-user": [
        "designer",
      ],
      "get-recruiter": [
        "designer",
      ],
      "unassign-all-companies": [
        "designer",
      ],
      "update-user": [
        "designer",
      ],
      "upload-photo": [
        "designer",
      ],
    },
  };
  
  export default userManagentTree;