<template>
  <div>
    <b-modal
      :active.sync="isOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Asignar empresas</div>
        </div>
        <div class="modal-card-body">
          <div class="columns">
            <div class="column" v-if="checkPermission('unassign-all-companies')"
            >
              <b-button
                expanded
                outlined
                type="is-danger"
                @click="unassignAll"
              >
                Desasignar todas
              </b-button>
            </div>
            <div class="column" v-if="checkPermission('assign-all-companies')"
            >
              <b-button
                expanded
                outlined
                type="is-blue"
                @click="assignAll"
              >
                Asignar todas
              </b-button>
            </div>
          </div>
          <div
            v-for="(userCompany, index) in companies"
            :key="`company-${index}`"
            class="card"
          >
            <div
              class="card-content has-text-weight-semibold"
              :style="{ color: userCompany.company.mainColor || '#0f486d' }"
            >
              <div class="is-flex justify-space-between">
                <div>
                  {{ userCompany.company.name }}
                </div>
                <div 
                  v-if="checkPermission('assign-company')"
                >
                  <b-switch
                    outlined
                    passive-type="is-danger"
                    type="is-blue"
                    v-model="userCompany.active"
                    @input="assignCompany(userCompany, $event)"
                  >
                  </b-switch>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-card-foot is-justify-content-flex-end">
          <b-button
            rounded
            @click="close"
          >
            Cancelar
          </b-button>
          <b-button
            rounded
            type="is-primary"
            @click="close"
          >
            Guardar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        isOpen: false,
        user: {},
        roles: [
          { value: "recruiter-manager", text: "Coordinador de Adquisición de Talento" },
          { value: "recruiter", text: "Especialista en Adquisición de Talento" },
          { value: "designer-manager", text: "Coordinador de Comunicación" },
          { value: "designer", text: "Especialista de Comunicación" },
          { value: "account-manager", text: "Coordinador de Cuentas Clave" },
          { value: "account", text: "Analista de Cuentas Clave" },
          { value: "hrmanager", text: "Manager de RH" },
          { value: "solicitant", text: "Solicitante" },
          { value: "manager", text: "Manager" },
        ],
        companies: [],
      };
    },
    computed: {
      companyId() {
        return this.company || this.$store.state.currentCompany.id;
      },
      recruiters() {
        return this.$store.state.recruiters;
      },
    },
    watch: {
      async isOpen(val) {
        if (val) {
          await this.loadCompanies();
        }
      },
    },
    methods: {
      open(user) {
        if (user) {
          this.user = { ...user };
        }
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
      async loadCompanies() {
        this.isLoading = true;

        try {
          const response = await this.$api.get(`/users/${this.user._id}/companies`);
          this.companies = response.data;
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      },
      async assignCompany(userCompany, val) {
        this.isLoading = true;
        try {
          const payload = {
            company: userCompany.company._id,
            val: val,
          };
          await this.$api.post(`/users/${this.user._id}/assign-company`, payload);
          this.$toast.success({
            message: "Se actualizó exitosamente al usuario",
          });
          this.$emit("updated");
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      },
      async assignAll() {
        this.isLoading = true;     

        try {
          await this.$api.post(`/users/${this.user._id}/assign-all-companies`);
          this.$toast.success({
            message: "Se actualizó exitosamente al usuario",
          });
          this.$emit("updated");
          this.close();
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      },
      async unassignAll() {
        this.isLoading = true;

        try {
          await this.$api.post(`/users/${this.user._id}/unassign-all-companies`);
          this.$toast.success({
            message: "Se actualizó exitosamente al usuario",
          });
          this.$emit("updated");
          this.close();
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      },
      checkPermission (action) {        
          //Revisando permisos de usuario por medio de rol
          const permissionByManagmentTree = this.$hasPermissionByManagmentTree({
          user: this.$store.state.session.user,
          userOnRole: this.user.role,
          userOnAction: action ,
          });

          return permissionByManagmentTree;
      }
  },
    
  };
</script>
