<template>
    <div>
        <div class="column pl-0 pt-0 mb-2 is-full is-flex is-align-content-center">
            <div class="title is-4 mb-0 has-text-blue">
                {{ generalDataTranslation.editGeneralProfile || generalDataTranslation.generalProfile }}
            </div>
            <!-- Icono guardar cambios de perfil general -->
            <a class="ml-2" @click="updateGeneralProfile">
                <b-tooltip animated class="is-flex is-align-items-end" label="Guardar cambios" position="is-top"
                    type="is-success">
                    <b-icon class="icons" icon="floppy-disk"></b-icon>
                </b-tooltip>
            </a>
        </div>

        <ValidationObserver ref="observer">
            <div class="columns is-multiline">

                <!-- Fecha de nacimiento -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Fecha de nacimiento" v-slot="{ errors, valid }">
                        <b-field label="Fecha de nacimiento" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-datepicker v-model="form.birthdate" placeholder="Selecciona la fecha"
                                icon="calendar-range" editable :mobile-native="false" :min-date="maxDate" :max-date="minDate"/>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Género -->
                <div class="column is-6">
                    <ValidationProvider rules="required" name="Género" v-slot="{ errors, valid }">
                        <b-field label="Género" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.gender" placeholder="Selecciona tu género">
                                <option v-for="(option, index) in genderOptions" :key="index" :value="option">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Pronoun -->
                <div class="column is-6">
                    <ValidationProvider rules="required" name="Pronoun" v-slot="{ errors, valid }">
                        <b-field label="Pronoun" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.pronoun" placeholder="Selecciona tu pronombre">
                                <option v-for="(option, index) in pronounOptions" :key="index" :value="option">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Tiene discapacidad -->
                <div class="column is-6">
                    <ValidationProvider rules="required" name="¿Tienes alguna discapacidad?" v-slot="{ errors, valid }">
                        <b-field label="¿Tienes alguna discapacidad?"
                            :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <div class="is-flex">
                                <b-radio v-model="form.hasDisability" :native-value="true" name="hasDisability">
                                    Sí
                                </b-radio>
                                <b-radio v-model="form.hasDisability" :native-value="false" name="hasDisability"
                                    class="pl-2">
                                    No
                                </b-radio>
                            </div>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Tipo de discapacidad (solo si hasDisability es true) -->
                <div v-if="form.hasDisability" class="column is-6">
                    <ValidationProvider rules="required" name="Tipo de discapacidad" v-slot="{ errors, valid }">
                        <b-field label="Tipo de discapacidad" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.disability" placeholder="Selecciona el tipo de discapacidad"
                                expanded>
                                <option v-for="(disability, index) in disabilitiesOptions" :key="index"
                                    :value="disability">
                                    {{ disability }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Teléfono -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required|numeric|max:10`" name="Teléfono" v-slot="{ errors, valid }">
                        <b-field label="Teléfono" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.phone" type="tel"
                                placeholder="Escribe tu número de teléfono"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Email -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required|email`" name="Email" v-slot="{ errors, valid }">
                        <b-field label="Email" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.email" type="email"
                                placeholder="Escribe tu correo electrónico"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- CURP -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required|max:${maxCurpLength}`" name="CURP" v-slot="{ errors, valid }">
                        <b-field label="CURP" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <b-input v-model="form.curp" placeholder="Escribe tu CURP" :maxCurpLength="maxCurpLength"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>
                <div v-if="showCountryFields" class="column is-6">

                    <!-- País de residencia -->
                    <ValidationProvider rules="required" name="País de residencia" v-slot="{ errors, valid }">
                        <b-field label="País de residencia" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.country" placeholder="Selecciona tu país de residencia" expanded>
                                <option v-for="(country, index) in countries" :key="`country-option-${index}`"
                                    :value="country.country">
                                    {{ country.country }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>

                    <!-- País de origen -->
                    <ValidationProvider rules="required" name="País de origen" v-slot="{ errors, valid }">
                        <b-field label="País de origen" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.originCountry" placeholder="Selecciona tu país de origen" expanded>
                                <option v-for="(country, index) in countries" :key="`country-option-${index}`"
                                    :value="country.country">
                                    {{ country.country }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>

                <div v-if="showStateFields" class="column is-6">
                    <!-- Estado -->
                    <ValidationProvider rules="required" name="Estado" v-slot="{ errors, valid }">
                        <b-field label="Estado" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.state" placeholder="Escribe tu estado"></b-input>
                        </b-field>
                    </ValidationProvider>

                    <!-- Ciudad -->
                    <ValidationProvider rules="required" name="Ciudad" v-slot="{ errors, valid }">
                        <b-field label="Ciudad" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.city" placeholder="Escribe tu ciudad"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <b-loading :active.sync="isLoading" />
    </div>
</template>

<script>
import { updateGeneralProfile } from '@/api/candidate';
import { genders, pronouns, disabilities } from '@/lib/applicationDictionaries';
import countries from '@/lib/countries';

export default {
    props: {
        profile: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    data() {
        const hoy = new Date();
        const normalizeDate = (date) => {
            const normalized = new Date(date);
            normalized.setHours(0, 0, 0, 0);
            return normalized;
        };
        
        return {
            form: {
                birthdate: this.profile.birthdate ? new Date(this.profile.birthdate) : null,
                gender: this.profile.gender || '',
                pronoun: this.profile.pronoun || '',
                hasDisability: this.profile.hasDisability,
                disability: this.profile.disability || '',
                phone: this.profile.phone || '',
                email: this.profile.email || '',
                curp: this.profile.curp || '',
                country: this.profile.country || '',
                originCountry: this.profile.originCountry || '',
                state: this.profile.state || '',
                city: this.profile.city || '',
            },
            isLoading: false,
            currentLanguage: 'es',
            showCountryFields: false,
            showStateFields: false,
            countries: countries,
            minDate: normalizeDate(new Date(hoy.getFullYear() - 15, hoy.getMonth(), hoy.getDate())),
            maxDate: normalizeDate(new Date(hoy.getFullYear() - 99, hoy.getMonth(), hoy.getDate())), 
        };
    },
    computed: {
        generalDataTranslation() {
            return this.$t('screens.candidate.detail');
        },
        genderOptions() {
            return genders[this.currentLanguage];
        },
        pronounOptions() {
            return pronouns[this.currentLanguage];
        },
        disabilitiesOptions() {
            return disabilities[this.currentLanguage];
        },
        maxCurpLength() {
            return this.currentLanguage === 'mx' ? 18 : 9
        },
    },
    mounted() {
        this.currentLanguage = this.detectLanguage();
        this.checkFormFields();
    },
    watch: {
        'form.hasDisability'(newValue) {
            if (!newValue) {
                this.form.disability = '';
            }
        },
        'form.country'() {
            this.checkFormFields();
        },
        'form.originCountry'() {
            this.checkFormFields();
        },
        'form.state'() {
            this.checkFormFields();
        },
        'form.city'() {
            this.checkFormFields();
        }
    },
    methods: {
        checkFormFields() {
            if (this.form.country || this.form.originCountry) {
                this.showCountryFields = true;
                this.showStateFields = false;
            } else if (this.form.state || this.form.city) {
                this.showCountryFields = false;
                this.showStateFields = true;
            } else {
                this.showCountryFields = false;
                this.showStateFields = false;
            }
        },
        detectLanguage() {
            if (genders.mx.includes(this.form.gender)) {
                return 'mx';
            } else if (genders.es.includes(this.form.gender)) {
                return 'es';
            } else if (genders.en.includes(this.form.gender)) {
                return 'en';
            }
            return 'es';
        },
        async updateGeneralProfile() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                this.$toast.error({
                    message: 'Uno o más campos están incompletos o son inválidos.',
                });
                return;
            }
            try {
                const userId = this.$store.state.session.user.id;

                this.isLoading = true;
                const payload = {
                    ...this.form,
                    id: this.$route.params.id,
                    user: userId,
                    birthdate: this.form.birthdate ? this.form.birthdate.toISOString() : null,
                    disability: this.form.hasDisability ? this.form.disability : '',
                    country: this.showCountryFields ? this.form.country : '',
                    originCountry: this.showCountryFields ? this.form.originCountry : '',
                    state: this.showStateFields ? this.form.state : '',
                    city: this.showStateFields ? this.form.city : '',
                };

                await updateGeneralProfile(payload);

                this.$toast.success({
                    message: 'Perfil general actualizado con éxito',
                });

                this.$emit('generalProfileUpdated', payload);
            } catch (error) {
                this.$toast.error({
                    message: error,
                });
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
