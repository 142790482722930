<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">

        <!-- Agregar o editar nuevos campos -->
        <div class="card" v-if="$hasPermissionsByRole(companyFieldsEditionAllowedRoles)">
          <div class="card-content">
            <!-- Recordaotrio y boton para agregar -->
            <div class="is-flex justify-space-between">
              <p class="title is-4">Agregar campos a las vacantes de la empresa.</p>
              <b-switch v-model="otherFields" type="is-blue" outlined passive-type="is-info">{{ otherFields ? 'Agregar campos' : 'Editar campos' }}</b-switch>
            </div>
            <!-- Campos agregados -->
            <div v-if="otherFields">
              <!-- Subtitulo de la carta -->
              <div class="is-flex is-justify-content-space-evenly mt-4">
                <p class="title is-5 mt-2">Estos campos son específicos para las vacantes de esta empresa</p>
                <b-button @click="addField" type="is-blue" outlined v-if="fields.length < 1" :disabled="fields.length > 0">Agregar campo</b-button>
                <b-tooltip :label="reminder" multilined type="is-blue">
                  <b-button type="is-blue" @click="saveFields" v-if="otherFields && (fields.length > 0)" outlined>Guardar Campo</b-button>
                </b-tooltip>
              </div>
              <!-- Renderizado de campos -->
              <div class="scrolleable" v-if="fields.length > 0" >
                <!-- donde se renderizan las opciones -->
                <div v-for="(field,fIndex) in fields" :key="`field-${fIndex}`"
                  class="card m-2 p-4" style="border-radius: 10px;">
                  <!-- Borrar campo -->
                  <div class="is-flex is-justify-content-flex-end">
                    <b-button outlined type="is-danger" icon-left="trash" @click="deleteField(index)" />
                  </div>
                  <!-- bases del campo -->
                  <div class="columns mb-5">
                    <!-- nombre del campo -->
                    <div class="column is-5">
                      <b-field :label="`Campo ${field.name}`" >
                        <b-input placeholder="-" v-model="field.name" :lazy="true" required/>
                      </b-field>
                      <b-checkbox v-model="field.isRequired">Campo obligatorio</b-checkbox>
                    </div>
                    <!-- tipo de campo -->
                    <div class="column is-3">
                      <b-field :label="`Tipo ${fIndex+1}`">
                        <b-select v-model="field.fieldType" @input="isArray(field)" expanded required>
                          <option v-for="(option, index) in fieldsOptions" :key="`option-${index}`" :value="option.value">{{option.text}}</option>
                        </b-select>
                      </b-field>
                    </div>
                    <!-- opciones de Campo -->
                    <div class="column is-4" v-if="hasOptions(field.fieldType)">
                      <div v-if=" field.fieldType === 'string'  ">
                        <b-field label="Opciones">
                          <b-tooltip :label="tooltips.strings" multilined>
                            <b-select v-model="field.options" required>
                              <option v-for="(option, index) in options.strings" :key="`option-${index}`" :value="option.value">{{ option.text }}</option>
                            </b-select>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <div v-else-if="field.fieldType === 'Date' ">
                        <b-field label="Opciones">
                          <b-tooltip :label="tooltips.dates" multilined >
                            <b-select v-model="field.options" required>
                              <option v-for="(option, index) in options.dates" :key="`option-${index}`" :value="option.value">{{option.text}}</option>
                            </b-select>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <div v-else-if="field.fieldType === 'Array'">
                        <b-field label="Opciones">
                          <b-tooltip :label="tooltips.multiple" multilined >
                            <b-taginput v-model="field.options" >
                            </b-taginput>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <div v-else-if="field.fieldType === 'Select'">
                        <b-field label="Opciones">
                          <b-tooltip :label="tooltips.multiple" multilined >
                            <b-taginput v-model="field.options" >
                            </b-taginput>
                          </b-tooltip>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <!-- Edicion del campo -->
                  <p class="subtitle">Edicion del campo</p>
                  <div class="columns" >
                    <!-- Colocar placeholder y tooltip -->
                    <div class="column is-6">
                      <b-field label="Agrega un texto 'guía' en la entrada" >
                        <b-input placeholder="Este es un texto 'guía'" v-model="field.placeholder"/>
                      </b-field>
                      <div class="is-flex">
                        <b-field label="Agrega un dato importante para mostrar" class="mr-4">
                          <b-tooltip label="Asi se muestra un dato importante"  style="width: 100%">
                            <b-input  v-model="field.tooltip"/>
                          </b-tooltip>
                        </b-field>
                        <b-field label="Dirección">
                          <b-select v-model="field.tooltipDirection">
                            <option value="is-top">Arriba</option>
                            <option value="is-right">Derecha</option>
                            <option value="is-left">Izquierda</option>
                            <option value="is-bottom">Abajo</option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>
                    <!-- Colocar icono -->
                    <div class="column is-6">
                      <p class="is-size-6 has-text-weight-bold">Selecciona un icono</p>
                      <div class="is-flex is-flex-wrap-wrap" >
                        <b-icon v-for="(icon,iIndex) in icons" :key="`icon-${iIndex}`" :icon="icon" size="is-medium"
                          :id="`icon-${fIndex}-${iIndex}`"  @click.native="selectedIcon(fIndex, iIndex); field.icon = icon"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Visualizador -->
                  <p class="has-text-weight-semibold has-text-centered is-size-5">Previsualización del campo</p>
                  <div class="is-flex is-justify-content-center mt-4 ">
                    <!-- Strings -->
                    <div v-if="field.fieldType === 'string'">
                      <b-field :label="field.name">
                        <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                          <b-input v-model="field.value" v-if="field.options === 'textarea'" type="textarea" :disabled="field.isDisabled" :placeholder="field.placeholder"  />
                          <b-input v-model="field.value" v-else  :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon"/>
                        </b-tooltip>
                      </b-field>
                    </div>
                    <!-- Number -->
                    <div v-if="field.fieldType === 'number'"  >
                      <b-field :label="field.name">
                        <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                          <b-input v-model="field.value" type="number" :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon"/>
                        </b-tooltip>
                      </b-field>
                    </div>
                    <!-- Dates -->
                    <div v-if="field.fieldType === 'Date'"  >
                      <b-field :label="field.name">
                        <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                          <b-datepicker v-model="field.rangeDates" :type="field.options === 'month' ? 'month' : null" range v-if="field.options === 'range'"
                          :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon" :unselectable-days-of-week="[0,6]">
                          </b-datepicker>
                          <b-datepicker v-model="field.value" :type="field.options === 'month' ? 'month' : null"  v-else
                          :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon" :unselectable-days-of-week="[0,6]">
                          </b-datepicker>
                        </b-tooltip>
                      </b-field>
                    </div>
                    <!-- Boolean -->
                    <div v-if="field.fieldType === 'boolean'"  >
                      <b-field :label="field.name">
                        <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                          <b-switch v-model="field.value" :disabled="field.isDisabled" :placeholder="field.placeholder">
                          </b-switch>
                        </b-tooltip>
                      </b-field>
                    </div>
                    <!-- tags Array -->
                    <div v-if="field.fieldType === 'Array'">
                      <b-field :label="field.name">
                        <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                          <div class="is-flex is-align-items-center is-flex-wrap-wrap is-justify-content-space-evenly">
                            <b-icon :icon="field.icon" />
                            <b-checkbox class="mb-2" v-model="field.value" v-for="(option,index) in field.options" :key="`option-${index}`" :native-value="option" :disabled="field.isDisabled">
                              {{ option }}
                            </b-checkbox>
                          </div>
                        </b-tooltip>
                      </b-field>
                    </div>
                    <!-- select Array -->
                    <div v-if="field.fieldType === 'Select'"  >
                      <b-field :label="field.name">
                        <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                          <b-select v-model="field.value" :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon">
                            <option
                              v-for="(option, index) in field.options"
                              :value="option"
                              :key="`option-${index}`">
                              {{ option }}
                            </option>
                          </b-select>
                        </b-tooltip>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Edicion de campos -->
            <div v-else>
              <div class="mt-4" v-if="vacancyFields.length>0" >
                <!-- Seleccionar campo para editar -->
                <div>
                  <!-- Mostrar campos -->
                  <div class="field-selector" v-if="!field" style="background-color: #f5f5f5; border-radius: 10px;">
                    <div class="field-card p-4" v-for="(field,fIndex) in vacancyFields" :key="`field-${fIndex}`"
                      @click="selectedField(field,fIndex)">
                      <p class="has-text-weight-bold">{{ field.name }}</p>
                      <p>{{ field.isActive ? 'Campo activo' : 'Campo inhabilitado' }}</p>
                    </div>
                  </div>
                  <!-- donde se renderizan las opciones -->
                  <div v-if="field" style="border-radius: 10px"
                    class="card m-2 p-4">
                    <!-- Borrar campo -->
                    <div class="is-flex is-justify-content-space-between mb-4">
                      <b-button icon-left="arrow-left" type="is-blue" outlined @click="field = null"/>
                      <div class="is-flex is-align-items-center">
                        <b-switch class="mr-4" v-model="field.isActive" outlined type="is-blue"
                        @input="openTurnDisabledDialog(field, field?.index)">Campo {{field.isActive ? 'activo' : 'inactivo'}}</b-switch>
                        <b-button outlined type="is-danger" icon-left="trash" @click="openFieldDeleteDialog(field, field?.index)" />
                      </div>
                    </div>
                    <!-- bases del campo -->
                    <div class="columns mb-5">
                      <!-- nombre del campo -->
                      <div class="column is-5">
                        <b-field :label="`Campo ${field.name}`" >
                          <b-input placeholder="-" v-model="field.name" :lazy="true"/>
                        </b-field>
                        <b-checkbox v-model="field.isRequired">Campo obligatorio</b-checkbox>
                      </div>
                      <!-- tipo de campo -->
                      <div class="column is-3">
                        <b-field :label="`Tipo ${field?.index+1}`">
                          <b-select v-model="field.fieldType" @input="isArray(field)" expanded>
                            <option v-for="(option, index) in fieldsOptions" :key="`option-${index}`" :value="option.value">{{option.text}}</option>
                          </b-select>
                        </b-field>
                      </div>
                      <!-- opciones de Campo -->
                      <div class="column is-4" v-if="hasOptions(field.fieldType)">
                        <div v-if=" field.fieldType === 'string'  ">
                          <b-field label="Opciones">
                            <b-tooltip :label="tooltips.strings" multilined >
                              <b-select v-model="field.options" >
                                <option v-for="(option, index) in options.strings" :key="`option-${index}`" :value="option.value">{{ option.text}}</option>
                              </b-select>
                            </b-tooltip>
                          </b-field>
                        </div>
                        <div v-else-if="field.fieldType === 'Date' ">
                          <b-field label="Opciones">
                            <b-tooltip :label="tooltips.dates" multilined >
                              <b-select v-model="field.options">
                                <option v-for="(option, index) in options.dates" :key="`option-${index}`" :value="option.value">{{option.text}}</option>
                              </b-select>
                            </b-tooltip>
                          </b-field>
                        </div>
                        <div v-else-if="field.fieldType === 'Array'">
                          <b-field label="Opciones">
                            <b-tooltip :label="tooltips.multiple" multilined >
                              <b-taginput v-model="field.options">
                              </b-taginput>
                            </b-tooltip>
                          </b-field>
                        </div>
                        <div v-else-if="field.fieldType === 'Select'">
                          <b-field label="Opciones">
                            <b-tooltip :label="tooltips.multiple" multilined >
                              <b-taginput v-model="field.options">
                              </b-taginput>
                            </b-tooltip>
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <!-- Edicion del campo -->
                    <p class="subtitle has-text-weight-semibold">Edicion del campo</p>
                    <div class="columns" >
                      <!-- Edicion placeholder y tooltip -->
                      <div class="column is-6">
                        <b-field label="Agrega un texto 'guía' en la entrada" >
                          <b-input placeholder="Este es un texto 'guía'" v-model="field.placeholder"/>
                        </b-field>
                        <div class="is-flex">
                          <b-field label="Agrega un dato importante para mostrar" class="mr-4">
                            <b-tooltip label="Asi se muestra un dato importante"  expanded style="width: 100%">
                              <b-input  v-model="field.tooltip" expanded/>
                            </b-tooltip>
                          </b-field>
                          <b-field label="Dirección">
                            <b-select v-model="field.tooltipDirection">
                              <option value="is-top">Arriba</option>
                              <option value="is-right">Derecha</option>
                              <option value="is-left">Izquierda</option>
                              <option value="is-bottom">Abajo</option>
                            </b-select>
                          </b-field>
                        </div>
                      </div>
                      <!-- Edicion de icono -->
                      <div class="column is-6" >
                        <b-field label="Selecciona un icono">
                          <div class="is-flex is-flex-wrap-wrap">
                            <b-icon v-for="(icon,iIndex) in icons" :key="`icon-${iIndex}`" :icon="icon" size="is-medium" class="i-con"
                              :id="`icon-${field?.index}-${iIndex}`"  @click.native="selectedIcon(field?.index, iIndex); field.icon = icon"
                            />
                          </div>
                        </b-field>
                      </div>
                    </div>
                    <!-- Visualizador -->
                    <p class="has-text-weight-semibold">Previsualización del campo</p>
                    <div class="is-flex is-justify-content-center">
                      <!-- Strings -->
                      <div v-if="field.fieldType === 'string'">
                        <b-field :label="field.name">
                          <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                            <b-input v-model="field.value" v-if="field.options === 'textarea'" :disabled="field.isDisabled" :placeholder="field.placeholder"  />
                            <b-input v-model="field.value" v-else :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon"/>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <!-- Number -->
                      <div v-if="field.fieldType === 'number'"  >
                        <b-field :label="field.name">
                          <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                            <b-input v-model="field.value" type="number" :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon"/>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <!-- Dates -->
                      <div v-if="field.fieldType === 'Date'"  >
                        <b-field :label="field.name">
                          <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                            <b-datepicker v-model="field.rangeDates" :type="field.options === 'month' ? 'month' : null" range v-if="field.options === 'range'"
                            :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon" :unselectable-days-of-week="[0,6]">
                            </b-datepicker>
                            <b-datepicker v-model="field.value" :type="field.options === 'month' ? 'month' : null"  v-else
                            :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon" :unselectable-days-of-week="[0,6]">
                            </b-datepicker>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <!-- Boolean -->
                      <div v-if="field.fieldType === 'boolean'"  >
                        <b-field :label="field.name">
                          <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                            <b-switch v-model="field.value" :disabled="field.isDisabled" :placeholder="field.placeholder">
                            </b-switch>
                            <b-icon :icon="field.icon" />
                          </b-tooltip>
                        </b-field>
                      </div>
                      <!-- tags Array -->
                      <div v-if="field.fieldType === 'Array'">
                        <b-field :label="field.name">
                          <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                            <div style="display: flex;  flex-wrap:wrap">
                              <b-checkbox class="mb-2" v-model="field.value" v-for="(option,index) in field.options" :key="`option-${index}`" :native-value="option" :disabled="field.isDisabled">
                                {{ option }}
                              </b-checkbox>
                            </div>
                          </b-tooltip>
                        </b-field>
                      </div>
                      <!-- select Array -->
                      <div v-if="field.fieldType === 'Select'"  >
                        <b-field :label="field.name">
                          <b-tooltip :label="field.tooltip" :position="field.tooltipDirection">
                            <b-select v-model="field.value" :disabled="field.isDisabled" :placeholder="field.placeholder" :icon="field.icon">
                              <option
                                v-for="(option, index) in field.options"
                                :value="option"
                                :key="`option-${index}`">
                                {{ option }}
                              </option>
                            </b-select>
                          </b-tooltip>
                        </b-field>
                      </div>
                    </div>
                    <div class="mt-4 is-flex is-justify-content-flex-end">
                      <b-button type="is-blue" @click="update(field, field?.index )" icon-left="down-to-bracket" label="Guardar Cambios" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="mt-4">
                <p class="title is-5">No hay campos adicionales</p>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!-- Cambiar nombre de campos -->
        <div class="card" v-if="$hasPermissionsByRole(companyFieldsEditionAllowedRoles)">
          <div class="card-content">
            <div class="title is-4">
              Cambiar nombre de campos existentes
            </div>
            <!-- Renderizacion de campos seleccionables -->
            <div class="actualField-card--container">
              <div class="actualField-card card m-2 py-2 columns is-flex is-align-items-center"
               v-for="(field, index) in actualFields" :key="`actual-field-${index}`">
               <div class="column is-3 has-text-centered">
                <p class="has-text-weight-bold">{{field.actualName}}</p>
              </div>
               <div class="column is-6">
                <b-input placeholder="Nombre" v-model="field.newName" class="mb-3"/>
                <b-input placeholder="Placeholder" v-model="field.newPlaceholder"/>
              </div>
               <div class="column">
                <b-button icon-left="check" outlined type="is-blue" class="mr-2"
                @click="renameFields(field.actualName, field?.newName, field.newPlaceholder, index)"/>
                <b-button icon-left="xmark" outlined type="is-danger"
                @click="unRenameFields( index)"/>
              </div>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!-- Borra empresa -->
        <div class="card" v-if="$hasPermissionsByRole(superAdminRoles)">
          <div class="card-content">
            <div class="title is-4">
              Borrar empresa
            </div>
            <div class="is-flex justify-flex-end">
              <b-button
                rounded
                type="is-danger"
                icon-left="trash"
                @click="openRemoveDialog()"
              >
                Borrar {{ company.name }}
              </b-button>
            </div>
          </div>
        </div>
        <br>

        <!-- Asignar reclutador -->
        <div class="card" v-if="$hasPermissionsByRole(superAdminRoles)">
          <div class="card-content">
            <div class="title is-4">
              Asignar las vacantes a
            </div>
            <div class="">
              <b-select placeholder="Selecciona un reclutador" name="" id="" icon="users"
              @input="asignRecruiter(asigned) " v-model="asigned">
                <option :value="recruiter.id" v-for="(recruiter,index) in recruiters"
                :key="index">{{recruiter.fullName}} </option>
              </b-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { superAdminRoles, companyFieldsEditionAllowedRoles } from '@/lib/permissions'

export default {

  data () {
    return {
      superAdminRoles,
      companyFieldsEditionAllowedRoles,
      deleteModal: false,
      otherFields: false,
      fields: [{
        name: '',
        fieldType: null,
        value: null,
        options: null,
        isActive: true,
        placeholder: null,
        tooltip: null,
        icon: null,
        rangeDates: [],
        tooltipDirection: 'is-top',
        isRequired: false
      }],
      vacancyFields: [],
      fieldsOptions: [
        { text: 'Texto', value: 'string' },
        { text: 'Númerico', value: 'number' },
        { text: 'Fecha', value: 'Date' },
        { text: 'Verdadero o Falso', value: 'boolean' },
        { text: 'Selección Multiple', value: 'Array' },
        { text: 'Opción Seleccionable', value: 'Select'}
      ],
      types: [
        { 'string': 'Texto' },
        { 'number': 'Númerico' },
        { 'Date': 'Fecha' },
        { 'true': 'Verdadero o Falso'  },
        { 'false': 'Verdadero o Falso'  },
        { 'boolean': 'Verdadero o Falso' },
        { 'Array': 'Selección Multiple'},
        { 'Select': 'Opción Seleccionable' }
      ],
      options:{
        strings:[
          { text: 'Default', value: 'default'},
          { text: 'Área de texto', value: 'textarea' },
        ],
        dates: [
          { text: 'Default', value: 'default'},
          { text: 'Mensual', value: 'month' },
          { text: 'Rango de fecha', value: 'range'}
        ]
      },
      tooltips:{
        strings: 'Desde 1 a 25 caracteres: default. Desde 25 a 100 caracteres: Area de texto.',
        dates: 'Default: incluye dias. Ej: DD/MMMM/AAAA. Mensual: Exclusivamente meses. Ej: MM/AAAA',
        multiple: 'Ingresa la opción y presiona Enter.'
      },
      reminder: 'Estos campos son exclusivos para Pro Meritum y no podrán ser visualizados por los candidatos.',
      tags: [],
      icons: [
        'magnifying-glass', 'phone', 'envelope', 'location-dot', 'key', 'files', 'address-card',
        'user-pen', 'user-large', 'user-group', 'user-clock', 'user-check', 'seal-question',
        'money-check-dollar-pen', 'money-bill-simple', 'messages', 'message-smile', 'message',
        'memo-circle-info', 'memo-circle-check', 'memo' , 'id-card', 'hourglass-clock', 'grip-dots',
        'folders', 'file-user', 'file-medical', 'file-magnifying-glass', 'envelope-dot', 'arrows-rotate',
        'arrows-minimize', 'arrows-maximize',
      ],
      prevIcon: null,
      prevField: null,
      field: null,
      actualFields: [
        { actualName: 'Nombre de la vacante', newName: '', newPlaceholder: '' },
        { actualName: 'Duración', newName: '', newPlaceholder: '' },
        { actualName: 'Número de Posiciones', newName: '', newPlaceholder: '' },
        { actualName: 'Estado', newName: '', newPlaceholder: '' },
        { actualName: 'Ciudad', newName: '', newPlaceholder: '' },
        { actualName: 'Horario', newName: '', newPlaceholder: '' },
        { actualName: 'Mostrar Beca', newName: '', newPlaceholder: '' },
        { actualName: 'Beca', newName: '', newPlaceholder: '' },
        { actualName: 'Modalidad', newName: '', newPlaceholder: '' },
        { actualName: 'Licenciatura(s)', newName: '', newPlaceholder: '' },
        { actualName: 'Nivel académico', newName: '', newPlaceholder: '' },
        { actualName: 'Semestre Mínimo', newName: '', newPlaceholder: '' },
        { actualName: 'Semestre Máximo', newName: '', newPlaceholder: '' },
        { actualName: 'Nivel de Excel', newName: '', newPlaceholder: '' },
        { actualName: 'Nivel de Inglés', newName: '', newPlaceholder: '' },
        { actualName: 'Termino de Materias', newName: '', newPlaceholder: '' },
        { actualName: 'Titulación', newName: '', newPlaceholder: '' },
        { actualName: 'Habilidades', newName: '', newPlaceholder: '' },
        { actualName: 'Conocimientos', newName: '', newPlaceholder: '' },
        { actualName: 'Objetivo', newName: '', newPlaceholder: '' },
        { actualName: 'Beneficios', newName: '', newPlaceholder: '' },
        { actualName: 'Actividades Diarias', newName: '', newPlaceholder: '' },
        { actualName: 'Contacto de la empresa', newName: '', newPlaceholder: '' },
        { actualName: 'Nombre', newName: '', newPlaceholder: '' },
        { actualName: 'Correo electrónico', newName: '', newPlaceholder: '' },
        { actualName: 'Teléfono', newName: '', newPlaceholder: '' },
        { actualName: 'Proyecto', newName: '', newPlaceholder: '' },
        { actualName: 'Comentarios', newName: '', newPlaceholder: '' },
      ],
      recruiters: [],
      asigned: null
    }
  },
  mounted(){
    this.hasOtherFields()
    this.hasRenamedFields()
    this.getRecruiters()
  },
  computed: {
    user () {
      return this.$store.state.session.user
    },
    company () {
      return this.$store.state.currentCompany
    }
  },
  methods: {
    async hasOtherFields(){
      try{
        const { data } = await this.$api.get(`/aggregate-fields/hasOtherFields/${this.company.id}`)

        if (data?.length === 0) {
          this.$toast.info({
            message: 'No hay campos adicionales',
          })
          return
        }

        if(data?.otherFields.length < 1){
          this.newFields(false)
          this.vacancyFields = []
        }else{
          this.newFields(true)
          this.vacancyFields = data.otherFields
        }
      } catch (error) {
        
        this.$toast.error({
          message: error,
        })
      }

    },
    async newFields(flag){
      try{
        await this.$api.put(`/aggregate-fields/${this.company.id}`, {flag})
      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
    },
    async saveFields(){
      let flag = true
      this.newFields(true)
      if(this.fields){
        this.fields.forEach( (field, index) => {
          if(field.name === ''  ){
            this.$toast.error({
              message: `Verifica el nombre del campo ${index+1}`,
            })
            flag = false
          }
          else if(!field.fieldType){
            this.$toast.error({
              message: `Verifica el tipo del campo ${index+1}`,
            })
            flag = false
          }
          else if( !field.fieldType === 'number' && field.options === null){
            this.$toast.error({
              message: `Verifica las opciones del campo ${index+1}`,
            })
            flag = false
          }
          else if( !field.fieldType === 'boolean' && field.options === null){
            this.$toast.error({
              message: `Verifica las opciones del campo ${index+1}`,
            })
            flag = false
          }
          if( field.fieldType === 'Array'){
            field.value = new Array()
          }
        })
      }
      if(flag){
        try{
          await this.$api.post(`/aggregate-fields/addFields/${this.company.id}`, this.fields[0])
          this.fields = []
          this.hasOtherFields()
        }catch(error){
          this.$toast.error({
            message: error,
          })
        }
      }
    },
    async turnDisabled(isActive, index){
      const payload = { fieldIndex: index, isActive }
      try{
        await this.$api.put(`aggregate-fields/turnDisabled/${this.company.id}`, payload )
      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
    },
    async update( field, index ){
      if(field.fieldType === 'Array' || field.fieldType === 'Select'){
        field.value = new Array()
      }
      const payload = { field, index }
      try{
        await this.$api.put(`aggregate-fields/update/${this.company.id}`, payload)
        this.field = null
        this.hasOtherFields()
      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
    },
    async fieldDelete(index){
      const payload = { fieldIndex: index }
      try{
        await this.$api.put(`aggregate-fields/deleteField/${this.company.id}`, payload )
        this.fields.splice(index,1)
        this.hasOtherFields()
      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
    },
    isArray(field){
      if(field.fieldType === 'Array' || field.fieldType === 'Select' ) {
        field.value = []
        field.options = null
      }
    },
    async renameFields(actualName, newName, newPlaceholder, index){
      const payload = { actualName, newName, newPlaceholder, index }
      try{
        await this.$api
          .put(`aggregate-fields/rename/${this.company.id}`, payload )
        this.$toast.success({
          message: 'Campo actualizado',
        })
      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
    },
    async unRenameFields( index){
      const payload = { index  }
      try{
        await this.$api
          .put(`aggregate-fields/unrename/${this.company.id}`, payload)
        this.actualFields[index].newName = ''
        this.actualFields[index].newPlaceholder = ''
        this.$toast.success({
          message: 'Campo eliminado',
        })
      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
    },
    async hasRenamedFields(){
      try {
        const { data } = await this.$api.get(`aggregate-fields/getRenamed/${this.company.id}`)    

        const filteredData = data.filter(field => field !== null)        
        
        if (filteredData.length > 0) {

          filteredData.forEach(field => {
            this.actualFields[field?.index].newName  = field?.newName
            this.actualFields[field?.index].newPlaceholder = field?.newPlaceholder
          })
        } else {
          this.$toast.info({
            message: 'No hay campos renombrados',
          })
        }
      } catch (error) {        
        this.$toast.error({
          message: error,
        })
      }
     
    },
    addField(){
      this.fields.push({
        name: '',
        fieldType: null,
        value: null,
        options: null,
        isActive: true,
        placeholder: null,
        tooltip: null,
        icon: null

      })
    },
    deleteField(index){
      this.fields.splice(index, 1)
    },
    openFieldDeleteDialog(field, index){
      this.$buefy.dialog.confirm({
        title: `Eliminar campo ${field.name}`,
        message: `Al eliminarla, este campo ya no existirá dentro de las vacantes.`,
        confirmText: 'De acuerdo',
        cancelText: 'No, mejor así',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.fieldDelete(index)
          this.field = null
        }
      })
    },
    openTurnDisabledDialog(field, index){
      if(!field.isActive){
        this.$buefy.dialog.confirm({
          title: `Deshabilitar campo ${field.name}`,
          message: `Al deshabilitar, este campo existirá dentro de las vacantes pero no podrá ser utilizado.`,
          confirmText: 'De acuerdo',
          cancelText: 'No, mejor así',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => this.turnDisabled(field.isActive, index),
          onCancel: () => { this.vacancyFields[index].isActive = !this.vacancyFields[index].isActive  }
        })
      }else{
        this.$buefy.dialog.confirm({
          title: `Habilitar campo ${field.name}`,
          message: `Al habilitar, este campo podrá ser utilizado en las vacantes.`,
          confirmText: 'De acuerdo',
          cancelText: 'No, mejor así',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => this.turnDisabled(field.isActive, index),
          onCancel: () => { this.vacancyFields[index].isActive = !this.vacancyFields[index].isActive  }
        })
      }
    },
    openRemoveDialog () {
      this.$buefy.dialog.confirm({
        title: `Eliminar empresa ${this.company.name}`,
        message: `Al eliminarla, se cerrarán todas las vacantes y se eliminará su página.`,
        confirmText: 'De acuerdo',
        cancelText: 'No, ya me arrepentí',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCompany()
      })
    },
    async deleteCompany () {
      this.isLoading = true
      try {
        await this.$api.delete(`/companies/${this.company.id}`)
        this.$router.push({ name: 'Home' })
        this.$toast.success({
          message: 'La empresa se borró correctamente',
        })
      } catch (error) {
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    hasOptions(value){
      if(!value) return false
      if(value !== 'number' && value !== 'boolean') return true
      else return false
    },
    selectedIcon(fIndex,iIndex){
      if(this.prevField !== fIndex){
        this.prevField = fIndex
      }
      if(this.prevIcon || this.prevIcon === 0){
        const icon = document.getElementById(`icon-${fIndex}-${this.prevIcon}`)
        icon.style.color = ''
        icon.style.border= ''
        icon.style.borderRadius = ''
      }
      const icon = document.getElementById(`icon-${fIndex}-${iIndex}`)
      icon.style.color = '#8ad6ce'
      icon.style.border= '1px solid #8ad6ce'
      icon.style.borderRadius = '5px'
      this.prevIcon = iIndex
      this.prevField = fIndex
    },
    selectedField(field, index){
      this.field = {
        ...field,
        index
      }
    },
    async getRecruiters(){
      this.isLoading = true
      const params = [
        `sectorSelected=${this.company.sector}`,
        `scholarshipSelected=${this.company.scholarship}`,
        `stateSelected=${this.company.state}`
      ]
      try {
        const { data } = await this.$api.get( `/users/recruiters-recommendation?${params.join('&')}` )
        this.recruiters = data
      } catch ( error ) {
        this.$toast.error( {
          message: error,
        } )
      }
      this.isLoading = false
    },
    async asignRecruiter(id){
      const payload = {
        myCompany: this.company,
        recruiter: id
      }
      try {
        await this.$api.put(`/vacancies/default-recruiters`, payload)
        this.$toast.success({
          message:'Reclutador asignado correctamente.',
        })
      } catch (error) {
        this.$toast.error({
          message:'El reclutador no fue asignado correctamente',
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scrolleable{
  padding: 0.6rem;
  width: 100%;
  height: 70vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.field-selector{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  gap: 0.5rem
}
.field-card{
  width: 15rem;
  height: 10rem;
  border: 3px solid #0E486D;
  border-radius: 10px;
  background-color: #e6e6e6;
  margin: 0.5rem 0;
  cursor: pointer;
}
.i-con{
  cursor: pointer;
}
.scrolleable::-webkit-scrollbar{
  width: 0.7rem;
}
.scrolleable::-webkit-scrollbar-track{
  background-color: transparent;
}
.scrolleable::-webkit-scrollbar-track-piece:start{
  margin-top: 1rem;
}
.scrolleable::-webkit-scrollbar-track-piece:end{
  margin-bottom: 1rem;
}
.scrolleable::-webkit-scrollbar-thumb{
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}
.actualField-card{
  border-radius: 5px;
  &--container{
    display: grid ;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
