<template>
  <div>
    <b-modal
      :active.sync="isOpen"
      :destroy-on-hide="true"
      @cancel="close"
      aria-modal
      aria-role="dialog"
      has-modal-card
      trap-focus
    >
      <div
        class="modal-card"
        style="width: 100%"
      >
        <div class="modal-card-head">
          <div class="modal-card-title">Designar solicitante a "{{ vacancyName }}"</div>
        </div>

        <div class="modal-card-body">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <p><b>Solicitante principal:</b> {{ getAuthorName(this.author) }}</p>
            <p>
              <b class="mr-3">¿Solicitantes adicionales?</b>
              <b-switch v-model="otherSolicitants">{{ otherSolicitants ? "Si agregar" : "No agregar" }}</b-switch>
            </p>
          </div>

          <hr />

          <p><b>Nuevo solicitante principal: </b> {{ getAuthorName(newAuthor) }}</p>

          <hr />

          <div
            class="columns is-multiline"
            v-if="!otherSolicitants"
          >
            <div class="column is-12">
              <b>Solicitantes disponibles: {{ companyUsers.length }}</b>
            </div>

            <div class="column is-12">
              <div
                class="columns is-multiline"
                style="max-height: 20rem; overflow-y: scroll"
              >
                <div
                  class="column is-6"
                  v-for="(user, index) in companyUsers"
                  :key="`author-${index}`"
                >
                  <div class="card mt-1">
                    <div class="card-content">
                      <div class="columns is-multiline">
                        <div class="column is-4 is-flex is-align-items-center is-justify-content-center">
                          <figure
                            class="image"
                            style="max-width: 70px"
                          >
                            <img
                              class="is-rounded"
                              :src="
                                user.photo?.url || 'https://via.placeholder.com/150/000000/FFFFFF/?text=Pro Meritum'
                              "
                            />
                          </figure>
                        </div>
                        <div class="column is-8 is-flex is-align-items-center is-justify-content-center">
                          {{ user.fullName }}
                        </div>
                        <div class="column is-12 has-text-centered">
                          {{ user.email }}
                        </div>
                        <div class="column is-12 is-flex is-align-items-center is-justify-content-center">
                          <b-button
                            type="is-primary"
                            rounded
                            @click="setAuthor(user.id)"
                            icon-right="hand-pointer"
                            :disabled="author === user.id"
                          >
                            Asignar
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="columns">
              <div class="column is-6">
                <b>Solicitantes disponibles: {{ companyUsers.length }}</b>
              </div>
              <div class="column is-6">
                <b>Solicitantes adicionales designados: {{ authors.length }}</b>
              </div>
            </div>
            <div class="columns">
              <div
                class="column is-6 px-1"
                style="max-height: 20rem; overflow-y: scroll"
              >
                <div
                  class="card mt-3"
                  v-for="(user, index) in companyUsers"
                  :key="`user-${index}`"
                >
                  <div class="card-content">
                    <div class="columns is-multiline">
                      <div class="column is-4 is-flex is-align-items-center is-justify-content-center">
                        <figure
                          class="image"
                          style="max-width: 70px"
                        >
                          <img
                            class="is-rounded"
                            :src="user.photo?.url || 'https://via.placeholder.com/150/000000/FFFFFF/?text=Pro Meritum'"
                          />
                        </figure>
                      </div>
                      <div class="column is-8 is-flex is-align-items-center is-justify-content-center">
                        {{ user.fullName }}
                      </div>
                      <div class="column is-12 has-text-centered">
                        {{ user.email }}
                      </div>
                      <div class="column is-12 is-flex is-align-items-center is-justify-content-center">
                        <b-button
                          type="is-primary"
                          rounded
                          @click="addCompanyUser(user.id)"
                          icon-right="plus"
                          :disabled="authors.includes(user.id) || disabledMultiple(user.id)"
                        >
                          Agregar
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="column is-6 px-1"
                style="max-height: 20rem; overflow-y: scroll"
              >
                <div class="authors">
                  <div
                    class="card mt-3"
                    v-for="(author, index) in authors"
                    :key="`author-${index}`"
                  >
                    <div class="card-content">
                      <div class="is-flex is-justify-content-end">
                        <b-button
                          icon-left="xmark"
                          size="is-small"
                          type="is-danger"
                          outlined
                          rounded
                          @click="popCompanyUser(index)"
                        />
                      </div>
                      <div class="is-flex is-flex-direction-column is-align-items-start">
                        <p class="is-flex is-align-items-center">
                          <b-icon
                            type="is-primary"
                            icon="user"
                          />
                          {{ getAuthorName(author) }}
                        </p>
                        <p class="is-flex is-align-items-center">
                          <b-icon
                            type="is-primary"
                            icon="envelope"
                          />
                          {{ getAuthorEmail(author) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <b-message
                    v-if="!authors.length"
                    type="is-warning"
                    has-icon
                    class="mt-3"
                  >
                    <p>Selecciona al menos 1 solicitante</p>
                  </b-message>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-card-foot is-justify-content-space-between">
          <b-button
            rounded
            @click="close"
            type="is-danger"
          >
            Cancelar
          </b-button>
          <b-button
            rounded
            @click="setData"
            type="is-success"
          >
            Aceptar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        isOpen: false,
        vacancy: {},
        companyUsers: [],
        vacancyName: null,
        author: "",
        newAuthor: "",
        showAuthors: [],
        otherSolicitants: false,
        authors: [],
      };
    },
    watch: {
      isOpen: async function (val) {
        if (val) {
          await this.getSolicitants();
        }
      },
    },
    computed: {
      ...mapGetters(["companyId"]),
    },
    methods: {
      open({ _id, name, author, additionalAuthors }) {
        this.vacancy = _id;
        this.vacancyName = name;
        this.author = author;
        this.authors = additionalAuthors;
        this.isOpen = true;
      },
      close() {
        this.vacancy = {};
        this.vacancyName = null;
        this.authors = [];
        this.companyUsers = [];
        this.showAuthors = [];
        this.newAuthor = "";
        this.otherSolicitants = false;
        this.isOpen = false;
      },
      async getSolicitants() {
        this.isLoading = true;
        try {
          const { data: solicitants } = await this.$api.get(`companies/${this.companyId}/solicitants`);
          const { data: hrManagers } = await this.$api.get(`/companies/${this.companyId}/hr-managers`);
          this.companyUsers = [...solicitants, ...hrManagers];
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      },
      async assignAsSolicitants() {
        try {
          await this.$api.post(`/vacancies/${this.vacancy}/assign-additional-authors`, this.authors);
          this.$toast.success({
            message: "Solicitante designado correctamente",
          });
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
      },
      getAuthorName(id) {
        const author = this.companyUsers.find((user) => user.id === id);
        return author?.fullName ? author.fullName : "Sin Asignar";
      },
      getAuthorEmail(id) {
        const author = this.companyUsers.find((user) => user.id === id);
        return author?.email;
      },
      addCompanyUser(id) {
        this.authors.push(id);
      },
      popCompanyUser(index) {
        this.authors.splice(index, 1);
      },
      disabledMultiple(id) {
        if (id === this.newAuthor) return true;
        if (id === this.author && !this.newAuthor) return true;

        return false;
      },
      setAuthor(id) {
        this.newAuthor = id;
      },
      async assignAuthor() {
        try {
          await this.$api.post(`/vacancies/${this.vacancy}/assign-author`, { author: this.newAuthor });
          this.$toast.success({
            message: "Solicitante designado correctamente",
          });
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
      },
      async setData() {
        if (this.newAuthor) await this.assignAuthor();
        if (this.otherSolicitants && this.authors.length > 0) await this.assignAsSolicitants();
        this.$emit("update");
        this.close();
      },
    },
  };
</script>
