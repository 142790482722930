<template>
  <div>
    <div class="wallet" v-if="!sawIndications">
      <div class="filters">
        <!-- Buscar y descargar -->
        <div :class="['superqueen'].includes(user.role) ? 'button-group' : 'not-super'">
          <b-button @click="checkFilters(); skip = 0" rounded icon-right="magnifying-glass" type="is-blue" class="mr-4">Buscar</b-button>
          <b-button
            :disabled="!(this.table.data.length >= 1)"
            v-if="( ['superqueen'].includes(user.role) )"
            rounded
            type="is-blue"
            @click="(saveFile = !saveFile)"
            icon-right="file-download"
            >
            Descargar cartera
          </b-button>
        </div>
        <!-- Filtros -->
        <b-collapse aria-id="filters" >
          <div class="card" style="border-radius: 10px; padding: 1rem 1rem; margin: 2rem 0; color:#fff; background-color:#0f486d">
            <div class="card-content" style="margin-bottom: 1rem; margin-left:2.6rem; ">
              <div  style="" class="columns is-multiline">
                <!-- Termino de materias y nivel academico -->
                <div class="column">
                  <b-field >
                    <template #label>
                      <span class="has-text-white">{{filterFields.semester}}</span>
                    </template>
                    <b-datepicker
                      type="month" icon="calendar-range" v-model="filters.dateSelected"
                      :icon-right="filters.dateSelected ? 'close' : '' "
                      icon-right-clickable
                      @icon-right-click="clearDate"
                      locale="es-MX"></b-datepicker>
                  </b-field>
                  <b-field >
                    <template #label>
                      <span class="has-text-white">{{filterFields.academicLevel}}</span>
                    </template>
                    <div  class="is-flex is-flex-wrap-wrap">
                      <b-checkbox v-model="filters.academicLevel" native-value="student"  class="pt-1 academic-level" type="is-blue">
                        Estudiante
                      </b-checkbox>
                      <b-checkbox v-model="filters.academicLevel" native-value="recently-graduated" class="pt-1 academic-level" type="is-blue">
                        Recién Graduado
                      </b-checkbox>
                      <b-checkbox v-model="filters.academicLevel" native-value="experienced-graduated" class="pt-1 academic-level" type="is-blue">
                        Graduado con Experiencia
                      </b-checkbox>
                    </div>
                  </b-field>
                </div>
                <!-- Carrera y area de estudios -->
                <div class="column">
                  <b-field >
                    <template #label>
                      <span class="has-text-white">{{filterFields.career}}</span>
                     </template>
                    <b-tooltip :label="tooltips.career" style="width: 100%">
                      <b-taginput
                      icon="graduation-cap"
                      v-model="careers"
                      icon-right="close-circle"
                      icon-right-clickable
                      @icon-right-click="careers = []" />
                    </b-tooltip>
                  </b-field>
                  <b-field >
                    <template #label>
                      <span class="has-text-white">{{filterFields.studyArea}}</span>
                     </template>
                    <b-select
                      v-model="filters.studyArea"
                      icon="grid-horizontal">
                      <option value=""></option>
                      <option v-for="area in studyAreas" :key="`area-${area}`" :value="area">{{area}}</option>
                    </b-select>
                  </b-field>
                </div>
                <!-- Estado y ciudad -->
                <div class="column">
                  <b-field>
                    <template #label>
                      <span class="has-text-white">{{filterFields.state}}</span>
                    </template>
                    <b-select icon="map-pin" v-model="stateSelected" expanded v-if="mxLocation">
                      <option value=""></option>
                      <option
                        v-for="(state, index) in states"
                        :key="`state-${index}`"
                        :value="state"
                      >
                        {{ state.name }}
                      </option>
                    </b-select>
                    <b-select icon="map-pin" v-model="stateSelected" expanded v-else>
                      <option value=""></option>
                      <option
                        v-for="(province, index) in provinces"
                        :key="`state-${index}`"
                        :value="province"
                      >
                        {{ province.name }}
                      </option>
                    </b-select>

                  </b-field>
                  <b-field>
                    <template #label>
                      <span class="has-text-white">{{filterFields.city}}</span>
                     </template>
                    <b-select
                      v-if="mxLocation"
                      icon="map-pin"
                      v-model="citySelected"
                      :disabled="stateCities.length === 0"
                      expanded>
                      <option value=""></option>
                      <option
                        v-for="(city, index) in stateCities"
                        :key="`city-${index}`"
                        :value="city"
                      >
                        {{ city.name }}
                      </option>
                    </b-select>
                    <b-select
                      v-else
                      icon="map-pin"
                      v-model="citySelected"
                      :disabled="provinceMunicipallities.length === 0"
                      expanded>
                      <option value=""></option>
                      <option
                        v-for="(municipality, index) in provinceMunicipallities"
                        :key="`municipality-${index}`"
                        :value="municipality"
                      >
                        {{ municipality.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <!-- Nivel de excel e ingles -->
                <div class="column">
                  <b-field>
                    <template #label>
                      <span class="has-text-white">{{filterFields.excelLevel}}</span>
                     </template>
                    <b-select v-model="filters.excelLevel" icon="table" >
                      <option hidden selected>Selecciona un nivel</option>
                      <option value=""></option>
                      <option v-for="level in excelLevels" :key="`nivel-${level}`" :value="level">{{level}}</option>
                    </b-select>
                  </b-field>
                  <b-field >
                    <template #label>
                      <span class="has-text-white">{{filterFields.englishLevel}}</span>
                     </template>
                    <b-select v-model="filters.englishLevel" icon="language" >
                      <option hidden selected>Selecciona un nivel</option>
                      <option value=""></option>
                      <option v-for="level in englishLevels" :key="`nivel-${level}`" :value="level" style="width: 400px">{{level}}</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="is-flex is-justify-content-flex-end" v-if="anyFilter">
              <b-button
                rounded
                type="is-blue"
                @click="cleanFilters"
                icon-right="trash"
                >
                  Borrar filtros
              </b-button>
            </div>
          </div>
          <template #trigger="props" >
            <div :class="props.open ? 'open' : 'closed' " style="border-radius:20px;" >
              <b-button aria-controls="filters" :aria-expanded="props.open"
                :icon-right="props.open ? 'chevron-down' : 'chevron-right' "
                rounded :click="open = props.open" class="show" style="margin-right:0"/>
            </div>
          </template>
        </b-collapse>
      </div>
      <div>
        <div class="buttons has-addons is-flex is-justify-content-space-between has-background-blue box" v-if="lookedUp">
          <!-- Invitar multiples candidatos -->
          <div>
            <b-button @click="multipleCandidates" type="is-blue" icon-left="share-nodes" :disabled="!checkedUsers.length > 0">
              Invitar {{ checkedUsers.length || ''  }}
              candidat@s</b-button>
          </div>
          <!-- Boton de bloque -->
          <div>
            <b-button type="is-blue" @click="less" :disabled="noLess" icon-left="arrow-left-from-line"/>
            <b-button type="is-blue" class="has-text-weight-bold" style="pointer-events: none;" :disabled="noMore && noLess">Bloque</b-button>
            <b-button type="is-blue" @click="more" :disabled="noMore" icon-right="arrow-right-from-line"/>
          </div>
        </div>
        <!-- Tabla -->
        <div v-if="table.data.length > 0">
          <b-table
            :data="table.data"
            :paginated="table.isPaginated"
            :total="table.total"
            :default-sort-direction="table.defaultSortDirection"
            :sort-icon="table.sortIcon"
            :sort-icon-size="table.sortIconSize"

            detailed
            :hoverable="true"

            :per-page="table.perPage"
            :current-page.sync="table.currentPage"
            :pagination-simple="false"
            :pagination-position="'bottom'"
            checkable
            :checked-rows.sync="checkedUsers"
          >

          <!-- Columna de nombre -->
            <b-table-column field="candidate-name" label="Nombre" v-slot="props" sortable >
              <p class="has-text-weight-semibold" @click="(seeDetails = true)">
                {{props.row.profile.firstName}}
                {{props.row.profile.firstSurname}}
                {{props.row.profile.lastSurname}}
              </p>
            </b-table-column>
            <!-- Columna de contacto -->
            <b-table-column field="candidate-contact" label="Contacto" v-slot="props" sortable>
              <p><span style="font-weight: bold">Email:</span> {{ props.row.mail || props.row.profile.email }}</p>
              <p><span style="font-weight: bold">Telefono:</span> {{props.row.profile.phone}}</p>
              <div style="text-align:center">
                <b-tooltip label="Copiar Correo" position="is-bottom" >
                  <b-icon
                    class="iconos"
                    icon="copy"
                    v-clipboard:copy="props.row.profile.email"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
                  </b-icon>
                </b-tooltip>
                <b-tooltip label="Enviar Correo" position="is-bottom">
                  <a class="email" :href="`https://mail.google.com/mail/u/0/?fs=1&to=${props.row.profile.email || props.row.email }&tf=cm`" target="_blank">
                    <b-icon
                      class="iconos"
                      icon="envelope-open-text"
                      >
                    </b-icon>
                  </a>
                </b-tooltip>
                <b-tooltip label="Copiar Teléfono" position="is-bottom" >
                  <b-icon
                    class="iconos"
                    icon="phone"
                    v-clipboard:copy="props.row.profile.phone"
                    v-clipboard:success="onCopyPhone"
                    v-clipboard:error="onError">
                  </b-icon>
                </b-tooltip>
              </div>
            </b-table-column>
            <!-- Columna de Carrera -->
            <b-table-column field="candidate-career" label="Licenciatura" v-slot="props" sortable>
              <p>{{props.row.profile.career}}</p>
            </b-table-column>
            <!-- Columna Termino de materias -->
            <b-table-column field="candidate-degree" label="Termino de Materias" v-slot="props" sortable>
              <p>{{props.row.profile.graduateDate |formatToDate }}</p>
            </b-table-column>
            <!-- Columna de habilidades -->
            <b-table-column field="candidate-habilities" label="Habilidades" v-slot="props" sortable>
              <p><span style="font-weight: bold">Nivel de Inglés:</span> {{props.row.profile.englishLevel}}</p>
              <p><span style="font-weight: bold">Nivel de Excel:</span> {{props.row.profile.excelLevel}}</p>
            </b-table-column>
            <!-- Columna de residencia -->
            <b-table-column field="candidate-residence" label="Residencia" v-slot="props" sortable>
              <p>{{props.row.profile.state}}, {{props.row.profile.city}}</p>
            </b-table-column>
            <b-table-column v-slot="props">
              <div>
                <!-- b-dropdown -->
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <button class="button" slot="trigger">
                    <b-icon icon="ellipsis-stroke"></b-icon>
                  </button>
                  <b-dropdown-item aria-role="lisitem" @click="downloadProfile(props.row.applications[0])" :disabled="!props.row.applications.length > 0">
                    <b-icon size="is-small" icon="file-pdf" />
                    Descargar perfil
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="lisitem" @click="downloadPDF(props.row.profile.resume.url)">
                    <b-icon size="is-small" icon="file-arrow-down" />
                    Descargar CV
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="openPDF(props.row.profile.resume.url)">
                    <b-icon size="is-small" icon="file" />
                    Ver CV
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="invite(props.row)" >
                    <b-icon size="is-small" icon="bolt" />
                    Invitar candidato
                  </b-dropdown-item>
                </b-dropdown>
                <!-- / b-dropdown END -->
              </div>
            </b-table-column>
            <template #detail="props">
              <div class="columns" >
                <!-- Perfil academico -->
                <div class="column is-4">
                  <p class="is-size-4 has-text-weight-semibold">Perfil académico</p><br>
                  <div class="columns">
                    <div class="column">
                      <p ><span class="has-text-weight-semibold">Universidad:</span> {{props.row.profile.university}}</p>
                      <p ><span class="has-text-weight-semibold">Campus:</span> {{props.row.profile.campus || 'Sin definir'}}</p>
                      <p ><span class="has-text-weight-semibold">Nivel académico:</span> {{academicLevel(props.row.profile.academicLevel) || 'Sin definir'}}</p>
                      <p ><span class="has-text-weight-semibold">Area de estudio:</span> {{props.row.profile.studyArea|| 'Sin definir'}}</p>
                      <p ><span class="has-text-weight-semibold">Fecha de titulación:</span> {{props.row.profile.degreeDate | formatToDate}}</p>
                    </div>
                  </div>
                  <div class="columns is-multiline">
                    <div class="column ">
                      <p><span class="has-text-weight-semibold">Lunes</span> <br> {{props.row.profile.mondaysSchedule || 'Sin definir' }} </p><br>
                      <p><span class="has-text-weight-semibold">Jueves</span><br> {{props.row.profile.thursdaysSchedule || 'Sin definir'}} </p>
                    </div>
                    <div class="column">
                      <p><span class="has-text-weight-semibold">Martes</span> <br> {{props.row.profile.tuesdaysSchedule || 'Sin definir'}} </p><br>
                      <p><span class="has-text-weight-semibold">Miercoles</span><br> {{props.row.profile.wednesdaysSchedule || 'Sin definir'}} </p>
                    </div>
                    <div class="column ">
                      <p><span class="has-text-weight-semibold">Viernes </span><br> {{props.row.profile.fridaysSchedule || 'Sin definir'}} </p><br>
                      <p><span class="has-text-weight-semibold">Sabado</span><br> {{props.row.profile.saturdaysSchedule || 'Sin definir'}} </p>
                    </div>
                  </div>
                </div>
                <!-- Intereses y habilidades -->
                <div class="column is-5" >
                  <p class="is-size-4 has-text-weight-semibold">Fortalezas</p>
                  <br>
                  <div class="is-flex is-justify-content-space-evenly">
                    <div style=" width:fit-content" class="">
                      <p class="has-text-weight-semibold">Intereses</p>
                      <ul>
                        <li >
                          {{props.row.profile.interestOne}}
                        </li>
                        <li >
                          {{props.row.profile.interestTwo}}
                        </li>
                        <li >
                          {{props.row.profile.interestThree}}
                        </li>
                      </ul>
                    </div>
                    <div style=" width:fit-content;" class="ml-2">
                      <p  class="has-text-weight-semibold">Habilidades</p>
                      <b-taglist attached v-for="(skill,index) in props.row.profile.skills" :key="`skill -${index}`">
                        <b-tag type="is-blue">{{skill.name}}</b-tag>
                        <b-tag>{{skill.level}}</b-tag>
                      </b-taglist>
                    </div>
                  </div>
                </div>
                <!-- Aplicaciones del usuario -->
                <div class="column is-3">
                  <p class="is-size-4 has-text-weight-semibold">Aplicaciones</p><br>
                  <div class="applications" v-if="props.row.applications.length >= 1">
                    <div class="card mt-4 mr-2 ml-2 mb-4" v-for="(application, index) in props.row.applications"
                      :key="`application-${index}`" >
                      <div class="card-content">
                        <div class="timeline" >
                          <div >
                            <p class="heading">
                            <b>Fecha de aplicacion:</b> {{ application.createdAt | formatDate }}
                            </p>
                            <p><b>Vacante:</b> {{ application.vacancy.name }}</p>
                            <p><b>Empresa:</b> {{ application.company.name }}</p>
                            <b-tag size="is-medium"  class="mt-2" :type="applicationStatus(statuses[application.status])" >
                              {{ statuses[application.status] }}
                            </b-tag>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <b-tag type="is-danger" size="is-medium">No existen aplicaciones</b-tag>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <!-- Modal para guardar nombre -->
      <b-modal
        :active.sync="saveFile"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <div class="modal-card">
          <section class="modal-card-head" style="background-color:#0f486d;">
            <p class="modal-card-title has-text-white">Un paso más...</p>
          </section>
          <section class="modal-card-body is-flex is-justify-content-center" >
            <div class="column is-6">
              <p class="mb-4 is-size-5 ">Ingresa el nombre del archivo</p>
              <b-input v-model="docName" ></b-input>
            </div>
          </section>
          <footer class="modal-card-foot justify-flex-end" style="height:min-content" >
            <b-button type="is-blue" @click="downloadUsers" :disabled="!docName">
              Aceptar
            </b-button>
          </footer>
        </div>
      </b-modal>
    </div>
    <!-- Modal de presentacion -->
    <div v-else class="is-flex is-justify-content-center mt-4 is-size-5">
      <div class="card" style="width: 45rem; height: 37rem; " >
        <header class="card-header">
          <p class="card-header-title is-justify-content-center">Bienvenidx a la cartera de candidatos</p>
        </header>
        <div class="card-content" >
          <b-steps
            v-model="activeStep"
            :animated="true" :rounded="true" :has-navigation="true" type="is-blue"
          >
            <b-step-item step="1">
              <div>
                <b-message type="is-blue" title="¿Qué filtros puedo encontrar?" :closable="false" style="height:22rem">
                  <ul style="list-style-position: inside; list-style-type: disc;" class="pl-2">
                    <li v-for="(field, index) in filterFields" :key="`field-${index}`">
                      {{field}}
                    </li>
                  </ul><br>
                  <p>- Los filtros son opcionales y solo serán aplicados aquellos que hayas seleccionado.</p>
                </b-message>
              </div>
            </b-step-item>
            <b-step-item step="2">
              <div>
                <b-message type="is-blue" :closable="false" title="Acerca del filtro Carrera" style="height:22rem">
                  <p>Lxs candidatxs suelen escribir su carrera de distintas formas:</p><br>
                  <ul style="list-style-position: inside; list-style-type: disc;">
                    <li>
                      Con o sin las palabras Licenciatura o Ingeniería.
                    </li>
                    <li>
                      Abreviaciones como: 'Lic.' o 'Ing.'.
                    </li>
                    <li>
                      Variaciones en mayúculas y minúsculas,
                    </li>
                    <li>
                      Con acentos o sin acentos.
                    </li>
                  </ul><br>
                  <p class="mb-2">Puedes alcanzar más registros escribiendo letras clave como: </p>
                  <b-tag type="is-blue" size="is-medium" class="mr-4">Lic</b-tag>
                  <b-tag type="is-blue" size="is-medium" class="mr-4">Ing</b-tag>
                  <b-tag type="is-blue" size="is-medium" class="mr-4">Admin</b-tag>
                  <b-tag type="is-blue" size="is-medium" class="mr-4">Quim</b-tag> <br>
                  <br><p>P.D. Ya estamos trabajando en esta normalización. :)</p>
                </b-message>
              </div>
            </b-step-item>
            <b-step-item step="3">
              <div >
                <b-message type="is-blue" :closable="false" title="¿Puedo descargar la tabla?" style="height:22rem; overflow-y:scroll">
                  <p>Puedes descargar a lxs candidatxs encontradxs en formato <b>Excel separado por comas</b>. <br> Ten en cuenta que este formato no muestra letras con acentos o algunos símbolos como la "ñ".</p><br>
                  <p>Así que para poder transformar estos caracteres especiales sigue los siguientes pasos: </p><br>
                  <b>
                    <ol style="list-style-position: inside;">
                      <li >
                        <span class="has-text-weight-normal">Abre <b>tu unidad personal</b> de <b>Google Drive</b>.</span>
                      </li>
                      <li>
                        <span class="has-text-weight-normal">Sube el archivo <b>.csv</b>.</span>
                      </li>
                      <li>
                        <span class="has-text-weight-normal">Da doble click en el archivo y este se abrira dentro de tu navegador.</span>
                      </li>
                      <li>
                        <span class="has-text-weight-normal">Encuentra la opción <b>Abrir con</b> en la parte superior.</span>
                      </li>
                      <li>
                        <span class="has-text-weight-normal">Selecciona <b>Google Sheets</b>.</span>
                      </li>
                      <li>
                        <span class="has-text-weight-normal">Se abrirá una nueva pestaña con el documento.</span>
                      </li>
                      <li>
                        Archivo<span class="has-text-weight-normal"> -> </span>Descargar<span class="has-text-weight-normal"> -> </span>Microsoft Excel (.xlsx)
                      </li>
                      <li>
                        <span class="has-text-weight-normal">Listo! Ya puedes leer tu excel con acentor y caracteres especiales.</span> :)
                      </li>
                    </ol>
                  </b>
                </b-message>
              </div>
            </b-step-item>
            <b-step-item step="4">
              <div>
                <b-message type="is-blue" :closable="false" title="Terminaste la introducción" style="height:22rem">
                  <p>Ahora si, estas preparadx para usar este buscador.</p><br>
                  <p>Si quieres omitir esta introduccion en futuras busquedas, da click en "<b>Entendido</b>"</p>
                </b-message>
              </div>
            </b-step-item>
            <template
              #navigation="{previous, next}">
              <div style="position:absolute; padding-top: 2rem; padding-left:1rem">
                <b-button
                  outlined
                  type="is-yellow"
                  icon-left="backward"
                  :disabled="previous.disabled"
                  @click.prevent="previous.action">
              </b-button>
              <b-button class="ml-4"
                type="is-blue"
                icon-right="forward"
                :disabled="next.disabled"
                @click.prevent="next.action">
              </b-button>
              </div>
          </template>
          </b-steps>
        </div>
        <footer class="card-footer is-flex is-justify-content-flex-end p-4">
          <div class="pr-4">
            <b-button type="is-blue" @click="(sawIndications = !sawIndications)">Entendido</b-button>
          </div>
        </footer>
      </div>
    </div>
    <DownloadCandidateProfile :application="downloadProfileId" ref="downloadProfile"/>
    <InviteCandidate :user="userSelected" ref="inviteCandidate" />
    <InviteMultipleCandidates :selectedUsers="multipleCandidatesSelected" ref="inviteMultipleCandidates"/>
    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script >
import axios from 'axios'
import cities from '../lib/cities'
import DownloadCandidateProfile from '@/components/modals/DownloadCandidateProfile'
import InviteCandidate from '../components/modals/invite-candidate/InviteCandidate'
import InviteMultipleCandidates from '../components/modals/invite-multiple-candidates/InviteMultipleCandidates.vue'
import states from '../lib/states'
import provinces from '@/lib/ES/provinces'
import municipalities from '@/lib/ES/municipalities'

  export default{
    components: {
      DownloadCandidateProfile,
      InviteCandidate,
      InviteMultipleCandidates
    },
    data () {
      return{
        states,
        cities,
        provinces,
        municipalities,
        seeDetails: false,
        sawIndications: true,
        saveFile: false,
        activeStep: 0,
        open: true,
        search: '',
        downloadProfileId: null,
        range: [0,1,2,3,4,5,6,7,8,9,10,11,12],
        filters:  { //Formulario de filtros
          studyArea: null,
          dateSelected: null,
          selected: null,
          academicLevel: [],
          excelLevel: '',
          englishLevel: '',
          showCV: false,
          showAplications: false,
        },
        filterFields:{ //Para rellenar label de los fields
          semester: 'Termino de Materias', //ya
          academicLevel: 'Nivel Académico', //ya
          career: 'Carrera',
          studyArea: 'Área de Estudios', //ya
          state: 'Estado',
          city: 'Ciudad',
          excelLevel: 'Nivel de Excel',
          englishLevel: 'Nivel de Inglés',
        },
        academicLevels:[ //Para seleccionar nivel academico
          'student', 'recently-graduated','experienced-graduated'
        ],
        excelLevels:[
          'Básico', 'Intermedio', 'Avanzado',
        ],
        englishLevels:[
          'Básico', 'Intermedio', 'Avanzado',
        ],
        studyAreas:[ //Para seleccionar area de estudio
        'Físico-matemático e Ingenierias',
        'Ciencias biológicas y de la salud',
        'Ciencias sociales',
        'Artes y humanidades',
        'Económico - Administrativos',
        'Otros'
        ],
        table: {
          data: [],
          isPaginated: true,
          isPaginationSimple: false,
          isPaginationRounded: true,
          paginationPosition: 'bottom',
          defaultSortDirection: 'asc',
          sortIcon: 'arrow-up',
          sortIconSize: 'is-small',
          currentPage: 1,
          perPage: 20,
          total: 0
        },
        tooltips:{
          career: 'Presiona enter para añadir mas carreras'
        },
        downloadParams: [],
        citySelected: null,
        stateSelected: null,
        docName: null,
        careers: [],
        checkCareer: true,
        timeline: null,
        statuses: {
          applying: 'En aplicación',
          selected: 'Seleccionad@',
          sent: 'En revisión con la empresa',
          rejected: 'Rechazad@',
          'pre-hired': 'Seleccionad@ por la empresa',
          hired: 'Contratad@'
        },
        link: null,
        levels: [
          { 'student' : 'Estudiante' },
          { 'recently-graduated' : 'Recién graduado'},
          { 'experienced-graduated' : 'Graduado con experiencia' }
        ],
        skip: 0,
        totals: 0,
        noMore: true,
        userSelected: null,
        checkedUsers: [],
        multipleCandidatesSelected: [],
        isLoading: false,
        lookedUp: false
      }
    },
    methods: {
      async getWallet (params) {
        this.isLoading = true
        this.table.data = []
        this.noMore = false
        try{
          const { data }  = await this.$api.get(`wallet/diferential?${params}&role=candidate&deleted=false`)
          if(data.filtered.length < 1 ) {
            this.$toast.warning({
            message: 'No se encuentran candidatos con los filtros proporcionados, intenta en el siguiente bloque.',
          })

          }          
          this.table.data  =  data.filtered
          this.totals = data.totals
          this.table.total = data.filtered.length
          this.lookedUp = true
        }catch (error) {
          this.cleanVariables()
          this.$toast.error({
            message: error
          })
        }
        this.isLoading = false
      },
      async checkFilters () {
          let params = [
            `skip=${this.skip}`,
          ]
          if(this.filters.studyArea){ params.push(`profile.studyArea=${this.filters.studyArea}`) }
          if(this.filters.excelLevel){ params.push(`profile.excelLevel=${this.filters.excelLevel}`) }
          if(this.filters.englishLevel){ params.push(`profile.englishLevel=${this.filters.englishLevel}`) }
          if(this.filters.academicLevel){ params.push(`profile.academicLevel=${this.filters.academicLevel.join(',')}`) }
          if(this.filters.dateSelected){ params.push(`graduateDate=${this.filters.dateSelected}`)}
          if(this.careers.length > 0){  params.push(`career=${this.careers}`)}else{ params.push(`career=`)}
          if(this.stateSelected){
            if(this.stateSelected.name === 'Todo México' || this.stateSelected.name === 'Todas las provincias'){
              params.push(`profile.state=`)
            }else{
              params.push(`profile.state=${this.stateSelected.name}`)
            }
          }
          if(this.citySelected){
            if(this.citySelected.name === 'Todo México'){
              params.push(`profile.city=`)
            }else{
              params.push(`profile.city=${this.citySelected.name}`)
            }
          }
          params = params.join('&')
          this.downloadParams = params
          await this.getWallet(params)
      },
      clearDate () {
        this.filters.dateSelected = null
      },
      onCopy () {
      this.$toast.success({
        message: 'Correo Copiado',
      })
      },
      onError (e) { + e.text
        this.$toast.error({
          message: 'No se copio el correo ' + e.text,
          }
        )
      },
      onCopyPhone () {
        this.$toast.success({
        message: 'Teléfono Copiado',
        })
      },
      replaceAccent (term) {
        if (term) {
          const accents = /á|é|í|ó|ú/
          const word = term.toLowerCase()
          return word.replace(accents, (s) => {
            if (s === 'á') return 'a'
            if (s === 'é') return 'e'
            if (s === 'í') return 'i'
            if (s === 'ó') return 'o'
            if (s === 'ú') return 'u'
          })
        }
        return term
      },
      downloadProfile (id) {
        this.downloadProfileId = id
        this.$refs.downloadProfile.open()
      },
      invite(row){
        this.userSelected = {
          recruiter: this.user,
          candidate: row
        }
        this.$refs.inviteCandidate.open()
      },
      multipleCandidates(){
        if(this.checkedUsers.length > 0){
          this.multipleCandidatesSelected = []
          this.checkedUsers.forEach( user => this.multipleCandidatesSelected.push({
            id: user.id,
            profile: user.profile
          }))
          this.$refs.inviteMultipleCandidates.open()
        }
        else{
          this.$toast.warning({
            message: 'Selecciona almenos un candidato',
          })
        }
      },
      async downloadPDF (url) {
        delete axios.defaults.headers.common["Authorization"]

        const fileName = url.split('/').pop()

        await axios.get(url,{ responseType: 'blob' })
        .then( response => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let html = document.createElement('a') //<a href="""></a>
          html.href = URL.createObjectURL(blob)
          html.download = fileName
          html.click()
          URL.revokeObjectURL(html.href)
          this.$toast.success({
            message: 'Descarga exitosa',
          })
        })
        .catch( error => {
          this.$toast.error({
            message: error,
          })
        })
      },
      openPDF (link) {
        const url = this.$router.resolve({ name: 'PDFViewer', query: { empty: true, link: link } })
        window.open(url.href)
        return false
      },
      applicationStatus(status){
        if(status === 'En aplicación') return 'is-blue'
        if(status === 'Seleccionad@') return 'is-link'
        if(status === 'En revisión con la empresa') return 'is-info'
        if(status === 'Contratad@') return 'is-success'
        if(status === 'Rechazad@') return 'is-danger'
      },
      cleanFilters(){
        this.filters.studyArea = ''
        this.filters.excelLevel = ''
        this.filters.englishLevel = ''
        this.filters.academicLevel = []
        this.filters.dateSelected = null
        this.careers = []
        this.stateSelected = null
        this.citySelected = null
      },
      academicLevel(level){
        if(level[0] === 'student') return 'Estudiante'
        if(level[0] === 'recently-graduated' ) return 'Recién graduado'
        if(level[0] === 'experienced-graduated') return 'Graduado con experiencia'
      },
      less () {
        if((this.skip-200)>=0){
          this.noMore = false
          this.skip -=  200
          this.checkFilters()
        }
      },
      more () {
        this.skip += 200
        this.checkFilters()
      },
      cleanVariables(){
        this.table.data  =  []
        this.totals = 0
        this.table.total = 0
      },
      async downloadUsers () {
      try {
        const response = await this.$api.get(`/wallet/diferential-export`, { responseType: 'blob' });
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        //Flujo de descarga del archivo
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${this.docName}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        this.$toast.error({ message: 'Error al descargar el archivo' });
      } finally {
        // Cerrar el modal
        this.saveFile = false;
      }
    },
    },
    computed: {
      token () {
        return this.$store.state.session.token
      },
      user () {
        return this.$store.state.session.user
      },
      stateCities () {
        const cities = this.stateSelected
          ? this.cities
            .filter(city => city.state_id === this.stateSelected.id)
            .sort((a, b) => {
              const nameA = this.replaceAccent(a.name)
              const nameB = this.replaceAccent(b.name)
              if (nameA > nameB) {
                return 1
              }
              if (nameA < nameB) {
                return -1
              }
              return 0
            })
          : []
        return cities
      },
      provinceMunicipallities (){
        const provinces = this.stateSelected
          ? this.municipalities.filter(item => item.state_id == this.stateSelected.id)
              .sort((a, b) => {
                const nameA = this.replaceAccent(a.name);
                const nameB = this.replaceAccent(b.name);
                if (nameA > nameB) {
                  return 1;
                }
                if (nameA < nameB) {
                  return -1;
                }
                return 0;
              })
          : [];
        return provinces;
      },
      anyFilter(){
        if(this.filters.studyArea) return true
        if(this.filters.excelLevel) return true
        if(this.filters.englishLevel) return true
        if(this.filters.academicLevel.length > 0)return true
        if(this.filters.dateSelected) return true
        if(this.careers.length > 0)return true
        if(this.stateSelected) return true
        if(this.citySelected) return true
        return false
      },
      noLess(){
        if(this.skip === 0){
          return true
        }
        return false
      },
      mxLocation(){
        return process.env.VUE_APP_LOCATION === 'mx'
      }
    },
    watch: {
      stateSelected () {
        this.citySelected = null
      },
      careers(newValue){
        let last = newValue.length - 1
        if(newValue[last] && newValue[last].length < 3){
          this.$toast.error({
          message: 'La carrera tiene que ser mayor a 3 letras',
          })
          newValue.pop()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../src/sass/variables';
  .wallet{
    display: flex;
    flex-direction: column;
  }
  .closed{
    margin-bottom: 2rem;
  }
  .show{
    background-color: #fff;
    color: #999;
    margin-right: 1rem;
  }
  .button-group{
    position: absolute;
    margin-left: 64%;
  }
  .not-super{
    position: absolute;
    margin-left: 89%;
  }
  .applications{
    height: 17rem;
    overflow-y: scroll;
  }
  .applications::-webkit-scrollbar{
    width: 0.7rem;
  }
  .applications::-webkit-scrollbar-track{
    background-color: transparent;
  }
  .applications::-webkit-scrollbar-track-piece:start{
    margin-top: 1rem;
  }
  .applications::-webkit-scrollbar-track-piece:end{
    margin-bottom: 1rem;
  }
  .applications::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }
  .link:hover{
    color: #4dc0b5;
  }
  @media screen and (max-width: 1279px) {
    .not-super{
      position: absolute;
      margin-left: 87%;
    }
  }
  @media screen and (min-width: 1280px) {
    .button-group{
      position: absolute;
      margin-left: 70%;
    }
    .not-super{
      position: absolute;
      margin-left: 88%;
    }
  }
  @media screen and (min-width: 1366px) {
    .button-group{
      position: absolute;
      margin-left: 70%;
    }
    .not-super{
      position: absolute;
      margin-left: 88%;
    }
  }
  @media screen and (min-width: 1420px) {
    .button-group{
      position: absolute;
      margin-left: 74.5%;
    }
    .not-super{
      position: absolute;
      margin-left: 89%;
    }
  }
  @media screen and (min-width: 1880px) {
    .button-group{
      position: absolute;
      margin-left: 74.5%;
    }
    .not-super{
      position: absolute;
      margin-left: 89%;
    }
  }
  .iconos {
    color: teal;
    margin-right: 5px;
  }
  .iconos:hover{
    color:#4dc0b5;
  }
  .email{
    color: teal;
  }
  .academic-level:hover{
    color:white
  }
</style>
