import httpApi from '@/axios'
import Vue from 'vue'

export default {
  getTotals: async function () {
    try {
      const response = await httpApi.get('/totals')
      return response
    } catch (error) {
      Vue.$toast.error({
        message: error,
      })
      return null
    }
  }
}