import { render, staticRenderFns } from "./VacancyCandidatesSent.vue?vue&type=template&id=6c045394&scoped=true"
import script from "./VacancyCandidatesSent.vue?vue&type=script&lang=js"
export * from "./VacancyCandidatesSent.vue?vue&type=script&lang=js"
import style0 from "./VacancyCandidatesSent.vue?vue&type=style&index=0&id=6c045394&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c045394",
  null
  
)

export default component.exports