
<template>
  <div>

    <b-modal
      :active.sync="isOpen"
      :destroy-on-hide="true"
      @cancel="close"
      aria-modal
      aria-role="dialog"
      has-modal-card
      trap-focus>

      <div 
        class="modal-card" style="width: 100%;">

        <div 
          class="modal-card-head">

          <div 
            class="modal-card-title">

            Asignar especialista a "{{ vacancyName }}"

          </div>

        </div>

        <div 
          class="modal-card-body" style="overflow-y:hidden;">

          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <p><b>Reclutador principal:</b> {{ getRecruiterName(headRecruiter) }}</p>
            <p>
              <b class="mr-3">¿Solicitantes adicionales?</b>
              <b-switch v-model="otherRecruiters">{{ otherRecruiters ? 'Si agregar' : 'No agregar' }}</b-switch>
            </p> 
          </div>

          <hr>

          <p>
            <b>Nuevo reclutador principal: </b> {{ getRecruiterName(newRecruiter) }}
          </p>

          <hr>

          <div class="columns is-multiline" v-if="!otherRecruiters">

            <div class="column is-12">
              <b>Reclutadores disponibles: {{ users.length }}</b>
            </div>

            <div class="column is-12">

              <div class="columns is-multiline" style="max-height: 20rem; overflow-y: scroll;">
                <div class="column is-6 " 
                  v-for="( user, index ) in users" 
                  :key="`author-${index}`" >

                  <div class="card mt-1">
                    <div class="card-content">
                      <div class="columns is-multiline">
                        <div class="column is-4 is-flex is-align-items-center is-justify-content-center">
                          <figure 
                            class="image" 
                            style="max-width: 70px">
                            <img
                              class="is-rounded"
                              :src="user.photo?.url || 'https://via.placeholder.com/150/000000/FFFFFF/?text=Pro Meritum'"
                            >
                          </figure>
                        </div>
                        <div class="column is-8 is-flex is-align-items-center is-justify-content-center">
                            {{ user.fullName }}
                        </div>
                        <div class="column is-12 has-text-centered">
                          {{ user.email }}
                        </div>
                        <div class="column is-12 is-flex is-align-items-center is-justify-content-center">
                          <b-button
                            type="is-primary"
                            rounded
                            @click="setRecruiter(user.id)"
                            icon-right="hand-pointer"
                            :disabled="headRecruiter === user.id"
                          >
                            Asignar
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <div class="columns is-multiline mt-1" v-else>
            <div class="column is-6">
              <b>Reclutadores disponibles</b>
            </div>
            <div class="column is-6">
              <b>Reclutadores asignados</b>
            </div>
            <div class="column is-6" style="max-height: 18rem; overflow-y: scroll;">
              <div 
                v-for="( user, index ) in users" 
                :key="`user-${index}`" 
                class="card mb-2">

                <div class="card-content">

                  <div class="columns is-multiline" style="overflow-x: hidden;">

                    <div class="column is-2 pr-0">

                      <figure 
                        class="image" 
                        style="max-width: 70px">

                        <img
                          class="is-rounded"
                          :src="(user.photo || {}).url || 'https://via.placeholder.com/150/000000/FFFFFF/?text=Pro Meritum'"
                        >

                      </figure>

                    </div>

                    <div class="column is-10 is-flex is-flex-direction-column is-align-items-start">
                    
                        {{ user.fullName }}
                    </div>

                    <div 
                      class="column is-12 is-flex is-align-items-center is-justify-content-center">

                      <b-button
                        type="is-primary"
                        rounded
                        icon-right="hand-pointer"
                        :disabled="asignedRecruiters.includes(user.id) || disableMultiple(user.id)"
                        @click="addRecruiter(user.id)">
                          Asignar
                      </b-button>

                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div class="column is-6" style="max-height: 18rem; overflow-y: scroll;">
              <div class="card mb-2"
                v-for="(recruiter, index) in asignedRecruiters" :key="`recruiter-${index}`"
              >
                <div class="card-content pt-1 pr-1">
                  <div class="is-flex is-justify-content-end">
                    <b-button @click="popRecruiter(index)" icon-right="xmark" size="is-small" rounded outlined type="is-danger" />
                  </div>
                  <p>
                    <b-icon icon="user" type="is-primary"/>
                    {{ getRecruiterName(recruiter) }}
                  </p>
                </div>
              </div>
              <b-message v-if="!this.asignedRecruiters.length" type="is-info" has-icon>
                Sin especialistas adicionales asignados
              </b-message>
            </div>
          </div>
        </div>

        <div 
          class="modal-card-foot is-justify-content-space-between">

          <b-button 
            rounded @click="close" 
            type="is-danger">
            Cancelar
          </b-button>
          <div>
            <b-button 
              rounded @click="getRecommendation" 
              type="is-primary is-light" 
              outlined
              icon-left="fa-user-magnifying-glass"
              class="mr-3"
              >
              Obtener recomendación
            </b-button>
            <b-button @click="setData" icon-right="floppy-disk" type="is-success" outlined rounded>Guardar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isOpen: false,
      vacancy: {},
      users: [],
      recommendationParams: null,
      vacancyName: null,
      headRecruiter: null,
      asignedRecruiters: [],
      otherRecruiters: false,
      newRecruiter: ''
    }
  },
  watch: {
    isOpen: async function ( val ) {
      if ( val ) {
        await this.getRecruiters()
      }
    },
    newRecruiter: function ( val ){
      if(val){
        this.asignedRecruiters = this.asignedRecruiters.filter(item => item !== val)
      }
    }
  },
  computed: {
    ...mapGetters( ['companyId'] )
  },
  methods: {
    open ( vacancy, params ) {
      this.vacancy = vacancy.id
      this.recommendationParams = params
      this.vacancyName = vacancy.name
      this.headRecruiter = vacancy.recruiter
      this.asignedRecruiters = vacancy.additionalRecruiters
      this.isOpen = true
    },
    close () {
      this.vacancy = null
      this.vacancyName = null
      this.users = []
      this.headRecruiter = null
      this.asignedRecruiters = []
      this.otherRecruiters = false
      this.newRecruiter = ''
      this.isOpen = false
    },
    async getRecruiters () {
      this.isLoading = true
      try {
        const { data } = await this.$api.get( `/users/all-recruiters` )
        this.users = data
      } catch ( error ) {
        this.$toast.error({
          message: error,
        });
      }
      this.isLoading = false
    },
    async assignRecruiter(){
      try {
        await this.$api.post( `/vacancies/${this.vacancy}/assign-recruiter`, { recruiter: this.newRecruiter } )
        this.$toast.success({
          message: "Especialista asignado correctamente",
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
    },
    async assignAsRecruiter () {
      try {
        await this.$api.post( `/vacancies/${this.vacancy}/assign-additional-recruiters`, this.asignedRecruiters ) 
        this.$toast.success({
          message: "Especialista asignado correctamente",
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
    },
    async getRecommendation (){
      if(this.recommendationParams){
        try{
          const { data } = await this.$api.get(`/users/recruiters-recommendation?${this.recommendationParams}`)
          this.users = data
        }
        catch(error){
          this.$toast.error({
            message: error,
          });
        }
      }
    },
    getRecruiterName(id){
      if(this.isOpen){
        const recruiter = this.users.find( user => user.id === id)
        return recruiter?.fullName
      }
      return ''
    },
    addRecruiter(id){
      this.asignedRecruiters.push(id)
    },
    popRecruiter(index){
      this.asignedRecruiters.splice(index,1)
    },
    setRecruiter(id){
      this.newRecruiter = id
    },
    disableMultiple(id){
      if(id === this.newRecruiter) return true
      if(id === this.headRecruiter && !this.newRecruiter) return true

      return false
    },
    async setData(){
      if(this.newRecruiter) await this.assignRecruiter()
      if(this.otherRecruiters && this.asignedRecruiters.length > 0) await this.assignAsRecruiter()
      this.close()
      this.$emit('update')
    }
  }
}
</script>