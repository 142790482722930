import Vue from 'vue'
import App from '@/App.vue'

import Buefy from 'buefy'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import { far } from '@fortawesome/pro-regular-svg-icons';
library.add(far)

import VueClipboard from 'vue-clipboard2'
import CheckRole from '@/utils/roles'
import api from '@/utils/api'
import moment from 'moment'
import Pusher from '@/utils/pusher'

import store from '@/store'
import router from '@/routes'
import { Kalendar } from 'kalendar-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

import './vee-validate'

import PortalVue from 'portal-vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

import i18n from "./plugins/i18n.js";
import './plugins/notification-toast.js'

Vue.component('f-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('KalendarComponent', Kalendar)
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.config.productionTip = false

Vue.use(api)
Vue.use(Buefy, {
  defaultIconComponent: 'f-icon',
  defaultIconPack: 'far'
})
Vue.use(CheckRole)
Vue.use(PortalVue)
Vue.use(Pusher)
Vue.use(VueClipboard)

Vue.filter('capitalize', function (value) {
  return !value ? '' : value.toString().charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('initials', function (value) {
  return !value
    ? ''
    : value.toString().split(' ').map(word => word[0]).join('').toUpperCase()
})

Vue.filter('ago', function (val) {
  if (val) {
    const date = moment(val).locale('es')
    return date.fromNow()
  }
  return ''
})

Vue.filter('toFix', function (val, num) {
  if (typeof val === 'number') {
    return val.toFixed(num || 2)
  }
  return 0
})

Vue.filter('formatToHours', (value) => {
  const date = moment(value)
  return date.isValid() ? date.locale('es').format('DD-MMM-YYYY hh:mm') : 'Sin fecha'
})

Vue.filter('formatToHours', (value) => {
  const date = moment(value)
  return date.isValid() ? date.locale('es').format('hh:mm a') : 'Sin horas'
})

Vue.filter('formatToDate', (value = null) => {
  const date = moment(value)
  return date.isValid() ? date.locale('es').format('DD-MMMM-YYYY') : 'Sin fecha'
})

Vue.filter('formatDate', (value) => {
  return moment(value).locale('es').format('DD-MMM-YYYY')
})

Vue.filter('year', (value) => {
  return moment(value).format('YYYY')
})

Vue.filter('formatToDateMonth', (value) => {
  const date = moment(value)
  const monthDate = date.isValid() ? date.locale('es').format('MMMM-YYYY') : 'Sin fecha'
  return monthDate.replace(monthDate[0], monthDate[0].toUpperCase())
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
