<template >
    <b-modal
      :active.sync="isOpen"
      trap-focus
      :can-cancel="false"
      :destroy-on-hideCompanies="false"
      aria-role="dialog"
      aria-modal
      width="80vw"
    >
      <div class="invite-container has-background-light" >
        <div :class="vacancies || !defaultCompany ? 'is-flex is-justify-content-space-between pr-4 pt-4 pb-2 pl-2' : 'is-flex is-justify-content-flex-end pr-4 pt-4 pb-2 pl-2'">
          <div class="is-flex justify-content-start">
            <!-- Volver atrás -->
            <b-button
              v-if="vacancies && !defaultCompany"
              @click="returnToMain" icon-right="arrow-left" type="is-blue" outlined
              >
            </b-button>

            <!-- Company logo -->
            <span
              v-if="defaultCompany?.logo"
              class="companyLogo"
              :style="`background-color:${companyColor}`"
              >
              <img :src="defaultCompany.logo?.url" :alt="`Logo de ${defaultCompany.logo?.name}`">
            </span>
            <span
              v-else-if="defaultCompany?.name"
              :style="`color: ${companyColor}; boder-color: ${companyColor}`"
              class="companyName"
            >
              {{ defaultCompany.name }}
            </span>

            <!-- Título -->
            <p class="is-size-4 has-text-weight-semibold ml-3">
              {{ vacancies ? 'Vacantes disponibles para invitar a candidato': 'Empresas disponibles para invitar a candidato' }}
            </p>
          </div>

          <!-- Cerrar modal -->
          <b-button @click="close"  icon-right="xmark" type="is-blue"
             outlined>
          </b-button>
        </div>

        <!-- Visualizador de empresas y vacantes -->
        <div class="main scrolleable" >

          <!-- Seleccionable de letras -->
          <LetterSelector
            v-if="showCompanies && !defaultCompany"
            :save-companies="saveCompanies"
            @letterSelected="filterCompanies"
            @lettersReseted="resetCompanies"
          />

          <!-- Scroleable de las empresas del reclutador -->
          <CompaniesContainer
            v-if="showCompanies && !defaultCompany"
            :companies="companies"
            @companySelected="getCompanyVacancies"
          />

          <!-- Botón de mostrar u ocultar empresas -->
          <b-icon size="is-medium"
            v-if="!defaultCompany && vacancies"
            :icon="showCompanies ? 'angle-left' : 'angle-right' "
            class="has-text-blue hideButton"
            @click.native="hideCompanies"
          />

          <!-- Scroleable de las vacantes de la empresa selccionada -->
          <transition name="slide-fade">
            <VacanciesContainer
              v-if="vacancies"
              :vacancies="vacancies"
              :company-color="companyColor"
              :user="user"
              @vacancyShared="share"
            />
          </transition>

          <!-- Datos usuario -->
          <transition name="up-fade">
            <CandidateInfo
              v-if="vacancies"
              :user="user"
              :company-color="companyColor"
            />
          </transition>
        </div>
      </div>
      <b-loading :active.sync="isLoading" />

      <AskCustomEvaluation ref="askCustomEvaluation" @inviteCandidate="inviteCandidate" />
    </b-modal>
</template>

<script>
import CandidateInfo from './CandidateInfo.vue'
import LetterSelector from './LetterSelector.vue'
import CompaniesContainer from './CompaniesContainer.vue'
import VacanciesContainer from './VacanciesContainer.vue'
import AskCustomEvaluation from '../AskCustomEvaluation.vue'

export default {
  props:{
    user: {
      default: null,
      required: true
    },
    companyId: {
      default: null,
      required: false
    },
    vacancyId: {
      default: null,
      required: false
    }
  },
  components: {
    CandidateInfo,
    LetterSelector,
    CompaniesContainer,
    VacanciesContainer,
    AskCustomEvaluation
  },
  data(){
    return{
      isOpen: false,
      companies: null,
      vacancies: null,
      sharedVacancy: null,
      defaultCompany: null,
      companyColor: null,
      saveCompanies: [],
      showCompanies: true,
      detailed : false,
      isLoading: false
    }
  },
  watch:{
    async user() {
      if (this.companyId && this.isSpain){
        await this.getCompany()
        await this.getCompanyVacancies(this.defaultCompany)
      } else {
        await this.getCompanies()
      }
    }
  },
  computed:{
    abecedary(){
      return ['A','B','C','D','E','F','G','H','I','J','K',
        'L','M','N','Ñ','O','P','Q','R','S','T','U','V','W','X','Y','Z',]
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION  === 'es'
    },
  },
  methods: {
    open(){
      this.isOpen = true
    },
    close(){
      this.isOpen = false,
      this.companies = null,
      this.vacancies = null,
      this.defaultCompany = null,
      this.companyColor = null,
      this.saveCompanies = [],
      this.showCompanies = true,
      this.detailed = false,
      this.isLoading = false
    },
    async getCompany(){
      this.isLoading = true
      try{
        const { data } = await this.$api.get(`companies/${this.companyId}`)
        this.defaultCompany = data
      }catch(error){
        const errorMessage = error.response.data.message || error
        this.$toast.error({
          message: 'Error al obtener la empresa: ' + errorMessage,
        });
      }
      this.isLoading = false
    },
    async getCompanies(){
      this.isLoading = true
      const { recruiter } = this.user
      try{
        const { data } = await this.$api.get(`wallet/companies/${recruiter.id}`)
        this.companies = data;
        this.orderCompanies()
        this.saveCompanies = this.companies
      }catch(error){
        this.$toast.error({
          message: "El reclutador no tiene empresas asignadas",
        });
      }
      this.isLoading = false
    },
    async getCompanyVacancies(company){
      this.isLoading = true
      const id = company._id || company.id
      const { recruiter } = this.user
      try{
        const { data } = await this.$api.get(`wallet/vacancies/${id}?recruiter=${recruiter.id}`)
        this.vacancies = data.filter( vacancy => vacancy._id !== this.vacancyId)
        this.companyColor = company.mainColor
        this.showCompanies = false
      }catch(error){
        const errorMessage = error.response.data.message || error
        this.$toast.warning({
          message: 'Error al obtener vacantes: ' + errorMessage,
        });
      }
      this.isLoading = false
    },
    share(vacancy){
      this.sharedVacancy = vacancy

      if (!this.companyId) {
        this.inviteCandidate(false) // Ask for keeping just in vacancy candidates screens
      } else {
        this.$refs.askCustomEvaluation.open()
      }
    },
    async inviteCandidate(sharingProfile){
      const payload = {
        candidate: this.user.candidate,
        recruiter: this.user.recruiter,
        vacancy: this.sharedVacancy,
        sharingProfile
      }

      try{
        await this.$api.post('wallet/share', payload)
        this.$toast.success({
          message: "Invitación enviada correctamente",
        });
      }catch(error){
        this.$toast.error({
          message: error,
        });
      }
    },
    orderCompanies(){
      const companies = this.companies.map( company => company.company)
      companies.sort( (a,b) => {
        let textA = a.name.toUpperCase()
        let textB = b.name.toUpperCase()
        return (textA<textB) ? -1 : (textA>textB) ? 1 : 0
      })
      this.companies = companies
    },
    filterCompanies(letter){
      if(!this.saveCompanies.length > 0) this.saveCompanies = this.companies
      this.companies = this.saveCompanies
      console.log(this.companies);
      this.companies = this.companies.filter( company => company.name.charAt(0) === letter)
    },
    resetCompanies(){
      this.companies = this.saveCompanies
    },
    returnToMain(){
      this.vacancies = null
      this.showCompanies = true
    },
    hideCompanies(){
      this.showCompanies = !this.showCompanies
    }
  }
}
</script>
<style  lang="scss"  scoped>
  .invite-container{
    height: 85vh;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
  }
  .main{
    height: calc(85vh - 130px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 0;
    padding: 0.5rem;
    gap: 1rem;
  }
  .hideButton {
    align-self: flex-start;
    cursor: pointer;
    margin-left: 0.5rem;
  }
  .companyLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    border-radius: 7px;
    margin-left: 0.5rem;
    width: 70px;
    max-height: 40px;
  }
  .companyName{
    margin-top: 0.2rem;
    border-width: 1px;
    border-style: solid;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }
  .scrolleable{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .scrolleable::-webkit-scrollbar{
    width: 0.85rem;
  }
  .scrolleable::-webkit-scrollbar-track{
    background-color: transparent;
  }
  .scrolleable::-webkit-scrollbar-track-piece:start{
    margin-top: 1rem;
  }
  .scrolleable::-webkit-scrollbar-track-piece:end{
    margin-bottom: 1rem;
  }
  .scrolleable::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
  }
  .scrolleable::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .8s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to{
    transform: translateX(400px);
    opacity: 0;
  }
  .up-fade-enter-active,  .up-fade-leave-active {
    transition: all 0.8s ease;
    transition-delay: 0.4s;
  }
  .up-fade-enter, .up-fade-leave-to{
    transform: translateY(400px);
    opacity: 0;
  }
</style>
