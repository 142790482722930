<template>
  <div
    class="hero is-fullheight justify-center"
    style="
      background: #0f486d;
      background: linear-gradient(339deg, #0f486d 0%, rgba(66,203,182,1) 100%);
    "
  >
    <div class="columns is-centered">
      <div class="column is-3">
        <div>
          <img style="width: 50%;" src="@/assets/logo-white.png" alt="ProMeritum">
        </div>
        <div class="card">
          <div class="card-content">
            <p>
              Una disculpa por las molestias, estamos dando mantenimiento, pronto estaremos de vuelta.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
}

</script>

<style>

</style>