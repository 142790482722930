import PDFViewer from '@/screens/PDFViewer'

const pdf = [
  {
    path: '/ver-pdf',
    name: 'PDFViewer',
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager', 'hrmanager', 'solicitant']
    },
    component: PDFViewer
  }
]

export default pdf