<template>
  <div>
    <b-field label="Correo electrónico">
      <b-input
        type="email"
        v-model="form.email"
      />
    </b-field>
    <div class="columns is-multiline">
      <div class="column is-12 has-text-right">
        <b-button
          icon-left="arrow-left"
          type="is-light"
          @click="goBack"
        ></b-button>
        <b-button
          type="is-primary"
          @click="login"
        >
          Enviar correo
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          email: null,
        },
      };
    },
    watch: {
      "form.email": function (val) {
        if (val) this.form.email = this.form.email.toLowerCase();
      },
    },
    methods: {
      async login() {
        try {
          await this.$store.dispatch('loginByMail', this.form);
          this.goBack();
        } finally {
          this.form.email = null;
        }
      },
      goBack() {
        this.$emit("go-back");
      },
    },
  };
</script>
