import Vue from 'vue';
import VueToast from 'vue-toast-notification';
// Importar el tema deseado
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast, {
  position: 'top',
  duration: 3000,
  dismissible: true,
  pauseOnHover: true,
});

// Guardar la función original para reutilizarla
const prevOpenFunction = Vue.$toast.open.bind(Vue.$toast);
const prevSuccessFunction = Vue.$toast.success.bind(Vue.$toast);
const prevErrorFunction = Vue.$toast.error.bind(Vue.$toast);
const prevInfoFunction = Vue.$toast.info.bind(Vue.$toast);
const prevWarningFunction = Vue.$toast.warning.bind(Vue.$toast);
const prevDefaultFunction = Vue.$toast.default.bind(Vue.$toast);

function getErrorMessage(message) {
  if (typeof message !== 'string') {
    const convertedMessage = String(message.response?.data?.message || message.response?.data || message.data?.message || message.response || message.data || message);
    return convertedMessage
  }

  return message;
}

// New toast functions function with message customization
function newOpen(params) {
  const { message } = params;
  params.message = getErrorMessage(message);
  return prevOpenFunction(params);
}

function newSuccess(params) {
  const { message } = params;
  params.message = getErrorMessage(message);
  return prevSuccessFunction(params.message, params);
}

function newError(params) {
  const { message } = params;
  params.message = getErrorMessage(message);
  return prevErrorFunction(params.message, params);
}

function newInfo(params) {
  const { message } = params;
  params.message = getErrorMessage(message);
  return prevInfoFunction(params.message, params);
}

function newWarning(params) {
  const { message } = params;
  params.message = getErrorMessage(message);
  return prevWarningFunction(params.message, params);
}

function newDefault(params) {
  const { message } = params;
  params.message = getErrorMessage(message);
  return prevDefaultFunction(params.message, params);
}
// Override the originals functions
Vue.$toast.open = newOpen;
Vue.$toast.error = newError;
Vue.$toast.success = newSuccess;
Vue.$toast.info = newInfo;
Vue.$toast.warning = newWarning;
Vue.$toast.default = newDefault;