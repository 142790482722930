<template>
  <div class="container">
    <div class="is-flex justify-flex-end align-flex-end mb-2">
      <section class="is-flex is-justify-content-flex-end align-flex-end">
        <div class="mr-12">
          <b-button rounded type="is-primary" :disabled="!checkedCandidates.length || !$hasPermissionsByRole(vacancyManagementAllowedRoles)" 
          @click="returnRejectedApplications">
            Regresar a En aplicación
          </b-button>
        </div>

        <b-field class="ml-10 mb-0 mr-2 mt-5">
          <b-input v-model="nameToSearch" placeholder="Buscar por nombre..." icon="magnifying-glass" />
        </b-field>
      </section>
    </div>
    <div>
      <b-table
        class="has-shadow"
        :columns="table.columns"
        :data="table.filteredData"
        :paginated="table.isPaginated"
        :per-page="table.perPage"
        :page-input="table.hasInput"
        :current-page.sync="table.currentPage"
        :pagination-simple="table.isPaginationSimple"
        :pagination-position="table.paginationPosition"
        :default-sort-direction="table.defaultSortDirection"
        :sort-icon="table.sortIcon"
        :sort-icon-size="table.sortIconSize"
        :checked-rows.sync="checkedCandidates"
        :checkable="['superqueen', 'recruiter-manager', 'recruiter'].includes(this.$isRole(this.user))"
        default-sort="candidate_name"
      >
        <b-table-column
          label="Evaluad@"
          v-slot="props"
        >
          <div>
            <div v-if="props.row.application.seen === false">
              <b-icon
                icon="check"
                size="is-medium"
              />
            </div>
            <div v-else>
              <b-icon
                icon="check-double"
                size="is-medium"
              />
            </div>
          </div>
        </b-table-column>
        <b-table-column
          field="candidate_name"
          label="Nombre"
          sortable
          v-slot="props"
        >
          <router-link
            :to="{
              name: 'CandidateDetail',
              query: {
                p: table.currentPage,
                s: status,
              },
              params: { id: props.row.application._id },
            }"
          >
            {{ props.row.profile.firstName }}
            {{ props.row.profile.firstSurname }}
            {{ props.row.profile.lastSurname }}
          </router-link>
        </b-table-column>

        <b-table-column
          label="Email"
          sortable
          centered
          v-slot="props"
        >
          {{ props.row.profile.email }}
          <br />
          <div>
            <b-tooltip
              label="Copiar correo"
              position="is-bottom"
              class=""
            >
              <b-icon
                class="iconos"
                icon="copy"
                style="color: teal; hover: true; hover-color: red"
                v-clipboard:copy="props.row.profile.email"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
              </b-icon>
            </b-tooltip>
            <b-tooltip
              label="Enviar correo"
              position="is-bottom"
            >
              <a
                class="email"
                :href="`https://mail.google.com/mail/u/0/?fs=1&to=${props.row.profile.email}&tf=cm`"
                target="_blank"
              >
                <b-icon
                  class="iconos"
                  icon="envelope-open-text"
                >
                </b-icon>
              </a>
            </b-tooltip>
          </div>
        </b-table-column>
        <b-table-column
          v-if="['superqueen', 'recruiter-manager', 'recruiter'].includes($isRole(user))"
          label="Telefono"
          centered
          v-slot="props"
        >
          {{ props.row.profile.phone }}
        </b-table-column>
        <b-table-column
          label="Licenciatura"
          centered
          v-slot="props"
        >
          {{ props.row.profile.career }}
        </b-table-column>
        <b-table-column
          label="Termino de materias"
          centered
          v-slot="props"
        >
          {{ props.row.profile.graduateDate | formatToDate }}
        </b-table-column>
        <b-table-column
          label="Residencia"
          v-if="['superqueen', 'recruiter-manager', 'recruiter'].includes($isRole(user))"
          v-slot="props"
        >
          {{ props.row.profile.state }}<br />{{ props.row.profile.city }}
        </b-table-column>
        <b-table-column
          label="Habilidades"
          v-if="['superqueen', 'recruiter-manager', 'recruiter'].includes($isRole(user))"
          v-slot="props"
        >
          Excel: {{ props.row.profile.excelLevel }}<br />Ingles:{{ props.row.profile.englishLevel }}
        </b-table-column>
      </b-table>
    </div>

    <b-modal
      :active.sync="psychometricTestModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">Enviar prueba psicométrica</div>
        <div class="modal-card-body">
          <div>Enviar mail al candidato para que haga su prueba psicométrica.</div>
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button
            type="is-primary"
            @click="sendPsychometricTest"
          >
            Enviar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active.sync="psychometricDateModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">Enviar prueba psicométrica</div>
        <div class="modal-card-body">
          <div>
            <b-field label="Fecha que aplicó la prueba">
              <b-datepicker
                inline
                placeholder="Selecciona una fecha"
                icon="calendar-today"
                v-model="psychometricDateForm.appliedAt"
              />
            </b-field>
          </div>
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button
            type="is-primary"
            @click="applyPsychometricTest"
          >
            Enviar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active.sync="preHireCandidateModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">Selecionar a {{ preHireCandidateForm.candidate }}</div>
        <div class="modal-card-body">
          <b-message type="is-info"> La fecha de ingreso deberá ser dentro de 72 hrs. </b-message>

          <div class="columns">
            <div class="column">
              <div>
                <b-field label="Dirección">
                  <b-input
                    placeholder="Dirección a la que se presentará"
                    type="textarea"
                    v-model="preHireCandidateForm.address"
                  />
                </b-field>
              </div>
              <div>
                <b-field label="Persona con quien se presentará">
                  <b-input
                    placeholder="Persona con quien se presentará"
                    type="text"
                    v-model="preHireCandidateForm.name"
                  />
                </b-field>
              </div>
            </div>

            <div class="column">
              <div>
                <b-field label="Fecha y hora de entrada">
                  <b-datetimepicker
                    inline
                    size="is-small"
                    placeholder="Selecciona una fecha"
                    icon="calendar-today"
                    :datepicker="{ minDate: threeDaysAfter, unselectableDaysOfWeek: [0, 6] }"
                    :timepicker="{ minTime, maxTime, incrementMinutes: 15 }"
                    v-model="preHireCandidateForm.entry"
                  />
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button @click="closePreHireModal"> Cerrar </b-button>
          <b-button
            type="is-primary"
            @click="preHireCandidate"
          >
            Seleccionar candidato
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  import { vacancyManagementAllowedRoles } from '@/lib/permissions'
  

  export default {
    data() {
      return {
        //activeTab: 'Todos',
        vacancyManagementAllowedRoles,
        isLoading: false,
        nameToSearch: "",
        vacancy: {
          name: null,
          id: null,
        },
        company: {
          name: null,
          id: null,
        },
        status: "rejected",
        table: {
          data: [],
          filteredData: [],
          isPaginated: true,
          isPaginationSimple: false,
          paginationPosition: "bottom",
          defaultSortDirection: "asc",
          sortIcon: "arrow-up",
          sortIconSize: "is-small",
          currentPage: 1,
          perPage: 15,
          hasInput: true,
        },
        psychometricTestModal: false,
        psychometricDateModal: false,
        psychometricDateForm: {
          appliedAt: new Date(),
        },
        candidate: {},
        checkedCandidates: [],
        preHireCandidateModal: false,
        preHireCandidateForm: {
          candidate: "",
          candidateId: "",
          address: "",
          entry: new Date(),
          name: "",
        },
      };
    },
    watch: {
      async status(val) {
        if (val && val.length > 3) {
          const params = [`status=${val}`];
          if (this.nameToSearch) {
            params.push(`candidate=${this.nameToSearch}`);
          }
          params.join("&");
          await this.getCandidates(params);
        } else {
          await this.getCandidates();
        }
      },
      async nameToSearch(val) {
        const regex = new RegExp(val, "gi");

        this.table.filteredData = this.table.data.filter((element) => {
          const firstName = element.profile.firstName.trim().toLowerCase();
          const firstSurname = element.profile.firstSurname.trim().toLowerCase();
          const lastSurname = element.profile.lastSurname.trim().toLowerCase();

          const name = `${firstName} ${firstSurname} ${lastSurname}`;
          
          return new RegExp(regex).test(name);
        });
      },
      'table.data'() {
        this.table.filteredData = this.table.data;
      },
    },
    computed: {
      ...mapGetters(["companyId"]),
      route() {
        return this.$route.name;
      },
      id() {
        return this.$route.params.id;
      },
      user() {
        return this.$store.state.session.user;
      },
      thereAreSelected() {
        if (!this.table.data.length) return false;
        return this.table.data.some(
          (item) => item.application.status === "selected" || item.application.status === "sent"
        );
      },
      selectedStatus() {
        return ["superqueen", "recruiter-manager", "recruiter"].includes(this.$isRole(this.user))
          ? `status=${this.status}`
          : "&selected=true";
      },
      hiredCandidates() {
        return !!this.table.data.filter((candidate) => candidate.status === "hired").length;
      },
      appUrl() {
        return `${process.env.VUE_APP_BASE_URL}`;
      },
      threeDaysAfter() {
        const date = moment().add(3, "day").toDate();
        return date;
      },
      thisCompany() {
        return this.$store.state.currentCompany;
      },
      minTime() {
        const min = moment(this.preHireCandidateForm.entry).startOf("day").add(8, "hours").toDate();
        return min;
      },
      maxTime() {
        const max = moment(this.preHireCandidateForm.entry).startOf("day").add(18, "hours").toDate();
        return max;
      },
      queryPage() {
        return this.$route.query.p;
      },
    },
    async mounted() {
      this.table.currentPage = this.queryPage;
      await this.getCandidates();
    },
    methods: {
      hiredDash() {
        const win = window.open("https://forms.monday.com/forms/bf54d5cd9df8a029eb807a2d022f8e7b?r=use1", "_blank");
        win.focus();
      },
      onCopy: function () {
        this.$toast.success({
          message: "Correo copiado",
        });
      },
      onError: function (e) {
        alert("No se pudo copiar el texto al portapapeles: " + e.text);
      },
      isCheckable(row) {
        const hasInterviewDone = row.application.promeritumInterviewDone;
        const hasStatusApplyingOrSelected =
          row.application.status === "applying" || row.application.status === "selected";
        return hasInterviewDone && hasStatusApplyingOrSelected;
      },
      async getCandidates(params) {
        this.isLoading = true;
        params = params || [this.selectedStatus];
        params = params.join("&");
        try {
          const { data } = await this.$api.get(`/vacancies/${this.id}/candidates?${params}`);
          this.table.data = data.candidates;
          this.vacancy = data.vacancy;
          this.company = data.company;
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
        this.isLoading = false;
      },
      async sendCandidates() {
        this.isLoading = true;
        try {
          const payload = {
            candidates: this.checkedCandidates
              .filter((candidate) => candidate.application.status === "selected")
              .map((candidate) => candidate._id),
          };
          await this.$api.post(`/vacancies/${this.vacancy._id}/send-candidates`, payload);
          await this.getCandidates();
          this.checkedCandidates = [];
          this.$toast.success({
            message: "Se enviaron a los candidatos",
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      async selectCandidate(candidateId, candidate) {
        this.isLoading = true;
        this.$buefy.dialog.confirm({
          title: "Seleccionar candidato",
          message: `¿Quieres seleccionar a ${candidate}?`,
          confirmText: "Seleccionarlo",
          cancelText: "¡Noup!",
          onConfirm: async () => await this.sendRequest(candidateId, "select-candidate", "Se seleccionó al candidato"),
        });
        this.isLoading = false;
      },
      async openPreHireModal(candidateId, candidate) {
        this.preHireCandidateModal = true;
        this.preHireCandidateForm.candidate = candidate;
        this.preHireCandidateForm.candidateId = candidateId;
      },
      async closePreHireModal() {
        this.preHireCandidateModal = false;
        this.preHireCandidateForm = {
          candidate: "",
          candidateId: "",
          address: "",
          entry: "",
          name: "",
        };
      },
      async preHireCandidate() {
        this.isLoading = true;
        try {
          const payload = this.preHireCandidateForm;
          delete payload.candidate;
          await this.$api.post(`/vacancies/${this.vacancy._id}/prehire-candidate`, payload);
          await this.getCandidates();
          this.closePreHireModal();
          this.checkedCandidates = [];
          this.$toast.success({
            message: "Se seleccionó al candidato",
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      async hireCandidate(candidateId, candidate) {
        this.isLoading = true;
        this.$buefy.dialog.confirm({
          title: "Contratar candidato",
          message: `¿Quieres contratar a ${candidate}?`,
          confirmText: "Contratarlo",
          cancelText: "¡Noup!",
          onConfirm: async () => {
            await this.sendRequest(candidateId, "hire-candidate", "Se contrató al candidato");
            this.hiredDash();
          },
        });
        this.isLoading = false;
      },
      async rejectCandidate(candidateId, candidate) {
        this.isLoading = true;
        this.$buefy.dialog.confirm({
          title: "Rechazar candidato",
          message: `¿Quieres rechazar a ${candidate}?`,
          confirmText: "Rechazarlo",
          cancelText: "¡Noup!",
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => await this.sendRequest(candidateId, "reject-candidate", "Se rechazó al candidato"),
        });
        this.isLoading = false;
      },
      async sendRequest(candidateId, endpoint, message) {
        this.isLoading = true;
        try {
          await this.$api.post(`/vacancies/${this.vacancy._id}/${endpoint}`, { candidate: candidateId });
          await this.getCandidates();
          this.checkedCandidates = [];
          this.$toast.success({
            message: message,
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      async checkPsychometricTest(user) {
        this.isLoading = true;
        try {
          const payload = user.profile;
          payload.psychometricTest = true;
          await this.$api.put(`/candidates/${user._id}`, { profile: payload });
          await this.getCandidates();
          this.$toast.success({
            message: "El candidato completó le prueba",
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      async returnRejectedApplications() {
        this.isLoading = true;
        try {
          const payload = {
            applicationsIds: this.checkedCandidates.map((candidate) => candidate.application._id)
          };
          await this.$api.post(`/vacancies/${this.vacancy._id}/reactivate-applications`, payload);
          await this.getCandidates();
          this.checkedCandidates = [];

          this.$toast.success({
            message: "Se regresaron los candidatos",
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      openDialogPsychometricTest(candidate) {
        this.psychometricTestModal = true;
        this.candidate = candidate;
      },
      closeDialogPsychometricTest() {
        this.psychometricTestModal = false;
        this.candidate = {};
      },
      async sendPsychometricTest() {
        this.isLoading = true;
        try {
          const payload = { recruiter: this.thisCompany.recruiter.email, vacancyId: this.vacancy._id };
          await this.$api.post(`/candidates/${this.candidate._id}/send-psychometric`, payload);
          await this.getCandidates();
          this.closeDialogPsychometricTest();
          this.$toast.success({
            message: "El test se envió correctamente",
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      openDialogPsychometricDate(candidate) {
        this.psychometricDateModal = true;
        this.candidate = candidate;
      },
      closeDialogPsychometricDate() {
        this.psychometricDateModal = false;
        this.candidate = {};
      },
      async applyPsychometricTest() {
        this.isLoading = true;
        try {
          await this.$api.post(`/candidates/${this.candidate._id}/apply-psychometric`, this.psychometricDateForm);
          await this.getCandidates();
          this.closeDialogPsychometricDate();
          this.$toast.success({
            message: "Se agregó la fecha de aplicación psicométrica",
          });
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
      goTo(to) {
        this.$router.push(to);
      },
      openPDF(link) {
        const url = this.$router.resolve({ name: "PDFViewer", query: { empty: true, link: link } });
        window.open(url.href);
        return false;
      },
      async setInterview(id) {
        this.isLoading = true;
        try {
          await this.$api.post(`/application/${id}/promeritum-interview`);
          this.$toast.success({
            message: "Se agendó la entrevista",
          });
          await this.getCandidates();
        } catch (error) {
          this.$toast.error({
            message : error
          });
        }
        this.isLoading = false;
      },
    },
  };
</script>
<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
  .iconos :hover {
    color: #4dc0b5;
  }
  .email {
    color: teal;
  }
</style>
