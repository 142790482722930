<template>
  <div class="card mt-5 mb-5">
    <div class="card-content columns is-multiline">
      <div class="column is-12 ">
        <b-field label="Resoluciones">
          <div class=" is-multiline">
              <div class="columns m-auto is-desktop is-vcentered" v-for="(resolution, index) in availableMediaQueries" :key="index">
                <div class="column is-3">
                  <b-field label="Alias">
                    <b-input 
                      v-model="resolution.alias" 
                      placeholder="Alias" 
                      type="text" 
                      @blur="updateAvailableMediaQueries"
                  />
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Ancho Mínimo (px)">
                    <b-input 
                      v-model="resolution.minWidth" 
                      placeholder="Ancho Mínimo" 
                      type="number" 
                      @blur="updateAvailableMediaQueries"
                  />
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Ancho Máximo (px)">
                    <b-input 
                      v-model="resolution.maxWidth" 
                      placeholder="Ancho Máximo" 
                      type="number" 
                      @blur="updateAvailableMediaQueries"
                  />
                  </b-field>
                </div>
                <div class="column is-1 mt-auto">
                  <b-button type="is-danger" icon-left="trash"
                    @click="openRemoveResolutionModal(resolution._id)"></b-button>
                </div>
              </div>
            <div class="is-flex is-align-items-flex-end is-justify-content-flex-end mt-3">
              <b-button type="is-primary" @click="addResolution">
                <f-icon icon="fa-regular fa-laptop-mobile" class="mr-1"/>
                Agregar resolución
              </b-button>
            </div>
          </div>
        </b-field>
      </div>
    </div>

    <!-- Delete resolution confirmation modal -->
    <b-modal
      :active.sync="showRemoveResolutionModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">
          <strong>
            <f-icon icon="fa-regular fa-laptop-mobile" class="mr-2"/>
            ¿Estás seguro que deseas eliminar la resolución?
          </strong>
        </div>
        <div class="modal-card-body">
          Esto provocará que también se <strong>eliminen las secciones</strong> que únicamente están asociadas a esa resolución, así como <strong>todos sus recursos</strong> que fueron subidos anteriormente.
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button
            type="is-danger"
            @click="removeResolution"
          >
            Sí, eliminar
          </b-button>
          <b-button
            type="is-grey"
            @click="closeRemoveResolutionModal"
          >
            No, mantener.
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: {
    defaultAvailableMediaQueries: {
      type: Array,
    },
  },
  data() {
    return {
      availableMediaQueries: [],
      resolutionToDelete: null,
      showRemoveResolutionModal: false,
      isLoading: false,
    }
  },
  mounted() {
    this.availableMediaQueries = [...this.defaultAvailableMediaQueries];
  },
  watch: {
    defaultAvailableMediaQueries: {
      handler: function (val) {
        this.availableMediaQueries = [...val]
      }
    },
  },
  methods: {
    updateAvailableMediaQueries() {
      if(this.checkForIntersections()) 
        return;
      else
        this.$emit('updateLandingProperty', this.availableMediaQueries, 'availableMediaQueries')
    },
    addResolution() {
      this.availableMediaQueries.push({ alias: '', minWidth: '', maxWidth: '' });
    },
    removeResolution() {
      this.availableMediaQueries = this.availableMediaQueries.filter(resolution => resolution._id !== this.resolutionToDelete)
      this.updateAvailableMediaQueries();
      this.closeRemoveResolutionModal();
    },
    openRemoveResolutionModal(resolutionId){
      this.resolutionToDelete = resolutionId
      this.showRemoveResolutionModal = true
    },
    closeRemoveResolutionModal(){
      this.resolutionToDelete = null
      this.showRemoveResolutionModal = false
    },
    checkForIntersections() {
      for (let i = 0; i < this.availableMediaQueries.length; i++) {
        const current = this.availableMediaQueries[i];
        const minWidth = parseInt(current.minWidth);
        const maxWidth = parseInt(current.maxWidth);

        if(current.alias == '' || current.minWidth == '' || current.maxWidth == '')
          return true;

        for (let j = 0; j < this.availableMediaQueries.length; j++) {
          if (i === j) continue;
          const resolution = this.availableMediaQueries[j];
          const min = parseInt(resolution.minWidth);
          const max = parseInt(resolution.maxWidth);

          if ((minWidth >= min && minWidth <= max) || (maxWidth >= min && maxWidth <= max)) {
            this.$toast.error({
              message: `La resolución "${current.alias}" interfiere con la resolución "${resolution.alias}".`,
              duration: 5000
            });
            return true;
          }
        }
      }

      return false;
    }
  }
}
</script>

<style scoped>
</style>
