import httpApi from "@/axios";
import Vue from 'vue'

const searchFunnelApplications = async function (payload) {
  try {
    const { data } = await httpApi.post(`/stats/applications/funnel`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchVacancyBreakdown = async function (payload) {
  try {
    const { data } = await httpApi.post(`/stats/vacancies`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchApplicationsBreakdown = async function (payload) {
  try {
    const { data } = await httpApi.post(`/stats/applications`,
      payload,
      { timeout: 180000 }

    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};
const searchCompaniesBreakdown = async function (payload) {
  try {
    const { data } = await httpApi.post(`/stats/companies`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchIndicators = async function (payload) {
  try {
    const { data } = await httpApi.post(`/stats/indicators`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchRecruitment = async function (payload) {
  try {
    const { data } = await httpApi.post(
      `/stats/vacancies/recruitment`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchRejections = async function (payload) {
  try {
    const { data } = await httpApi.post(
      `/stats/applications/rejection`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchApplicationsGrossProfile = async function (payload) {
  try {
    const { data } = await httpApi.post(
      `/stats/applications/gross-profile`,
      payload,

      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchApplicationsHiringProfile = async function (payload) {
  try {
    const { data } = await httpApi.post(`/stats/applications/hiring`,
      payload,
      { timeout: 180000 }
    )
    return data

  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchVacanciesGrossProfile = async function (payload) {
  try {
    const { data } = await httpApi.post(
      `/stats/vacancies/gross-profile`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchVacanciesContactList = async function (payload) {
  try {
    const { data } = await httpApi.post(
      `/stats/vacancies/contact-list`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchCandidatesBreakdown = async function (payload) {
  try {
    const { data } = await httpApi.post(
      `/stats/users/count-candidates`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

const searchLegacyData = async function (payload) {
  try {
    const { data } = await this.$api.post(
      `/dashboard/${payload.company}`,
      payload,
      { timeout: 180000 }
    );
    return data;
  } catch (error) {
    Vue.$toast.error({
      message: error,
    });
  }
};

export {
  searchFunnelApplications,
  searchVacancyBreakdown,
  searchApplicationsBreakdown,
  searchIndicators,
  searchRecruitment,
  searchRejections,
  searchApplicationsGrossProfile,
  searchVacanciesGrossProfile,
  searchApplicationsHiringProfile,
  searchVacanciesContactList,
  searchLegacyData,
  searchCandidatesBreakdown,
  searchCompaniesBreakdown,
};
