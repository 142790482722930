import router from '@/routes'
import axios from 'axios'

const notRedirect = ['/login', '/recovery-password', '/set-password', '/invite-admin']

const httpApi = {
  get(url, options) {
    return this.request('get', url, options)
  },

  put(url, data, options) {
    return this.request('put', url, data, options)
  },

  post(url, data, options) {
    return this.request('post', url, data, options)
  },

  delete(url, options) {
    return this.request('delete', url, options)
  },

  request(method, endpoint, data, options) {
    axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/v1`

    // Configurar el envío de cookies automáticamente
    axios.defaults.withCredentials = true;

    return axios[method](endpoint, data, options)
  }
}

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const currentRoute = router.currentRoute.path
  if (error.response?.status === 401 && !notRedirect.includes(currentRoute)) {
    router.push('/login')
  }

  return Promise.reject(error)
})

export default httpApi