<template>
  <div>
    <b-modal
      :active.sync="isOpen"
      has-modal-card
      trap-focus
      :canCancel="false"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Descargar perfil en PDF</div>
        </div>
        <div class="modal-card-body">
          <div class="columns is-multiline">
            <div class="column is-12">Escoge el orden en el cuál quieres que se descargue el PDF:</div>
            <div class="column is-12">
              <div
                class="sort-container"
                v-sortable="sortableOptions"
              >
                <div
                  v-for="section in sections"
                  :key="`section-${section.name}`"
                  :class="section.disabled ? '' : 'sort-element'"
                >
                  <div :class="['card', section.disabled ? 'is-disabled' : '']">
                    <div class="card-content">
                      <div class="is-flex justify-space-between">
                        <div>
                          <b-icon icon="arrows-up-down-left-right" />
                          {{ section.title }}
                        </div>
                        <div>
                          <b-switch
                            :disabled="section.disabled"
                            v-model="section.active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-card-foot">
          <b-button
            type="is-primary"
            @click="close"
          >
            Cerrar
          </b-button>
          <b-button
            type="is-primary"
            tag="a"
            target="_blank"
            :disabled="!order"
            @click="downloadProfile"
          >
            Descargar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import Sortable from "sortablejs";
  import { downloadAllCandidateInfoAllowedRoles } from "@/lib/permissions";

  const createSortable = (el, options, vnode) => {
    return Sortable.create(el, {
      ...options,
      onEnd: function (evt) {
        const data = vnode.context.sections;
        const item = data[evt.oldIndex];
        if (evt.newIndex > evt.oldIndex) {
          for (let i = evt.oldIndex; i < evt.newIndex; i++) {
            data[i] = data[i + 1];
          }
        }
        if (evt.newIndex < evt.oldIndex) {
          for (let i = evt.oldIndex; i > evt.newIndex; i--) {
            data[i] = data[i - 1];
          }
        }
        data[evt.newIndex] = item;
        data.forEach((section, index) => {
          section.position = index;
        });
      },
    });
  };

  const sortable = {
    name: "sortable",
    bind(el, binding, vnode) {
      const container = el;
      container._sortable = createSortable(container, binding.value, vnode);
    },
    update(el, binding, vnode) {
      const container = el;
      container._sortable.destroy();
      container._sortable = createSortable(container, binding.value, vnode);
    },
    unbind(el) {
      const container = el;
      container._sortable.destroy();
    },
  };

  export default {
    directives: { sortable },
    props: {
      application: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        isLoading: false,
        isOpen: false,
        sortableOptions: {
          chosenClass: "is-primary",
          draggable: ".sort-element",
          animation: 150,
          ghostClass: "has-background-primary",
          sort: true,
        },
        sections: [
          {
            name: "fat4",
            title: "Perfil del candidato",
            active: true,
            disabled: false,
            position: 0,
          },
          {
            name: "killerQuestions",
            title: "Killer Questions",
            active: false,
            disabled: false,
            position: 1,
          },
          {
            name: "fat6",
            title: "Entrevista por Pro Meritum",
            active: false,
            disabled: false,
            position: 2,
          },
          {
            name: "customEvaluation",
            title: "Evaluación personalizada",
            active: false,
            disabled: false,
            position: 3,
          },
          {
            name: "companyInterviewFeedback",
            title: "Feedback de la empresa",
            active: false,
            disabled: false,
            position: 4,
          },
        ],
      };
    },
    computed: {
      companyId() {
        return this.company || this.$store.state.currentCompany.id;
      },
      recruiters() {
        return this.$store.getters.unassignedRecruiters;
      },
      order() {
        const order = this.sections
          .filter((section) => section.active)
          .sort((a, b) => {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            return 0;
          })
          .map((section) => section.name);
        return order.length ? order.join() : null;
      },
      downloadLink() {
        let url = `/application/${this.application._id}/download?order=${this.order}`;
        return url;
      },
    },
    methods: {
      open() {
        this.enableSections();
        this.isOpen = true;
      },
      enableSections() {
        const application = this.application;
        const customEvaluation = {
          applicationLinkVideo: application?.profile?.applicationLinkVideo,
          applicationFile: application?.profile?.applicationFile,
          aditionalComments: application?.profile?.aditionalComments,
        };

        this.sections[1].disabled = !application?.profile.hasKillerQuestions;
        this.sections[2].disabled = !(application?.promeritumInterviewDone && this.$hasPermissionsByRole(downloadAllCandidateInfoAllowedRoles));
        this.sections[3].disabled = Object.values(customEvaluation).every((value) => !value);
        this.sections[4].disabled = !application?.companyInterviewFeedback;
      },
      close() {
        this.isOpen = false;
        this.reset();
      },
      reset() {
        this.sections = [
          {
            name: "fat4",
            title: "Perfil del candidato",
            active: true,
            disabled: false,
            position: 0,
          },
          {
            name: "killerQuestions",
            title: "Killer Questions",
            active: false,
            disabled: false,
            position: 1,
          },
          {
            name: "fat6",
            title: "Entrevista por Pro Meritum",
            active: false,
            disabled: false,
            position: 2,
          },
          {
            name: "customEvaluation",
            title: "Evaluación personalizada",
            active: false,
            disabled: false,
            position: 3,
          },
          {
            name: "companyInterviewFeedback",
            title: "Feedback de la empresa",
            active: false,
            disabled: false,
            position: 4,
          },
        ];
      },
      async downloadProfile() {
        try {
          const candidateProfile = await this.$api.get(this.downloadLink);
          const JSONCandidateProfile = JSON.stringify(candidateProfile.data);

          window.localStorage.setItem("PM_CANDIDATE_PROFILE", JSONCandidateProfile);
          window.open("/download-candidate-profile");
        } catch (error) {
          this.$toast.error({
            message: error,
          });
        }
      },
    },
  };
</script>
