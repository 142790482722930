import store from '@/store'
import userManagentTree from '../lib/userManagmentTree'

const CheckRole = {
  install: function (Vue) {
    Vue.prototype.$isRole = function () {
      return store?.state?.session?.user?.role
    }
    Vue.prototype.$hasPermissionsByRole = function (roles) {
      return roles.includes(store?.state?.session?.user?.role ?? 'guest')
    },
      Vue.prototype.$hasPermissionsByCompany = function (company, hasExternalPermissions = true) {
        if (!company) return false;

        // Setting up id's
        const headRecruiter = company.headRecruiter?.id || company.headRecruiter
        const headRecruiterManager = company.headRecruiterManager?.id || company.headRecruiterManager
        const companyId = company.id || company
        const userId = store?.state?.session?.user?.id
        const userCompany = store?.state?.session?.user?.company

        return (headRecruiterManager === userId ||
          headRecruiter === userId ||
          (companyId === userCompany && hasExternalPermissions));
      },
      Vue.prototype.$hasPermissionsByVacancy = function (vacancy) {
        if (!vacancy) return false;

        const vacancyRecruiter = vacancy.recruiter?.id ? vacancy.recruiter.id : vacancy.recruiter
        return (vacancyRecruiter === store?.state?.session?.user?.id) || vacancy.additionalRecruiters?.includes(store?.state?.session?.user?.id)
      },
      Vue.prototype.$hasPermissionByManagmentTree = function ({ user, userOnRole, userOnAction }) {            
        // Se verifica si el usuario tiene permisos para crear un usuiario con el rol especificado
        if (userOnRole) {
          const allowedRolesToManage = userManagentTree[user.role][userOnAction]; // Roles permitidos a modificar por user          
    
          if (!allowedRolesToManage.includes(userOnRole)) { // Si el rol del usuario no está en los permitidos  
            return false;
          }
          
          return true;
      }
        return false;
    }
  }
}

export default CheckRole