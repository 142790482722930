<template>
  <div>
    <div class="column pl-0 pt-0 mb-2 is-full is-flex is-align-content-center">
      <div class="title is-4 mb-0 has-text-blue">
        {{ professionalDataTranslation.editProfessionalProfile || professionalDataTranslation.professionalProfile }}
      </div>
      <!-- Botón para guardar -->
      <a class="ml-2" @click="updateProfessionalProfile">
        <b-tooltip animated class="is-flex is-align-items-end" label="Guardar cambios" position="is-top"
          type="is-success">
          <b-icon class="icons" icon="floppy-disk"></b-icon>
        </b-tooltip>
      </a>
    </div>
    <ValidationObserver ref="observer">
      <div class="columns is-multiline">
        <!-- Subir CV -->
        <div class="column is-6">
          <p class="pm-register--field mb-2" style="font-weight: bold;">
            Currículum
          </p>
          <div class="is-flex">
            <ValidationProvider name="Currículum" v-slot="{ errors, valid }">
              <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-upload 
                  v-model="form.resumeFile" 
                  accept=".pdf" 
                  @input="setTempUrl"
                >
                  <a class="button is-primary">
                    <b-icon icon="upload"></b-icon>
                  </a>
                </b-upload>
              </b-field>
            </ValidationProvider>

            <!-- Mostrar nombre del archivo -->
            <span v-if="form.resumeFile?.name || form.resume?.name" class="file-name">
              {{ form.resumeFile?.name || form.resume?.name }}
            </span>
            <span v-else class="file-name">
              Selecciona un archivo
            </span>

            <!-- Link para ver archivo actual -->
            <a v-if="form.resumeFile?.tempUrl || form.resume?.url" :href="form.resumeFile?.tempUrl || form.resume?.url"
              target="_blank" class="button is-info">
              Ver archivo
            </a>

            <!-- Botón para eliminar archivo -->
            <b-button type="is-danger" class="ml-2" @click="deleteLocalResume"
              :disabled="!form.resume?.url && !form.resumeFile">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
          <div v-if="form.resumeFile?.size">
            Tamaño del archivo: {{ Number(form.resumeFile.size / (1024 * 1024)).toFixed(2) }} MB
          </div>
        </div>

        <!-- Portafolio -->
        <div class="column is-6">
          <ValidationProvider name="Portafolio" v-slot="{ errors, valid }">
            <b-field label="Portafolio" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
              <b-input v-model="form.portfolio" placeholder="Ingresa la URL del portafolio"></b-input>
            </b-field>
          </ValidationProvider>
        </div>

      </div>
    </ValidationObserver>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import { updateProfessionalProfile } from '@/api/candidate';
import { validateFileExtension } from '@/utils/validateFileExtension';

export default {
  props: {
    profile: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      validateFileExtension,
      form: {
        portfolio: this.profile.portfolio || '',
        resume: this.profile.resume || { name: '', url: '', tag: '', key: '' },
        resumeFile: null,
      },
      isLoading: false,
    };
  },
  methods: {
    async setTempUrl() {
      if (this.form.resumeFile) {
        // Validar tamaño del archivo
        if (!this.checkResumeSize()) {
          this.deleteLocalResume();
          return;
        }

        // Validar extensión del archivo
          const isFileValid = await this.validateFileExtension(this.form.resumeFile, ['pdf'], 'CV');
          if (!isFileValid) {
            this.deleteLocalResume();
            return;
          }

        this.form.resumeFile.tempUrl = URL.createObjectURL(this.form.resumeFile);
        // this.$forceUpdate();
      } else {
        this.form.resumeFile = null;
      }
    },
    checkResumeSize() {
      if (this.form.resumeFile?.size && this.form.resumeFile.size / (1024 * 1024) > 5) {
        this.$buefy.dialog.alert({
          title: '¡Oooops!',
          message: 'El archivo supera el límite de 5MB.',
          type: 'is-danger',
        });
        return false;
      }
      return true;
    },
    async uploadResumeFile() {
      const file = this.form.resumeFile;
      if (!file) return;
      
      try {
        this.isLoading = true;
        
        const payload = new FormData();
        payload.append("file", file);
        
        const { data } = await this.$api.post(`application/${this.$route.params.id}/upload-resume`, payload,{
            headers: { 'Content-Type': 'multipart/form-data' }
          });

        this.form.resume = data;
      } catch (error) {
        const status = error.response?.status; // Código de estado HTTP
        const data = error.response?.data; // Datos de la respuesta

        if (status === 422 && data?.malicious) {
          // Archivo con malware
          this.$toast.error({
            message: "El archivo que intentas subir es potencialmente peligroso y no puede ser cargado.",
          });
        } else if (status === 415) {
          // Extensión de archivo no válida
          this.$toast.error({
            message: data?.validExtension,
            type: "is-danger",
          });
        } else {
          // Otros errores genéricos
          this.$toast.error({
            message: error,
          });
        }
        this.form.resumeFile = null;
      } finally {
        this.isLoading = false;
      }
    },
    deleteLocalResume() {
      this.form.resume = { name: '', tag: '', url: '', key: '' };
      URL.revokeObjectURL(this.form.resumeFile?.tempUrl);
      this.form.resumeFile = null;
    },
    async updateProfessionalProfile() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$toast.error({
          message: 'Por favor, corrige los errores antes de continuar.',
        });
        return;
      }

      try {
        const userId = this.$store.state.session.user.id;

        await this.uploadResumeFile();

        const payload = {
          user: userId,  // Incluir el ID del usuario
          portfolio: this.form.portfolio,
          resume: {
            name: this.form.resume.name,
            url: this.form.resume.url,
            tag: this.form.resume.tag,
            key: this.form.resume.key,
          },
        };

        await updateProfessionalProfile(this.$route.params.id, payload);

        this.$toast.success({
          message: 'Perfil profesional actualizado con éxito.',
        });
        
        this.$emit('ProfileProfessionalUpdated', payload);
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
    },
  },
  computed: {
    professionalDataTranslation() {
      return this.$t('screens.candidate.detail');
    },
  },
};
</script>
