import {
  confirmed,
  digits,
  email,
  image,
  length,
  max_value,
  max,
  min,
  min_value,
  numeric,
  required,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "./plugins/i18n"

extend("confirmed", {
  ...confirmed,
  message: () => i18n.t('errors.validation.confirmed'),
});

extend("required", {
  ...required,
  message: () => i18n.t('errors.validation.required'),
});

extend("email", {
  ...email,
  message: () => i18n.t('errors.validation.email'),
});

extend("numeric", {
  ...numeric,
  message: () => i18n.t('errors.validation.numeric'),
});

extend("digits", {
  ...digits,
  message: (value, { length }) => i18n.t("errors.validation.digits", { length }),
});

extend("min_value", {
  ...min_value,
  message: (value, { min }) => i18n.t("errors.validation.min_value", { min }),
});

extend("max", {
  ...max,
  message: (value, { length }) =>
    i18n.t("errors.validation.max", { length: length + 1 }),
});

extend("min", {
  ...min,
  message: (value, { length }) =>
    i18n.t("errors.validation.min", { length: length - 1 }),
});

extend("max_value", {
  ...max_value,
  message: (value, { max }) => i18n.t("errors.validation.max_value", { max }),
});

extend("length", {
  ...length,
  message: (value, { length }) =>
    i18n.t("errors.validation.length", { length }),
});

extend("image", {
  ...image,
  message: (value, { name }) => i18n.t("errors.validation.image", { name }),
});

extend("hour", {
  params: ["hour"],
  validate: (value) => {
    const formatHour = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return formatHour.test(value);
  },
  message: () => i18n.t('errors.validation.hour'),
});

extend("fileSize", {
  params: ["size"],
  validate: (value, { size }) => {
    if (value.size) {
      return value.size / (1024 * 1024) <= size;
    }
    return true;
  },
  message: (value, { size }) => i18n.t("errors.validation.fileSize", { size }),
});

// Agregar la validación de complejidad de contraseña
extend("password_complexity", {
  validate: (value) => {
    const passwordRegex = /^(?=.*[A-ZÑ])(?=.*[a-zñ])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|\\:;"'<>,.?/~])[A-Za-zñÑ\d!@#$%^&*()\-_=+{}[\]|\\:;"'<>,.?/~]{12,128}$/;

    return passwordRegex.test(value);
  },
  message: "La contraseña no cumple con los requisitos de seguridad.",
});
