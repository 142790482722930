<template>

  <div>

    <b-modal
      :active.sync="isOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>

      <div class="modal-card">

        <div class="modal-card-head">

          <p class="subtitle has-text-weight-semibold is-size-4">
            Motivos de cierre de la vacante
          </p>

        </div>

        <div class="modal-card-body">

          <div class="columns">

            <div class="column is-7">

              <b-message
                type="is-info"
              >
                Selecciona uno o ambos motivos de cierre de la vacante. Puedes seleccionar un 
                <b>
                  máximo de {{ maxNotHiredPositions }}
                </b>
                posiciones para uno o entre ambos motivos.
              </b-message>

            </div>
            
            <div class="column is-5 is-flex justify-center align-flex-start direction-column">

              <p class="has-text-weight-semibold ">
                Posiciones disponibles: {{ avaliblePlaces }}
              </p>
    
              <p class="has-text-weight-semibold ">
                Posiciones contratadas: {{ hiredPlaces }}
              </p>

              <p class="has-text-weight-semibold ">
                Posiciones seleccionadas: {{ positionsFlag }}
              </p>

            </div>

          </div>

          <b-field class="mt-3 ">

            <b-checkbox-button 
              v-model="otherReason"
              native-value="Cerrada por la empresa"
              type="is-warning"
              class="controls"
              expanded
              :disabled="canceledPositions >= maxNotHiredPositions"
            >
              
              <b-icon icon="user-secret"/>
              <span>Posiciones cerradas por la empresa</span>
              
            </b-checkbox-button>
            
            <b-numberinput
              controls-position="compact"
              controls-alignment="right"
              :disabled="!otherReason.includes('Cerrada por la empresa') "
              v-model="closedPositions"
              min="0"
              :max="maxNotHiredPositions"
            />
        

          </b-field>

          <b-field class="">

            <b-checkbox-button 
              v-model="otherReason"
              native-value="Cancelada por la empresa"
              type="is-danger"
              class="controls"
              expanded
              :disabled="closedPositions >= maxNotHiredPositions"
            >

              <b-icon icon="close"></b-icon>
              <span>Posiciones canceladas por la empresa</span>

            </b-checkbox-button>

            <b-numberinput 
              class=""
              controls-position="compact"
              controls-alignment="right"
              :disabled="!otherReason.includes('Cancelada por la empresa')"
              v-model="canceledPositions"
              min="0"
              :max="maxNotHiredPositions"
            />

          </b-field>

        </div>

        <div class="modal-card-foot justify-flex-end">

          <b-button
            type="is-primary"
            @click="closeVacancy"
            :disabled="!otherReason.length > 0"
            >
            Cerrar vacante
          </b-button>

        </div>


      </div>

    </b-modal>


  </div>

</template>

<script>
export default {
  data(){
    return{
      isOpen: false,
      vacancy: {},
      otherReason: [],
      avaliblePlaces: 0,
      hiredPlaces: 0,
      closedPositions: 0,
      canceledPositions: 0,
      validReason: true
    }
  },
  watch:{

    otherReason(val) {

      if(!val.includes('Cerrada por la empresa')) setTimeout( () => this.closedPositions = 0 , 500)

      if(!val.includes('Cancelada por la empresa')) setTimeout( () => this.canceledPositions = 0 , 500)

    },
    positionsFlag(val) {

      if(val > this.maxNotHiredPositions){

        this.$toast.warning({
          message: 'Sobrepasaste la cantidad de posiciones que podian ser <b>cerradas o canceladas por la empresa</b>. <br> Verifica las cantidades en los motivos de cierre.',
          duration: 6000
        })

      }

    },
    closedPositions(val){

      if(val === this.maxNotHiredPositions){
        this.otherReason = this.otherReason.filter( reason => reason === 'Cerrada por la empresa')
      }

      if(val === 0 ){
        this.otherReason = this.otherReason.filter( reason => reason !== 'Cerrada por la empresa')
      }

    },
    canceledPositions(val){

      if(val === this.maxNotHiredPositions){
        this.otherReason = this.otherReason.filter( reason => reason === 'Cancelada por la empresa')
      }

      if(val === 0 ){
        this.otherReason = this.otherReason.filter( reason => reason !== 'Cancelada por la empresa')
      }

    }
  
  },
  computed: {

    positionsFlag() {
      return this.closedPositions + this.canceledPositions 
    },
    maxNotHiredPositions(){
      const vacancyPositions = this.vacancy.positions ? this.vacancy.positions : this.vacancy.places
      return vacancyPositions - this.hiredPlaces 
    }

  },
  methods: {
    async open(vacancy){
      this.avaliblePlaces = vacancy.positions ? vacancy.positions : vacancy.places
      this.vacancy = vacancy
      await this.getHiredCandidates()
    },
    reset(){
      this.otherReason = []
      this.closedPositions = 0
      this.canceledPositions = 0
      this.vacancy = {}
    },
    close(){
      this.reset()
      this.isOpen = false
    },
    async getHiredCandidates(){
      try{
        const { data } = await this.$api.get(`/vacancies/${this.vacancy.id}/candidates`)
        this.hiredPlaces = data.candidates.filter( candidate => candidate.application.status === 'hired' ).length
        if(this.hiredPlaces === this.vacancy.positions || this.hiredPlaces === this.vacancy.places){
          await this.filledVacancy()
        }
        else this.isOpen = true
      }catch(error){
        this.$toast.error({
          message: 'Error al obtener cantidad de posiciones cubiertas',
        })
      }
    },
    async filledVacancy (){
      const payload = {
        reasons: ['Se cumplieron las posiciones'],
        filledPositions: this.hiredPlaces
      }
      try {
        await this.$api.post(`/vacancies/${this.vacancy.id}/close`, payload)
        this.$emit('update')
        this.close()
        this.$toast.success({
          message: 'La vacante se cerró',
        })
      } catch (error) {
        this.$toast.error({
          message: error,
        })
      }
    },
    async closeVacancy () {
      
      if( this.otherReason.includes('Cerrada por la empresa') && !this.closedPositions > 0 ){ 
        this.$toast.warning({
          message: 'Indica la cantidad de posiciones cerradas por la empresa.',
        })
        this.validReason = false
      }

      if( this.otherReason.includes('Cancelada por la empresa') && !this.canceledPositions > 0 ){ 
        this.$toast.warning({
          message: 'Indica la cantidad de posiciones canceladas por la empresa.',
        })
        this.validReason = false
      }

      if( this.otherReason.includes('Cerrada por la empresa') && this.otherReason.includes('Cancelada por la empresa') && !this.closedPositions > 0 && !this.canceledPositions > 0 ){ 
        this.$toast.warning({
          message: 'Indica la cantidad de vacantes cerradas y canceladas  por la empresa.',
        })
        this.validReason = false
      }

      if(this.validReason){
        if( this.positionsFlag != this.maxNotHiredPositions ) {
          this.$toast.warning({
            message: 'Las posiciones seleccionadas tienen que coincidir con el máximo seleccionable.',
          })
        }
        else{
          const payload = {
            reasons: this.otherReason,
            filledPositions: this.hiredPlaces,
            closedPositions: this.closedPositions,
            canceledPositions: this.canceledPositions
          }
          if(this.hiredPlaces > 0 ) payload.reasons.push('Se cumplieron las posiciones')
          try {
            await this.$api.post(`/vacancies/${this.vacancy.id}/close`, payload)
            this.$emit('update')
            this.close()
            this.$toast.success({
              message: 'La vacante se cerró',
            })
          } catch (error) {
            this.$toast.error({
              message: error,
            })
          }
        }  
      }
      this.validReason = true
    },
  }
}
</script>

<style lang="scss" scoped>

</style>