<template>
  <div class="container">
    <div class="columns is-multiline">
      <BasicLandingPageForm ref="basicLandingPage" v-show="landing.template === 'basic'" :defaultLanding="landing"
        @updateBasicLanding="updateLandingPayload" :validImage.sync="validImage"/>
      <CustomLandingPageForm ref="customLandingPage" v-show="landing.template === 'custom'" :defaultLanding="landing"
        @updateCustomUrl="updateCustomUrl" />
      <MultimediaLandingPageForm ref="multimediaLandingPage" v-show="landing.template === 'multimedia'"
        :defaultLanding="landing" @updateMultimediaLanding="updateLandingPayload" :deleteImage="deleteImage"/>

      <div class="column is-4 p-0 m-0">
        <div class="mb-4" id="firstContainer">
          <LandingTypeSelector :defaultType="landing.template" :lastUpdate="landing.updatedAt" :isLoading="isLoading"
            @updateTemplate="updateTemplate" />
        </div>

        <div class="card edition-card" id="secondContainer" v-show="landing.template === 'multimedia'">
          <SectionEditor
            :availableMediaQueries="landing.availableMediaQueries"
            :defaultMediaSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.selectedSection"
            :uploadImage="uploadImage" 
            :deleteImage="deleteImage"
            :riseSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.riseSection"
            :descendSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.descendSection"
            :deleteSections="$refs.multimediaLandingPage?.deleteSections"
            @updateMediaSection="e => $refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.updateMediaSection(e)" 
            class="inner-card"
          />

          <ResourcesEditor
            :defaultMediaSection="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.selectedSection"
            :defaultMediaSource="$refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.selectedMediaSource"
            :uploadImage="uploadImage" 
            :deleteImage="deleteImage"
            @updateMediaSection="e => $refs.multimediaLandingPage?.$refs.mediaSectionsLandingPage?.updateMediaSection(e)"
            class="inner-card"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicLandingPageForm from "../components/landings/BasicLandingPageForm.vue";
import CustomLandingPageForm from "../components/landings/CustomLandingPageForm.vue";
import MultimediaLandingPageForm from "../components/landings/MultimediaLandingPageForm.vue";
import LandingTypeSelector from "../components/landings/LandingTypeSelector.vue";
import SectionEditor from '../components/landings/multimedia/SectionEditor.vue';
import ResourcesEditor from "../components/landings/multimedia/ResourcesEditor.vue";
import { validateFileExtension } from '../utils/validateFileExtension'

export default {
  components: {
    BasicLandingPageForm,
    CustomLandingPageForm,
    MultimediaLandingPageForm,
    LandingTypeSelector,
    SectionEditor,
    ResourcesEditor
  },
  data() {
    return {
      validateFileExtension,
      validImage: true,
      isLoading: false,
      landing: {
        template: "basic",
        customUrl: "",
        sections: [],
        availableMediaQueries: [],
        mediaSections: [],
        colors: { title: "#42CBB6", benefits: "#42CBB6" }
      },
    };
  },
  mounted() {
    this.getLanding();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
    urlLanding() {
      return `${process.env.VUE_APP_PUBLIC_URL}/company/${this.companyId}`;
    },
  },
  methods: {
    async updateTemplate(template) {
      this.landing.template = template;
      await this.saveChanges();
    },
    async updateLandingPayload(landing) {
      if(!this.validImage) return; // if the image is not valid, we don't save the changes
      
      try {
        this.landing = { ...this.landing, ...landing };
        await this.saveChanges();
      } catch (error) {
          this.$toast.error({
              message: "Hubo un problema al guardar los cambios.",
          });
      }
    },
    async updateCustomUrl(customUrl) {
      this.landing.customUrl = customUrl;
      await this.saveChanges();
    },
    async getLanding() {
      this.isLoading = true;
      try {
        const response = await this.$api.get(`/companies/${this.companyId}/landings`);
        this.landing = response.data;
      } catch (error) {
        this.$toast.warning({
          message : error,
        });

        if (error.response?.status === 404) { // creating landing if not exists
          await this.createLanding();
        }
      }
      this.isLoading = false;
    },
    async saveChanges() {
      const landingRefs = {
        basic: this.$refs.basicLandingPage,
        custom: this.$refs.customLandingPage,
        multimedia: this.$refs.multimediaLandingPage,
      };

      const landing = landingRefs[this.landing.template].landing;

      if (landing.id) await this.updateLanding();
      else await this.createLanding();
    },
    async updateLanding() {
      this.isLoading = true;
      try {
        await this.$api.put(`/companies/${this.companyId}/landings`, { ...this.landing });
        this.getLanding();
        this.$toast.success({
          message: "Los cambios se guardaron correctamente",
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
      this.isLoading = false;
    },
    async createLanding() {
      this.isLoading = true;
      try {
        await this.$api.post(`/companies/${this.companyId}/landings`, { ...this.landing });
        this.getLanding();
        this.$toast.success({
          message: "Los cambios se guardaron correctamente",
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
      this.isLoading = false;
    },
    async uploadImage(file, fileIdentifier, fileMetadata, name) {
      if (!file) return;

      // Validar el archivo antes de subirlo
      const isFileValid = await this.validateFileExtension(file, ["webp","jpg", "jpeg", "png", "gif", "ico", "tiff"], name);
      if (!isFileValid) {
        this.validImage = false;
        return; // Detener si la validación falla
      }

      const payload = new FormData();
      payload.append("file", file);
      payload.append("fileIdentifier", fileIdentifier);
      payload.append("fileMetadata", JSON.stringify(fileMetadata ?? {}));

      try {
        
        this.validImage = true;
        const { data } = await this.$api.put(`/companies/${this.companyId}/landings/image`, payload);
        return data;

      } catch (error) {
        const status = error.response?.status; // Código de estado HTTP
        const data = error.response?.data; // Datos de la respuesta

        if (status === 422 && data?.malicious) {
          // Archivo con malware
          this.$toast.error({
            message: "El archivo que intentas subir es potencialmente peligroso y no puede ser cargado.",
          });
        } else if (status === 415) {
          // Extensión de archivo no válida
          this.$toast.error({
            message: data?.validExtension,
          });
        } else {
          // Otros errores genéricos
          const message = data?.message || error.message || "Error desconocido.";
          this.$toast.error({
            message,
          });
        }
        throw error
      }
    },
    async deleteImage(fileKey) {
      try {
        const payload = {
          fileKey
        };
        const { data } = await this.$api.post(`/companies/${this.companyId}/landings/delete-image`, payload);

        return data;
      } catch (error) {
        this.$toast.error({
          message: error,
        });
        throw error
      }
    },
    handleScroll() {
      const firstContainer = document.getElementById('firstContainer');
      const secondContainer = document.getElementById('secondContainer');

      if (firstContainer && secondContainer) {
        const rect = firstContainer.getBoundingClientRect();
        if (rect.bottom <= 60) {
          secondContainer.classList.add('sticky');
        } else {
          secondContainer.classList.remove('sticky');
        }
      }
    },
  },


};
</script>


<style>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.edition-card {
  width: 95%;
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>
