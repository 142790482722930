import axios from '@/axios'
import Vue from 'vue'

const authStore = {
  state: {
    session: { // Saves user session
      user: {
        id: null,
        role: null,
        email: null,
        company: null
      },
    },
  },
  // Actions are functions that causes side effects and can involve asynchronous operations and only called from other components
  actions: {
    // Logout action (clear session and send api signout request)
    async logout({ commit }) {
      try {
        await axios.post("/auth/signout");
        commit('logout')
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 3000,
        });
        throw error
      }
    },
    // Login action with mail (sigin api request and save session)
    async loginByMail(_, payload) {
      try {
        const { data } = await axios.post("/auth/signin-by-mail", payload);

        Vue.$toast.info({
          message: data.message,
          duration: 5000,
        });
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
    // Login action with password (sigin api request and save session)
    async loginByPassword({ commit }, payload) {
      try {
        const { data } = await axios.post("/auth/signin", payload);

        commit('login', data)
        return data.user;
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
    // Get user session (get user session from api)
    async getSession({ commit }) {
      try {
        const { data } = await axios.get('/auth/get-user-auth');
        commit('login', data);

        return data.user;
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
    // Login action with mail (sigin api request and save session)
    async sendResetPassword(_, payload) {
      try {
        const { data } = await axios.post('/auth/reset-password', payload);

        Vue.$toast.info({
          message: data.message,
          duration: 5000,
        });
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
    // Stablish new password
    async createPassword({ commit }, payload) {
      try {
        const { data } = await axios.post("/auth/create-password", payload);

        commit('login', data)
        return data.user;
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
    // Stablish new password by invite
    async createPasswordByInvite({ commit }, payload) {
      try {
        const { data } = await axios.post(`/auth/update-password-by-invite/${payload.invite}`, payload.form)

        commit('login', data)
        return data.user;
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
    // Verify a invite is valid
    async verifyInvite({ commit }, payload) {
      try {
        const { data } = await axios.post(`/auth/verify-invite/${payload.invite}`)

        commit('login', data)
        return data.user;
      } catch (error) {
        Vue.$toast.error({
          message: error,
          duration: 5000,
        });
        throw error
      }
    },
  },
  // Mutations are functions that effect the state, just called on actions
  mutations: {
    logout(state) {
      state.session = {
        user: {
          id: null,
          role: null,
          email: null,
          company: null,
        },
      }
      state.userVacancies = []
      state.userCompanies = []
      state.currentCompany = {}

      state.recruiters = []
      state.project.name = null
      state.userVacancies = []
      state.userCompanies = []
    },
    login(state, session) {
      state.session = session
    },
  },
  // Getters are functions that return a value from the state and can be called from other components
  getters: {
  }
}

export default authStore;