import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import CandidateProfile from '@/screens/CandidateProfile'
import _403 from '@/screens/errors/403'
import _404 from '@/screens/errors/404'
import Maintenance from '@/screens/Maintenance'
import auth from './auth'
import company from './company'
import pdf from './pdf'
import recruitment from './recruitment'

Vue.use(VueRouter)

// Función que determina a dónde redirigir al usuario al iniciar sesión
const geHomeRedirect = (user) => {
  if (!user) return '/login'

  const routes = {
    superqueen: '/empresas',
    recruiter: '/empresas',
    'recruiter-manager': '/empresas',
    designer: '/empresas',
    'designer-manager': '/empresas',
    manager: '/empresas',
    hrmanager: `/empresa/${user.company}/vacantes`,
    solicitant: `/empresa/${user.company}/vacantes`,
    account: `/empresas`,
    'account-manager': `/empresas`
  }
  return routes[user.role]
}

const routes = [
  {
    path: '/',
    name: 'Root',
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'manager', 'account-manager', 'account', 'hrmanager', 'solicitant']
    },
    component: {
      render: (r) => r('router-view')
    }
  },
  ...auth,
  ...company,
  ...recruitment,
  ...pdf,
  {
    path: '/download-candidate-profile',
    component: CandidateProfile,
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager', 'account-manager', 'account', 'hrmanager', 'solicitant']
    },
  },
  {
    path: '/maintenance',
    component: Maintenance,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/403',
    component: _403,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '*',
    component: _404,
    meta: {
      requireAuth: false,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // Comprobando modo mantenimiento
  if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
    if (from.path !== '/maintenance' && to.path !== '/maintenance') {
      next('/maintenance')
      return
    }
  }

  // Comprobando que requiera autenticación
  if (to.meta.requireAuth === false) {
    await store.dispatch('logout')
    next()
    return
  }

  // Obteniendo la sesión del usuario 
  let user = store.state.session.user;
  if (!user?.role) {
    user = await store.dispatch('getSession');

    if (!user?.role) { // Si la sesión ha caducado
      next('/login')
      return
    }
  }

  try {
    let permissions = to.meta.permissions;

    if (permissions?.length === 0) { // Si no hay definición de permisos (error de diseño)
      Vue.$toast.warning({
        message: "Necesitas tener permisos para acceder a esta página",
        duration: 3000,
      });
      next(from.path)
      return
    }

    if (!permissions.includes(user.role)) { // Si no tiene permisos para acceder a la página
      Vue.$toast.warning({
        message: "No tienes permisos para acceder a esta página",
        duration: 3000,
      });
      next(from.path)
      return
    }

    if (to.path === '/') { // Si viene de una redirección de login
      next(geHomeRedirect(user))
      return
    }

    next()
    return
  } catch (error) { // Error de autenticación en API redirige a login
    Vue.$toast.error({
      message: error,
      duration: 3000,
    });
    await store.dispatch('logout')
    next('/login')
    return
  }
})

export default router
