<template >
    <b-modal
      :active.sync="isOpen"
      trap-focus
      :can-cancel="false"
      :destroy-on-hideCompanies="false"
      aria-role="dialog"
      aria-modal
      width="80vw"
    >
      <div class="invite-container has-background-light" >
        <div :class="vacancies || !defaultCompany ? 'is-flex is-justify-content-space-between pr-4 pt-4 pb-2 pl-2' : 'is-flex is-justify-content-flex-end pr-4 pt-4 pb-2 pl-2'">
          <div class="is-flex justify-content-start">
            <!-- Volver atrás -->
            <b-button
              v-if="vacancies && !defaultCompany"
              @click="returnToMain" icon-right="arrow-left" type="is-blue" outlined
              >
            </b-button>

            <!-- Company logo -->
            <span
              v-if="defaultCompany?.logo"
              class="companyLogo"
              :style="`background-color:${companyColor}`"
              >
              <img :src="defaultCompany.logo?.url" :alt="`Logo de ${defaultCompany.logo?.name}`">
            </span>
            <span
              v-else-if="defaultCompany?.name"
              :style="`color: ${companyColor}; boder-color: ${companyColor}`"
              class="companyName"
            >
              {{ defaultCompany.name }}
            </span>

            <!-- Título -->
            <p class="is-size-4 has-text-weight-semibold ml-3">
              {{ vacancies ? 'Vacantes disponibles para invitar a candidatos': 'Empresas disponibles para invitar a candidatos' }}
            </p>
          </div>

          <!-- Cerrar modal -->
          <b-button @click="close"  icon-right="xmark" type="is-blue"
             outlined>
          </b-button>
        </div>

        <!-- Visualizador de empresas y vacantes -->
        <div class="main" >
          <div class="companiesLettersContainer"
            v-if="showCompanies && !defaultCompany"
          >
            <!-- Seleccionable de letras -->
            <LetterSelector
            :save-companies="saveCompanies"
            @letterSelected="filterCompanies"
            @lettersReseted="resetCompanies"
            />
            
            <!-- Scroleable de las empresas del reclutador -->
            <CompaniesContainer
              :companies="companies"
              @companySelected="getCompanyVacancies"
            />
          </div>
          
          <!-- Botón de mostrar u ocultar empresas -->
          <b-icon size="is-medium"
          v-if="!defaultCompany && vacancies"
          :icon="showCompanies ? 'angle-up' : 'angle-down' "
          class="has-text-blue hideButton"
          @click.native="hideCompanies"
          />

          <transition name="slide-fade">
          <div class="vacanciesComparingContainer" v-if="vacancies">
            <!-- Scroleable de las vacantes de la empresa selccionada -->
              <VacanciesContainer
              :vacancies="vacancies"
              :company-color="companyColor"
              @vacancyShared="share"
              @vacancyCompared="comparedVacancy = $event"
              />
            
            <!-- Datos usuario -->
              <CandidatesInfo
              :selected-users-to-invite="selectedUsersToInvite"
              :company-color="companyColor"
              :compared-vacancy="comparedVacancy"
              @deletedCandidate="deleteCandidate"
              />
          </div>
          </transition>
        </div>
      </div>
      <b-loading :active.sync="isLoading" />

      <AskCustomEvaluation ref="askCustomEvaluation" @inviteCandidate="inviteCandidates" />
    </b-modal>
</template>

<script>
import CandidatesInfo from './CandidatesInfo.vue'
import LetterSelector from './LetterSelector.vue'
import CompaniesContainer from './CompaniesContainer.vue'
import VacanciesContainer from './VacanciesContainer.vue'
import AskCustomEvaluation from '../AskCustomEvaluation.vue'

export default {
  props:{
    selectedUsers: {
      type: Array,
      default: () => {
        return []
      }
    },
    companyId: {
      default: null,
      required: false
    },
    vacancyId: {
      default: null,
      required: false
    }
  },
  components: {
    CandidatesInfo,
    LetterSelector,
    CompaniesContainer,
    VacanciesContainer,
    AskCustomEvaluation
  },
  data(){
    return{
      isOpen: false,
      companies: null,
      vacancies: null,
      sharedVacancy: null,
      defaultCompany: null,
      companyColor: null,
      saveCompanies: [],
      showCompanies: true,
      comparedVacancy: null,
      selectedUsersToInvite: [],
      isLoading: false
    }
  },
  watch:{
    selectedUsers(val) {
      this.selectedUsersToInvite = val
    }
  },
  computed:{
    user () {
        return this.$store.state.session.user
    },
    isSpain(){
      return process.env.VUE_APP_LOCATION  === 'es'
    },
  },
  methods: {
    async open(){
      this.isOpen = true
      this.selectedUsersToInvite = this.selectedUsers

      if (this.companyId && this.isSpain){
        await this.getCompany()
        await this.getCompanyVacancies(this.defaultCompany)
      } else {
        await this.getCompanies()
      }
    },
    close(){
      this.isOpen = false
      this.companies = null
      this.vacancies = null
      this.defaultCompany = null
      this.companyColor = null
      this.saveCompanies = []
      this.showCompanies = true
      this.comparedVacancy = null
      this.selectedUsersToInvite = []
      this.isLoading = false
    },
    async getCompany(){
      this.isLoading = true
      try{
        const { data } = await this.$api.get(`companies/${this.companyId}`)
        this.defaultCompany = data
      }catch(error){
        const errorMessage = error.response.data.message || error
        this.$toast.error({
          message: 'Error al obtener la empresa: ' + errorMessage,
        });
      }
      this.isLoading = false
    },
    async getCompanies(){
      this.isLoading = true
      const { id } = this.user
      try {
        const { data } = await this.$api.get(`wallet/companies/${id}`)
        this.companies = data;
        this.orderCompanies()
        this.saveCompanies = this.companies
      } catch(error) {
        this.$toast.error({
          message: `Error al obtener empresas: ${error.response.data.message || error}`,
        });
      }
      this.isLoading = false
    },
    async getCompanyVacancies(company){
      this.isLoading = true
      const id = company._id || company.id
      try{
        const { data } = await this.$api.get(`wallet/vacancies/${id}`)
        this.vacancies = data.filter( vacancy => vacancy._id !== this.vacancyId)
        this.companyColor = company.mainColor
        this.showCompanies = false
      }catch(error){
        const errorMessage = error.response.data.message || error
        this.$toast.warning({
          message: 'Error al obtener vacantes: ' + errorMessage,
        });
      }
      this.isLoading = false
    },
    share(vacancy){
      this.sharedVacancy = vacancy

      if (!this.companyId){
        this.inviteCandidates(false) // Ask for keeping just in vacancy candidates screens
      } else {
        this.$refs.askCustomEvaluation.open()
      }
    },
    async inviteCandidates(sharingProfile){
      const payload = {
        candidates: this.selectedUsersToInvite,
        recruiter: this.user,
        vacancy: this.sharedVacancy,
        sharingProfile
      }
      try{
        const { data } = await this.$api.post('wallet/share-multiple', payload)
        this.updateCandidatesInvitationStatus(data) // Updating invitation status on CandidateInfo component

        this.$toast.success({
          message: "Invitación enviada correctamente",
        });
      }catch(error){
        this.$toast.error({
          message: error,
          duration: 3000
        });
      }
    },
    updateCandidatesInvitationStatus(invitationsStatuses){
      this.selectedUsersToInvite = this.selectedUsersToInvite.map( (user) => {
        user.invitationStatus = invitationsStatuses[user.id]

        return user
      })
    },
    orderCompanies(){
      const companies = this.companies.map( company => company.company)
      companies.sort( (a,b) => {
        let textA = a.name.toUpperCase()
        let textB = b.name.toUpperCase()
        return (textA<textB) ? -1 : (textA>textB) ? 1 : 0
      })
      this.companies = companies
    },
    filterCompanies(letter){
      if(!this.saveCompanies.length > 0) this.saveCompanies = this.companies
      this.companies = this.saveCompanies
      console.log(this.companies);
      this.companies = this.companies.filter( company => company.name.charAt(0) === letter)
    },
    resetCompanies(){
      this.companies = this.saveCompanies
    },
    returnToMain(){
      this.vacancies = null
      this.showCompanies = true
    },
    hideCompanies(){
      this.showCompanies = !this.showCompanies
    },
    deleteCandidate(index){
      this.selectedUsersToInvite = this.selectedUsersToInvite.splice(index,1)
    }
  }
}
</script>
<style  lang="scss"  scoped>
  .invite-container{
    height: 85vh;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
  }
  .hideButton {
    align-self: flex-start;
    cursor: pointer;
    margin-left: 0.5rem;
  }
  .companyLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    border-radius: 7px;
    margin-left: 0.5rem;
    width: 70px;
    max-height: 40px;
  }
  .companyName{
    margin-top: 0.2rem;
    border-width: 1px;
    border-style: solid;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }
  .main{
    height: calc(85vh - 130px);
    max-height: calc(85vh - 130px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem;
    gap: 0.5rem;
    overflow: hidden;
  }
  .companiesLettersContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    flex-shrink: 1;
    width: 100%;
    min-height: 13rem;
    height: fit-content;
    max-height: 100%;
  }
  .vacanciesComparingContainer{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    height: 53%;
    flex-shrink: 0;
    flex-grow: 1;
  }
  .scrolleable{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .scrolleable::-webkit-scrollbar{
    width: 0.85rem;
  }
  .scrolleable::-webkit-scrollbar-trac{
    background-color: transparent;
  }
  .scrolleable::-webkit-scrollbar-track-piece:start{
    margin-top: 1rem;
  }
  .scrolleable::-webkit-scrollbar-track-piece:end{
    margin-bottom: 1rem;
  }
  .scrolleable::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }
  .scrolleable-horizontal{
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .scrolleable-horizontal::-webkit-scrollbar{
    width: 0.85rem;
  }
  .scrolleable-horizontal::-webkit-scrollbar-track{
    background-color: transparent;
  }
  .scrolleable-horizontal::-webkit-scrollbar-track-piece:start{
    margin-top: 1rem;
  }
  .scrolleable-horizonal::-webkit-scrollbar-track-piece:end{
    margin-bottom: 1rem;
  }
  .scrolleable-horizontal::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .8s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to{
    transform: translateX(400px);
    opacity: 0;
  }
  .up-fade-enter-active,  .up-fade-leave-active {
    transition: all 0.8s ease;
    transition-delay: 0.4s;
  }
  .up-fade-enter, .up-fade-leave-to{
    transform: translateY(400px);
    opacity: 0;
  }
</style>
