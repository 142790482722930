<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ dataTranslation.languageInfo.title }}
            <f-icon icon="fa-regular fa-globe-pointer" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Info -->
        <!-- Vacancy Form Language -->
        <div class="column is-3">
          <ValidationProvider
            rules="required"
            name="Idiomas de vacante"
            v-slot="{ errors, valid }"
          >
            <b-field
               :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
                <b-radio
                  v-for="(vacancyFormLanguageOption, index) in vacancyFormLanguageOptions"
                  :key="`vacancy-form-language-${index}`"
                  :native-value="vacancyFormLanguageOption.value"
                  class="mr-5 mt-2"
                  v-model="form.vacancyFormLanguages"
                >
                  {{ vacancyFormLanguageOption.text }}
                </b-radio>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  props : {
    editData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      form: {
        vacancyFormLanguages:  process.env.VUE_APP_LOCATION,
      }
    };
  },
  mounted() {
    this.updateFormLanguage(process.env.VUE_APP_LOCATION)
  },
  watch: {
    'form.vacancyFormLanguages': function (newVal) {
      this.updateFormLanguage(newVal)
    },
    'editData.vacancyFormLanguages': {
      immediate: true,
      deep: true,
      handler(newVacancyFormLanguages) {
        if (newVacancyFormLanguages) {
          this.form.vacancyFormLanguages = newVacancyFormLanguages;
        }
      },
    },
  },
  computed: {
    vacancyFormLanguageOptions() {
      return [
        {
          text: this.dataTranslation.vacancyFormLanguageOptions.spanish,
          value: process.env.VUE_APP_LOCATION,
        },
        {
          text: this.dataTranslation.vacancyFormLanguageOptions.english,
          value: "en",
        },
      ];
    },
    dataTranslation () {
      return this.$t('screens.vacancies.edit')
    }
  },
  methods: {
    updateFormLanguage (newLanguage) {
      this.$i18n.locale = newLanguage || process.env.VUE_APP_LOCATION; // Cambia el idioma actual de i18n
    },
    async validateForm(){
      const valid = await this.$refs.observer.validate();
      if(valid){
        return this.form;
      }
      return false;
    },
  },
  unmounted() {
    this.updateFormLanguage(process.env.VUE_APP_LOCATION);
  },
};
</script>