<template >
  <div class="container" id="projects-view">
    <!-- Project details and edit -->
    <div
      v-if="selectedProjectId || isCreatingProject"
      class="project-detail-container"
     >
      <div class="is-flex align-center justify-flex-end mb-5 mr-3" >
        <b-button
          icon-left="arrow-left"
          rounded
          outlined
          type="is-primary"
          @click="goBack">
          Regresar
        </b-button>
      </div>

      <ProjectDetail 
        :projectId="selectedProjectId"
        @projectCreated="goBack"
      />
    </div>

    <!-- Projects -->
    <ProjectsList
      v-if="!isCreatingProject && !selectedProjectId"
      :projects="projects"
      @selectProject="selectProject"
      @showCreateProject="showCreateProject"
    />

    <b-loading :active.sync="isLoading" />
  </div>

</template>
<script>
import ProjectsList from '../components/projects/ProjectsList.vue'
import ProjectDetail from '../components/projects/ProjectDetail.vue'
import { projectCreationAllowedRoles } from '../lib/permissions'

export default {
  name: 'ProjectsMain',
  components:{
    ProjectsList,
    ProjectDetail
  },
  data(){
    return{
      projectCreationAllowedRoles,
      projects: [],
      selectedProjectId: null,
      isCreatingProject: false,
      isLoading: false
    }
  },
  mounted(){
    if(this.queryId){
      this.selectProject(this.queryId)
    }
    else{
      this.getProjects()
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    queryId(){
      return this.$route.query.project
    },
    handledVacancy () {
      return this.$route.query.vacancy
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    }
  },
  methods: {
    async getProjects(){
      this.isLoading = true
      try{
        const { data } = await this.$api.get(`/companies/${this.id}/projects`)
        this.projects = data

      }catch(error){
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    selectProject(project){
      this.selectedProjectId = project
      const query = {
        project
      }
      if(this.handledVacancy) query.vacancy = this.handledVacancy
      this.$router.push({ query })
    },
    async goBack(){
      this.selectedProjectId = null
      this.isCreatingProject = false
      this.$router.push({ query: {} })
      this.$store.dispatch('deleteProjectName')
      await this.getProjects()
    },
    showCreateProject(){
      this.isCreatingProject = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>