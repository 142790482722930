<template>
  <div>
    <b-modal
      :active.sync="isOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal>
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">
            Editar fechas del Becario
          </div>
        </div>
        <div class="modal-card-body">
          <ValidationObserver ref="observer">
            <div class="columns">
              <div class="column">
                <ValidationProvider rules="required" name="Inicio de convenio" v-slot="{ errors, valid }">
                  <b-field
                    label="Inicio de convenio"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    >
                    <b-datepicker
                      inline
                      size="is-small"
                      placeholder= "Fecha de inicio"
                      v-model="form.entry"
                    />
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider rules="required" name="Término de convenio" v-slot="{ errors, valid }">
                  <b-field
                    label="Término de convenio"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    >
                    <b-datepicker
                      inline
                      size="is-small"
                      placeholder= "Fecha de término"
                      v-model="form.finish"
                    />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <!-- ************************************************************************************************************* -->
          </ValidationObserver>
        </div>
        <div class="modal-card-foot">
          <b-button
            type="is-primary"
            @click="edit">
            Actualizar fechas
          </b-button>
          <b-button
            @click="close">
            Cancelar
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      isOpen: false,
      candidate: {},
      form: {
        entry: new Date(),
        finish: new Date()
      }
    }
  },
  computed: {
    roles () {
      const baseRoles = [
        { value: 'recruiter-manager', text: 'Coordinador de Adquisición de Talento' },
        { value: 'recruiter', text: 'Especialista en Adquisición de Talento)' },
        { value: 'designer', text: 'Especialista de Comunicación' },
        { value: 'designer-manager', text: 'Coordinador de Comunicación' },
        { value: 'account', text: 'Analista de Cuentas Clave' },
        { value: 'account-manager', text: 'Coordinador de Cuentas Clave' },
        { value: 'manager', text: 'Manager' }
      ]

      const contactRoles = [
        { value: 'hrmanager', text: 'Manager de RH' },
        { value: 'solicitant', text: 'Solicitante' }
      ]

      if (this.contactUsers || ['solicitant', 'hrmanager'].includes(this.user.role)) {
        return contactRoles
      }

      if (this.companyUsers) {
        return baseRoles
      }

      return baseRoles.concat(contactRoles)
    }
  },
  methods: {
    open (candidate) {
      if (candidate) {
        const { entry, finish } = candidate.hiring || {}
        this.candidate = candidate
        if (entry && finish) {
          this.form = { entry: new Date(entry), finish : new Date(finish) }
        }
      }
      this.isOpen = true
    },
    close () {
      this.clean()
      this.isOpen = false
    },
    async edit () {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.isLoading = true
        try {
          const payload = { ...this.form }
          await this.$api.put(`/candidates/${this.candidate._id}/hiring-date`, payload)
          this.$toast.success({
            message: 'Se actualizó el Candidato exitosamente',
          })
          this.$emit('updated')
          this.close()
        } catch (error) {
          this.$toast.error({
            message: error,
          })
        }
        this.isLoading = false
      }
    },
    clean () {
      this.form = {
        entry: new Date(),
        finish: new Date()
      }
      this.$refs.observer.reset()
    }
  }
}
</script>