<template>
  <div>
    <div class="columns">
      <div class="column">
        <span class="is-size-3">Usuarios</span>
      </div>
    </div>
    <!-- For setting headReacruiter and headRecruiterManager -->
      <div class="mb-5 px-3">
        <div class="columns">
          <div class="card column mr-3 p-5">
            <!-- HeadRecruiterManager field title -->
            <div class="title is-5 mb-0">
              Coordinador principal
              <div v-if="$hasPermissionsByRole(projectCreationAllowedRoles)" class="is-inline">
                <a v-if="!editingHeadRecruiterManager"
                  @click="editingHeadRecruiterManager = true"
                >
                  <b-icon class="icons" icon="pen-to-square">
                  </b-icon>
                </a>
                <a v-if="editingHeadRecruiterManager"
                  @click="updateHeadRecruiterManager()"
                >
                  <b-icon class="icons" icon="floppy-disk" >
                  </b-icon>
                </a>
              </div>
            </div>
            <!-- Dropdown of recruiterManagers -->
            <div
              v-if="editingHeadRecruiterManager"
              class="mt-4"
            >
              <b-select placeholder="Selecciona un coordinador" name="" id="" icon="users"
                   v-model="selectedHeadRecruiterManager">
                  <option :value="recruiterManager.id" v-for="(recruiterManager, index) in recruiterManagers"
                  :key="index">{{recruiterManager.fullName}} </option>
              </b-select>
            </div>
            <!-- Name and photo of HeadRecruiterManager -->
            <div
              v-else-if="company?.headRecruiterManager"
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
            >
              <div class="mr-2">
                {{ company.headRecruiterManager.fullName }}
              </div>
              <div v-if="company.headRecruiterManager.photo" class="column image" style="max-width: 70px">
                <img class="is-rounded" :src="company.headRecruiterManager.photo.url">
              </div>
            </div>
            <div
              v-else
            >
              <div class="mr-2">
                Sin asignar
              </div>
            </div>
          </div>

          <!-- recruiters field title -->
          <div class="card column mr-3 p-5">
            <div class="title is-5 mb-0">
              Reclutador principal
              <div v-if="$hasPermissionsByRole(projectCreationAllowedRoles)" class="is-inline">
                <a v-if="!editingHeadRecruiter"
                  @click="editingHeadRecruiter = true"
                >
                  <b-icon class="icons" icon="pen-to-square">
                  </b-icon>
                </a>
                <a v-if="editingHeadRecruiter"
                  @click="updateHeadRecruiter()"
                >
                  <b-icon class="icons" icon="floppy-disk" >
                  </b-icon>
                </a>
              </div>
            </div>
            <!-- Dropdown of recruiters -->
            <div
              v-if="editingHeadRecruiter"
              class="mt-4"
            >
              <b-select placeholder="Selecciona un coordinador" name="" id="" icon="users"
                v-model="selectedHeadRecruiter">
                  <option :value="recruiter.id" v-for="(recruiter, index) in recruiters"
                  :key="index">{{recruiter.fullName}} </option>
              </b-select>
            </div>
            <!-- Name and photo of HeadRecruiter -->
            <div
              v-else-if="company?.headRecruiter"
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
            >
              <div class="mr-2">
                {{ company.headRecruiter.fullName }}
              </div>
              <div v-if="company.headRecruiter.photo" class="column image" style="max-width: 70px">
                <img class="is-rounded" :src="company.headRecruiter.photo.url">
              </div>
            </div>
            <div
              v-else
            >
              <div class="mr-2">
                Sin asignar
              </div>
            </div>
          </div>
        </div>
      </div>

    <div>
      <div class="card">

        <div
        class="card-content"
        v-for="(user, index) in users"
        :key="`user-${index}`">

          <hr v-if="index > 0">

          <div class="columns">

            <div class="column is-2  is-flex is-justify-content-center is-align-items-center">

              <div v-if="user.user.photo" class="image" style="max-width: 70px">
                <img class="is-rounded" :src="user.user.photo.url">
              </div>

              <div v-else>
                <b-field class="file is-blue">
                  <b-upload
                    @input="uploadPhoto( $event, user )"
                    accept=".gif, .jpeg, .jpg, .png"
                    class="file-label"
                    rounded
                    v-model="file">
                    <span class="file-cta">
                        <b-icon
                          class="file-icon"
                          icon="upload">
                        </b-icon>
                        <span class="file-label">
                          Subir foto
                        </span>
                    </span>
                  </b-upload>
                </b-field>
              </div>

            </div>

            <div class="column is-3">

              <div>
                {{ user.user.fullName }}
              </div>

              <div>
                <a :href="`mailto:${user.user.email}`">
                  {{ user.user.email }}
                </a>
                <div class="is-flex is-justify-content-space-around">
                  <b-tooltip
                    label="Copiar correo"
                    position="is-bottom">
                    <a>
                      <b-icon
                        class="iconos"
                        icon="copy"
                        v-clipboard:copy="user.user.email"
                        v-clipboard:error="onError"
                        v-clipboard:success="onCopy">
                      </b-icon>
                    </a>
                  </b-tooltip>

                  <b-tooltip
                    label="Enviar correo"
                    position="is-bottom">
                    <a
                      :href="`https://mail.google.com/mail/u/0/?fs=1&to=${user.user.email}&tf=cm`"
                      target="_blank">
                      <b-icon
                        class="iconos"
                        icon="envelope-open-text"
                        >
                      </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>

            </div>

            <div class="column is-4">
              <p class="mx-2">
                {{ roles[user.user.role] }}
              </p>
            </div>

            <div class="column is-2">
              <div>
                <b-switch v-model="user.active" @input="assignCompany( user, $event )">
                  Activo en empresa
                </b-switch>
              </div>
            </div>

            <div class="column is-1">
              <div class="is-flex is-justify-content-end">
                <b-dropdown
                  :triggers="['hover']"
                  aria-role="list"
                  position="is-bottom-left">
                    <button class="button" slot="trigger">
                      <b-icon icon="ellipsis-stroke"></b-icon>
                    </button>

                    <b-dropdown-item
                      aria-role="listitem"
                      @click="openEdit( user.user )">
                      <div>
                        <b-icon icon="pencil" /> Editar usuario
                      </div>
                    </b-dropdown-item>

                </b-dropdown>
              </div>
            </div>

          </div>

        </div>

      </div>

      <hr>

      <div class="columns">
        <div class="column is-12">
          <b-pagination
            :current.sync="current"
            :per-page="perPage"
            :range-after="rangeAfter"
            :range-before="rangeBefore"
            :total="total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            order="is-centered"
            rounded
            >
          </b-pagination>
        </div>
      </div>

    </div>

    <UserEdit ref="edit" @updated="getUsers"/>

    <UserCreate @updated="getUsers" ref="create"/>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import UserEdit from '../components/modals/UserEdit.vue'
import UserCreate from '../components/modals/UserCreate.vue'
import { mapGetters } from 'vuex'
import { projectCreationAllowedRoles } from '../lib/permissions'
import { validateFileExtension } from '../utils/validateFileExtension.js'

export default {
  components: {
    UserEdit,
    UserCreate,
  },
  data() {
    return {
      validateFileExtension,
      isLoading: false,
      file: null,
      users: [],
      current: 1,
      perPage: 12,
      rangeAfter: 3,
      rangeBefore: 3,
      total: 0,
      roles: {
        'leader-recruiter-manager': 'Gerente de Adquisición de Talento',
        'recruiter-manager': 'Coordinador de Adquisición de Talento',
        'recruiter': 'Especialista en Adquisición de Talento',
        'designer': 'Especialista de Comunicación',
        'designer-manager': 'Coordinador de Comunicación',
        'account': 'Analista de Cuentas Clave',
        'account-manager': 'Coordinador de Cuentas Clave',
        'manager': 'Manager',
        'hrmanager': 'Manager de RH',
        'solicitant': 'Solicitante'
      },
      projectCreationAllowedRoles,
      company: null,
      recruiterManagers: [],
      recruiters: [],
      selectedHeadRecruiterManager: null,
      editingHeadRecruiterManager: false,
      selectedHeadRecruiter: null,
      editingHeadRecruiter: false,
    }
  },
  computed: {
    ...mapGetters(['companyId']),
  },
  watch: {
    async current () {
      await this.getUsers()
    }
  },
  async mounted () {
    await this.getCompany()
    await this.getUsers()
    await this.getRecruiterManagers()
    await this.getRecruiters()
  },
  methods: {
    async getCompany(){
      try {
        const { data } = await this.$api.get(`/companies/${this.companyId}`)
        this.company = data
        this.selectedHeadRecruiterManager = this.company.headRecruiterManager?._id || ''
        this.selectedHeadRecruiter = this.company.headRecruiter?._id || ''

      } catch (error) {
        this.$toast.error({
          message: error,
        })
      }
    },
    async getRecruiterManagers(){
      this.isLoading = true

      try {
        const { data } = await this.$api.get( `/users/recruiter-managers` )
        this.recruiterManagers = data

      } catch ( error ) {
        this.$toast.error( {
          message: error,
        } )
      }

      this.isLoading = false
    },
    async getRecruiters(){
      this.isLoading = true

      try {
        const { data } = await this.$api.get( `/users/all-recruiters` )
        this.recruiters = data

      } catch ( error ) {
        this.$toast.error( {
          message: error,
        } )
      }

      this.isLoading = false
    },
    async getUsers ( params = null ) {
      this.isLoading = true
      params = params || [
        `populate=user`,
        `skip=${(this.current - 1) * this.perPage}`,
        `limit=${this.perPage}`,
        `fields=user,company,active`,
        `sort=role,email`
      ]
      if ( ['recruiter-manager'].includes( this.$isRole() ) ) params.push( 'role=recruiter' )
      if ( ['designer-manager'].includes( this.$isRole() ) ) params.push( 'role=designer' )
      if ( ['account-manager'].includes( this.$isRole() ) ) params.push( 'role=account' )
      try {
        const { data } = await this.$api.get( `/companies/${this.companyId}/users?${params.join('&')}` )
        this.users = data.data
        this.total = data.total
      } catch (error) {
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    async uploadPhoto ( event, user ) {
      if (this.file) {
        const isFileValid = await this.validateFileExtension(this.file, ["jpg", "jpeg", "png", "gif"]);
        if (!isFileValid) return; // Detener si falla
      }
      
      this.isLoading = true
      try {
        if (this.file) {
          const formData = new FormData()
          formData.append( 'file', event )
          await this.$api.post(`/users/${user.user._id}/upload-photo`, formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          this.resetPhoto()
          await this.getUsers()
        }
      } catch ( error ) {
        const status = error.response?.status; // Código de estado HTTP
        const data = error.response?.data; // Datos de la respuesta

        if (status === 422 && data?.malicious) {
          // Archivo con malware
          this.$toast.error({
            message: "El archivo que intentas subir es potencialmente peligroso y no puede ser cargado.",
          });
        } else if (status === 415) {
          // Extensión de archivo no válida
          this.$toast.error({
            message: data?.validExtension,
          });
        } else {
          // Otros errores genéricos
          const message = data?.message || error.message || "Error desconocido.";
          this.$toast.error({
            message,
          });
        }
      }
      this.isLoading = false
    },
    resetPhoto () {
      this.file = null
    },
    openEdit ( user ) {
      this.$refs.edit.open( user )
    },
    async assignCompany ( userCompany, val ) {
      this.isLoading = true
      try {
        const payload = {
          company: userCompany.company,
          val: val
        }
        await this.$api.post( `/users/${userCompany.user._id}/assign-company`, payload )
        this.$toast.success({
          message: 'El usuario se ha actualizado con éxito',
        })
        await this.getUsers()
      } catch ( error ) {
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    async updateHeadRecruiter () {
      this.isLoading = true
      try {
        const payload = {
          recruiter: this.selectedHeadRecruiter
        }
        await this.$api.post(`/companies/${this.companyId}/recruiters/set-head-recruiter`, payload)
        await this.getCompany()
        this.editingHeadRecruiter = false
        
      } catch ( error ) {
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    async updateHeadRecruiterManager () {
      this.isLoading = true
      try {
        const payload = {
          recruiter: this.selectedHeadRecruiterManager
        }
        await this.$api.post(`/companies/${this.companyId}/recruiters/set-head-recruiter-manager`, payload)
        await this.getCompany()
        this.editingHeadRecruiterManager = false
        
      } catch ( error ) {
        this.$toast.error({
          message: error,
        })
      }
      this.isLoading = false
    },
    onCopy: function () {
      this.$toast.success({
        message: 'Correo copiado correctamente',
      })
    },
    onError: function ( e ) {
      this.$toast.warning({
        message: 'Error al copiar el correo electrónico ' + e.text,
      })
    }
  },
}
</script>

<style lang="scss">
.p-sidebar.b-sidebar {
  .sidebar-content {
    width: 50%;
  }
}
</style>
