<template>
  <div>             
    <router-view />
    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
export default {
  name: 'RecruitmentLayout',
  components: {
  },
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
  },
  methods: {
  },
  beforeMount: async function () {
  },
  async mounted () {
  }
}
</script>