import Login from '@/screens/auth/Login'
import Invite from '@/screens/auth/Invite'
import SetPassword from '@/screens/auth/SetPassword'
import RecoveryPassword from '@/screens/auth/RecoveryPassword'

const auth = [
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/invite-admin',
    component: Invite,
    name: 'Invite',
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/set-password',
    component: SetPassword,
    name: 'SetPassword',
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/recovery-password',
    component: RecoveryPassword,
    name: 'RecoveryPassword',
    meta: {
      requireAuth: false,
    },
  }
]

export default auth