import axios from '@/axios'
import Vue from 'vue'
import Vuex from 'vuex'
import authStore from './auth'
import companiesStore from './companies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...authStore.state,
    ...companiesStore.state,
    recruiters: [], // Saves all recruiters
    project: { // Saves project name
      id: null,
      name: null
    },
  },
  // Actions are functions that causes side effects and can involve asynchronous operations and only called from other components
  actions: {
    ...authStore.actions,
    ...companiesStore.actions,
    async getRecruiters({ commit }) {
      const { data } = await axios.get(`/recruiters`)
      commit('setRecruiters', data)
    },
    getProjectName({ commit }, name) {
      commit('setProjectName', name)
    },
    deleteProjectName({ commit }) {
      commit("deleteProjectName");
    },
  },
  // Mutations are functions that effect the state, just called on actions
  mutations: {
    ...authStore.mutations,
    ...companiesStore.mutations,
    setRecruiters(state, recruiters) {
      state.recruiters = recruiters
    },
    setProjectName(state, name) {
      state.project.name = name || 'Por definir'
    },
    deleteProjectName(state) {
      state.project.name = null
    },
  },
  // Getters are functions that return a value from the state and can be called from other components
  getters: {
    ...authStore.getters,
    ...companiesStore.getters,
    unassignedRecruiters(state) {
      return state.recruiters.filter(recruiter => recruiter.deleted || !recruiter.companyRecruiter)
    },
    projectName(state) {
      return state.project.name || 'Por definir'
    },
    projectId(state) {
      return state.project.id || 'Por definir'
    }
  }
})
