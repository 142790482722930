<template>
  <div class="columns">
    <SideBar v-if="showSidebar"/>

    <div class="column">
      <div class="pr-10 pt-10">
        <AppBreadcrumbs v-if="showBreadCrumb"/>
        
        <section class="has-background-light layout">
          <div class="container mt-50">
            <transition name="fade">
              <router-view />
            </transition>
          </div>
        </section>
      </div>
    </div>
    
    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import AppBreadcrumbs from '@/components/AppBreadcrumbs'
import {
  externalRoles,
  designerRoles,
} from '../lib/permissions'

export default {
  name: 'PromeritumLayout',
  components: {
    SideBar,
    AppBreadcrumbs,
  },
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    id () {
      const id = this.$route.params.id
      if (!id)
        this.$router.back()

      return id
    },
    showSidebar () {
      return !this.$hasPermissionsByRole([...externalRoles, ...designerRoles])
    },
    showBreadCrumb () {
      return !this.$hasPermissionsByRole([...externalRoles])
    },
  },
}
</script>