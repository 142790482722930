<template>
    <div>
        <div class="column pl-0 pt-0 mb-2 is-full is-flex is-align-content-center">
            <div class="title is-4 mb-0 has-text-blue">
                {{ academicDataTranslation.editAcademicProfile || academicDataTranslation.academicProfile }}
            </div>
            <!-- Botón para guardar cambios -->
            <a class="ml-2" @click="updateAcademicProfile">
                <b-tooltip animated class="is-flex is-align-items-end" label="Guardar cambios" position="is-top"
                    type="is-success">
                    <b-icon class="icons" icon="floppy-disk"></b-icon>
                </b-tooltip>
            </a>
        </div>

        <ValidationObserver ref="observer">
            <div class="columns is-multiline">
                <!-- Universidad -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Universidad" v-slot="{ errors, valid }">
                        <b-field label="Universidad" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.university" placeholder="Ingresa la universidad"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Campus -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Campus" v-slot="{ errors, valid }">
                        <b-field label="Campus" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.campus" placeholder="Ingresa el campus"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Carrera -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Carrera" v-slot="{ errors, valid }">
                        <b-field label="Carrera" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.career" placeholder="Ingresa la carrera"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Área de estudio -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Área de estudio" v-slot="{ errors, valid }">
                        <b-field label="Área de estudio" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.studyArea" placeholder="Selecciona un área de estudio">
                                <option v-for="area in studyAreas" :key="area" :value="area">{{ area }}</option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Nivel académico -->
                <div class="column is-6">
                    <ValidationProvider :rules="academicDataTranslation.academicLevel.validation" name="Nivel académico"
                        v-slot="{ errors, valid }">
                        <b-field label="Nivel académico" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.academicLevel" placeholder="Selecciona un nivel académico">
                                <option v-for="(level, key) in academicLevels" :key="key" :value="key">{{ level }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>
                <!-- Semestre actual (si es estudiante) -->
                <div class="column is-6" v-if="form.academicLevel === 'student'">
                    <ValidationProvider :rules="academicDataTranslation.currentSemester.validation"
                        name="Semestre actual" v-slot="{ errors, valid }">
                        <b-field label="Semestre actual" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-select v-model="form.currentSemester" placeholder="Selecciona el semestre actual">
                                <option v-for="semester in semesters" :key="semester.value" :value="semester.value">
                                    {{ semester.text }}
                                </option>
                            </b-select>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Fecha de graduación -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Fecha de graduación" v-slot="{ errors, valid }">
                        <b-field label="Fecha de graduación" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-datepicker v-model="form.graduateDate" placeholder="Selecciona la fecha"></b-datepicker>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Fecha de titulación -->
                <div class="column is-6">
                    <ValidationProvider :rules="`required`" name="Fecha de titulación" v-slot="{ errors, valid }">
                        <b-field label="Fecha de titulación" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-datepicker v-model="form.degreeDate" placeholder="Selecciona la fecha" :min-date="form.graduateDate"></b-datepicker>
                        </b-field>
                    </ValidationProvider>
                </div>

                <!-- Horario escolar -->
                <div class="column is-12">
                    <h3 class="subtitle is-5">Horario Escolar</h3>
                </div>

                <div class="column is-4">
                    <ValidationProvider :rules="{ required: true, max: 25 }" name="Lunes" v-slot="{ errors, valid }">
                        <b-field label="Lunes" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.mondaysSchedule"
                                placeholder="Ingresa el horario del lunes" maxlength="25"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <div class="column is-4">
                    <ValidationProvider :rules="{ required: true, max: 25 }"  name="Martes" v-slot="{ errors, valid }">
                        <b-field label="Martes" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.tuesdaysSchedule"
                                placeholder="Ingresa el horario del martes" maxlength="25"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <div class="column is-4">
                    <ValidationProvider :rules="{ required: true, max: 25 }"  name="Miércoles" v-slot="{ errors, valid }">
                        <b-field label="Miércoles" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.wednesdaysSchedule"
                                placeholder="Ingresa el horario del miércoles" maxlength="25"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <div class="column is-4">
                    <ValidationProvider :rules="{ required: true, max: 25 }"  name="Jueves" v-slot="{ errors, valid }">
                        <b-field label="Jueves" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.thursdaysSchedule"
                                placeholder="Ingresa el horario del jueves" maxlength="25"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <div class="column is-4">
                    <ValidationProvider :rules="{ required: true, max: 25 }"  name="Viernes" v-slot="{ errors, valid }">
                        <b-field label="Viernes" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.fridaysSchedule"
                                placeholder="Ingresa el horario del viernes" maxlength="25"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>

                <div class="column is-4">
                    <ValidationProvider :rules="{ required: true, max: 25 }"  name="Sábado" v-slot="{ errors, valid }">
                        <b-field label="Sábado" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="errors">
                            <b-input v-model="form.saturdaysSchedule"
                                placeholder="Ingresa el horario del sábado" maxlength="25"></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { updateAcademicProfile } from '@/api/candidate';
import { academicLevels, studyAreas, semesters } from '@/lib/applicationDictionaries';

export default {
    props: {
        profile: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    data() {
        return {
            form: {
                university: this.profile.university || '',
                campus: this.profile.campus || '',
                career: this.profile.career || '',
                studyArea: this.profile.studyArea || '',
                academicLevel: Array.isArray(this.profile.academicLevel) ? this.profile.academicLevel[0] : this.profile.academicLevel || '',
                currentSemester: this.profile.currentSemester || null,
                graduateDate: this.profile.graduateDate ? new Date(this.profile.graduateDate) : null,
                degreeDate: this.profile.degreeDate ? new Date(this.profile.degreeDate) : null,
                mondaysSchedule: this.profile.mondaysSchedule || '',
                tuesdaysSchedule: this.profile.tuesdaysSchedule || '',
                wednesdaysSchedule: this.profile.wednesdaysSchedule || '',
                thursdaysSchedule: this.profile.thursdaysSchedule || '',
                fridaysSchedule: this.profile.fridaysSchedule || '',
                saturdaysSchedule: this.profile.saturdaysSchedule || '',
            },
            academicLevels: academicLevels['mx'],
            studyAreas: studyAreas['mx'],
            semesters: semesters['mx'],

        };
    },

    watch: {
        "form.graduateDate": function (newValue) {
            if (this.form.degreeDate && newValue) {
                this.validateDates();
            }
        },
        "form.degreeDate": function (newValue) {
            if (this.form.graduateDate && newValue) {
                this.validateDates();
            }
        },
    },


    methods: {
        async updateAcademicProfile() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                this.$toast.error({
                    message: 'Por favor, corrige los errores antes de continuar.',
                });
                return;
            }

            try {
                // Obtener el ID del usuario desde el localStorage o vuex store
                const userId = this.$store.state.session.user.id; // Ajusta esto si tu estructura es diferente

                // Crear el payload incluyendo el ID del usuario
                const payload = {
                    ...this.form,
                    id: this.$route.params.id,
                    user: userId  // Incluye el ID del usuario
                };

                await updateAcademicProfile(payload); // Enviar el payload actualizado al API

                this.$toast.success({
                    message: 'Perfil académico actualizado con éxito',
                });

                this.$emit('academicProfileUpdated', payload);
            } catch (error) {
                this.$toast.error({
                    message: error,
                });
            }
        },
        validateDates() {
            if (this.form.graduateDate > this.form.degreeDate) {
                this.$buefy.toast.open({
                    message: 'La fecha de titulación no puede ser anterior a la fecha de graduación.',
                    type: 'is-danger',
                });
                this.form.degreeDate = null; 
                }
            },
    },



    computed: {
        academicDataTranslation() {
            return this.$t('screens.candidate.detail');
        },
    },
};
</script>
